import Dashboard from "./Dashboard";
import Login from "./Login";
import LoginWithCode from "./LoginWithCode";
import LoginWithToken from "./LoginWithToken";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import PatientsRequestPage from "./PatinetsRequest";
import UserProfile from "./UserProfile";
import TransferInRequest from "./TransferInRequest";
import TransferOutRequest from "./TransferOutRequest";
import ChoosePharmacyAccount from "./ChoosePharmacyAccount";
import User from "./User";
import PharmacyBranch from "./PharmacyBranch";
import Patient from "./Patient";
import Prescriber from "./Prescriber";
import Drug from "./Drug";
import NotFound from "./NotFound";
import Root from "./Root";
import ResetPassword from "./ResetPassword";
import AcceptInvite from "./AcceptInvite";
import MessageAnotherPharmacy from "./MessageAnotherPharmacy";
import PharmacyBranchReportsPage from "./PharmacyBranchReports";
import DrugReportsReportsPage from "./drugReports";
import ReportsPage from "./Reports";
import Referals from "./Referals";
import ProductLocatorPage from "./ProductLocator";
export default {
  Root,
  Dashboard,
  UserProfile,
  Login,
  LoginWithCode,
  SignUp,
  ForgotPassword,
  TransferInRequest,
  TransferOutRequest,
  NotFound,
  User,
  LoginWithToken,
  ChoosePharmacyAccount,
  PharmacyBranch,
  Patient,
  Prescriber,
  Drug,
  ResetPassword,
  AcceptInvite,
  MessageAnotherPharmacy,
  PatientsRequestPage,
  PharmacyBranchReportsPage,
  DrugReportsReportsPage,
  ReportsPage,
  Referals,
  ProductLocatorPage,
};
