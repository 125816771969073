import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import TransferInRequestService from "../../services/transfer-in-request.service";
import TransferOutRequestService from "../../services/transfer-out-request.service";

const ManualAuctoComplateRequestModal = (props) => {
  const cancelReasons = useSelector((state) => state.lookups.cancelReasons);
  const [selectedOption, setSelectedOption] = useState(null);
  const [reasonText, setReasonText] = useState("");

  const handleOptionChange = (id) => {
    setSelectedOption(id);
  };
  const handleProceed = async () => {
    const actionParams = {
      transferRequestId: props?.request?.RequestId,
      actionReasonId: selectedOption,
      actionReasonText: `Manual accepted reason: ${reasonText}`,
    };
    const result = await (props.requesttype === 1
      ? TransferOutRequestService
      : TransferInRequestService
    ).Accept(actionParams);
    setReasonText("");
    setSelectedOption(null);
    if (!result.error) {
      props.onHide(true);
    }
  };

  return (
    <Modal
      onHide={() => {
        setReasonText("");
        setSelectedOption(null);
        props.onHide();
      }}
      show={props.show}
      aria-labelledby="cancel-transfer-request-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="cancel-transfer-request-modal-title">
          Manual Complete Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="mb-3">
            Are you sure you want to COMPLETE request #`
            <b>{props?.request?.RequestId}</b>`?
          </div>
          <div className="row">
            <div className="col-sm-12">
              <textarea
                value={reasonText}
                className="form-control"
                title="Other action Reason"
                placeholder=""
                onChange={(event) => setReasonText(event.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-danger"
          disabled={
            (selectedOption === null || selectedOption < 0) && reasonText === ""
          }
          onClick={handleProceed}
        >
          Proceed
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setReasonText("");
            setSelectedOption(null);
            props.onHide();
          }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManualAuctoComplateRequestModal;
