import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import DashboardService from "../../services/dashboard.service";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import TopTransferedDrugsDataGrid from "../../components/TopTransferedDrugsDataGrid/index";
import StatisticsWidget from "../../components/StatisticsWidget/index";
import "./index.scss";
import { useSelector } from "react-redux";
import TopPharmaciesTransferOutDataGrid from "../../components/TopPharmaciesTransferOutDataGrid";
import TopPharmaciesTransferInDataGrid from "../../components/TopPharmaciesTransferInDataGrid";

const DashboardPage = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const userData = currentUser && currentUser.user;
  const pieChartOptions = {
    series: [],
    options: {
      chart: {
        type: "pie",
      },
      labels: ["In", "Out"],
      colors: ["#2BC155", "#F46B68"],
      legend: { position: "bottom" },
    },
  };

  const areaChartOptions = {
    series: [
      {
        name: "In",
        data: [250, 380, 200, 300, 200, 520, 380, 770, 250, 520, 300, 900],
      },
      {
        name: "Out",
        data: [500, 230, 600, 360, 700, 890, 750, 420, 600, 300, 420, 220],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2],
        curve: "straight",
      },
      legend: {
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
        },
      },
      markers: {
        size: 6,
        border: 0,
        hover: {
          size: 6,
        },
      },
      xaxis: {
        categories: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
      fill: {
        colors: ["#2BC155", "#F46B68"],
        type: "solid",
        opacity: 0.07,
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      colors: ["#2BC155", "#F46B68"],
    },
  };

  const pieChartRef = useRef(null);
  const areaChartRef = useRef(null);
  const [transferRequestsStatistics, setTransferRequestsStatistics] = useState({
    InPendingAcceptance: 0,
    OutPendingAcceptance: 0,
    InPendingReview: 0,
    OutPendingReview: 0,
    InAccepted: 0,
    OutAccepted: 0,
    InRejected: 0,
    OutRejected: 0,
    InCanceled: 0,
    OutCanceled: 0,
    InDraft: 0,
    OutDraft: 0,
  });

  const [radioValue, setRadioValue] = useState("1");
  const radios = [
    { name: "Last Year", value: "1" },
    { name: "Last Month", value: "2" },
    { name: "Last Week", value: "3" },
  ];

  const GetTransferRequestsStatistics = async () => {
    try {
      const result = await DashboardService.GetTransferRequestsStatistics();
      if (result.error === null) {
        setTransferRequestsStatistics(result.data);
        pieChartRef.current.chart.updateSeries([
          result.data.InAccepted,
          result.data.OutAccepted,
        ]);
        //areaChartRef.current.chart.updateSeries();
      }
    } catch (error) {
      console.log("error", error)
    }
  };

  useEffect(() => {
    document.title = "TransferMyRx::Dashboard";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Dashboard";
    }
    GetTransferRequestsStatistics();
  }, []);
  return (
    <div className="dashboard-container">
      {
        userData?.roleId === 1 ? <>
          <div className="row">
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
              <TopTransferedDrugsDataGrid requesttype={0} />
            </div>
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
              <TopTransferedDrugsDataGrid requesttype={1} />
            </div>
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
              <TopPharmaciesTransferOutDataGrid />
            </div>
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
              <TopPharmaciesTransferInDataGrid />
            </div>
          </div></> : <>  <div className="d-flex justify-content-center flex-wrap mb-3">
            <StatisticsWidget
              title={"Pending Acceptance"}
              in={transferRequestsStatistics.InPendingAcceptance}
              out={transferRequestsStatistics.OutPendingAcceptance}
            />
            <StatisticsWidget
              title={"Pending Review"}
              in={transferRequestsStatistics.InPendingAcceptance}
              out={transferRequestsStatistics.OutPendingAcceptance}
            />
            <StatisticsWidget
              title={"Accepted"}
              in={transferRequestsStatistics.InAccepted}
              out={transferRequestsStatistics.OutAccepted}
            />
            <StatisticsWidget
              title={"Rejected"}
              in={transferRequestsStatistics.InRejected}
              out={transferRequestsStatistics.OutRejected}
            />
            <StatisticsWidget
              title={"Canceled"}
              in={transferRequestsStatistics.InCanceled}
              out={transferRequestsStatistics.OutCanceled}
            />
          </div>
          <div className="row">
            <div className="col-xl-8 col-xxl-8 col-lg-8 mb-3 widget">
              <div className="card h-100">
                <div className="card-header d-sm-flex justify-content-between bg-white border-0">
                  <h3 className="fs-5 mb-3 mb-sm-0 text-black">
                    Transfers In VS Out
                  </h3>
                  <ButtonGroup>
                    {radios?.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="outline-success"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
                <div className="card-body">
                  <Chart
                    type="area"
                    options={areaChartOptions.options}
                    series={areaChartOptions.series}
                    height="100%"
                    ref={areaChartRef}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-xxl-4 col-lg-4 mb-3 widget">
              <div className="card h-100">
                <div className="card-header d-sm-flex justify-content-between bg-white border-0">
                  <h3 className="fs-5 mb-3 mb-sm-0 text-black">
                    Transfers In VS Out Overall
                  </h3>
                </div>
                <div className="card-body">
                  <Chart
                    type="pie"
                    options={pieChartOptions.options}
                    series={pieChartOptions.series}
                    height="100%"
                    ref={pieChartRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
              <TopTransferedDrugsDataGrid requesttype={0} />
            </div>
            <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
              <TopTransferedDrugsDataGrid requesttype={1} />
            </div>
          </div></>
      }

    </div>
  );
};

export default DashboardPage;
