import React, { useEffect, useRef, useState } from "react";
import TopTransferedDrugsDataGrid from "../../components/TopTransferedDrugsDataGrid/index";
import "./index.scss";
import { useSelector } from "react-redux";
import PharmacyBranchReports from "../../components/PharmacyBranchReports";
import DrugReports from "../../components/DrugReports";
import AsyncCreatableSelect from "react-select/async-creatable";
import SearchService from "../../services/search.service";


const ReportsPage = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const userData = currentUser && currentUser.user;
  const [drug, setDrug] = useState();

  useEffect(() => {
    document.title = "TransferMyRx::Reports";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Reports";
    }
  }, []);
  const loadOptions = async (category, inputValue, callback) => {
    const data = await SearchService.Search(category, inputValue);
    callback(data?.data);
  };
  const formatOptionLabel = ({ label, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };
  return (
    <div className="dashboard-container">
      <div className="row">
        <PharmacyBranchReports pharmacyBranchId={userData?.pharmacyBranchId} pharmacyBranchName={userData?.pharmacyName} />
      </div>
      <div className="row">
        <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
          <TopTransferedDrugsDataGrid requesttype={0} />
        </div>
        <div className="col-xl-6 col-xxl-6 col-lg-6 mb-3 widget">
          <TopTransferedDrugsDataGrid requesttype={1} />
        </div>
      </div>
      <div className="row" style={{ marginTop: 20, marginBottom: 20 }}>
        <h3>Select Drug:</h3>
        <AsyncCreatableSelect
          id="prescribed-drug-ddl"
          className="me-3 w-100"
          classNamePrefix="react-select"
          getOptionValue={(option) => option.id}
          isClearable={true}
          value={drug}
          menuPlacement={"auto"}
          onCreateOption={(inputValue) => {
            setDrug({ id: null, label: inputValue });
          }}
          onChange={(item) => {
            setDrug(item);
          }}
          loadOptions={(inputValue, callback) =>
            loadOptions("drugs", inputValue, callback)
          }
          formatOptionLabel={formatOptionLabel}
          styles={{ zIndex: 100 }}
        />
        <div style={{ marginTop: 20, marginBottom: 20 }}>  {drug && <DrugReports pharmacyBranchId={userData?.pharmacyBranchId} drugId={drug.id} drugName={drug.label} />}</div>

      </div>

    </div>
  );
};

export default ReportsPage;
