import React, { useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useSelector } from "react-redux";

import PatientService from "../../services/patient.service";
import SearchService from "../../services/search.service";

const EditPatientModal = (props) => {
  const lookups = useSelector((state) => state.lookups);

  const fieldsSchema = {
    firstname: { isRequired: true },
    lastname: { isRequired: true },
    gender: { isRequired: false },
    dateOfBirth: { isRequired: true },
    emailAddress: { isRequired: false },
    mobile: { isRequired: true },
    address: { isRequired: false, cssClass: " w-100" },
    city: { isRequired: false },
    preferredPharmacyBranch: { isRequired: false },
  };

  const [showMoreFields, setShowMoreFields] = useState(false);

  const loadOptions = async (category, inputValue, callback) => {
    const data = await SearchService.Search(category, inputValue);
    callback(data?.data);
  };

  const formatOptionLabel = ({ label, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  const toggleShowMoreFields = () => {
    setShowMoreFields(!showMoreFields);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.record?.Id || "",
      firstname: props.record?.FirstName || "",
      lastname: props.record?.LastName || "",
      emailAddress: props.record?.EmailAddress || "",
      mobile: props.record?.Mobile || "",
      phone: props.record?.Phone || "",
      gender: props.record
        ? { id: props.record?.GenderId, label: props.record?.GenderName }
        : null,
      dateOfBirth: props.record?.DateOfBirth,
      avatarImageUrl: props.record?.AvatarImageUrl || "",
      city: props.record
        ? { id: props.record?.CityId, label: props.record?.CityName }
        : null,
      address: props.record?.Address || "",
      preferredPharmacyBranch: props.record
        ? {
            id: props.record?.PreferredPharmacyBranchId,
            label: props.record?.PreferredPharmacyBranchName,
          }
        : null,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Field is required"),
      lastname: Yup.string().required("Field is required"),
      emailAddress: Yup.string().email("Invalid email address"),
      phone: Yup.string().phone("US", "Invalid phone number"),
      dateOfBirth: Yup.date().required("Field is required"),
    }),
    onSubmit: async (values) => {
      const newValues = {
        id: values.id,
        firstname: values.firstname,
        lastname: values.lastname,
        emailAddress: values.emailAddress,
        mobile: values.mobile || "-",
        phone: values.phone,
        genderId: values.gender?.id,
        dateOfBirth: values.dateOfBirth,
        avatarImageUrl: values.avatarImageUrl,
        cityId: values.city?.id,
        address: values.address,
        preferredPharmacyBranchId: values.preferredPharmacyBranch?.id,
      };
      const result = await (!values.id
        ? PatientService.Create
        : PatientService.Update)(newValues);
      if (result.error === null) {
        //dispatch(lookupSlice.actions.createPatient(result?.data));
        props.onHide(result?.data);
        formik.resetForm();
      }
    },
  });

  const getCustomClasses = (fieldName) => {
    return !showMoreFields && !fieldsSchema[fieldName].isRequired
      ? "d-none"
      : `form-group ${!!formik.errors[fieldName] ? " invalid" : ""}${
          fieldsSchema[fieldName].cssClass
            ? fieldsSchema[fieldName].cssClass
            : ""
        }${!showMoreFields ? " w-100" : ""}`;
  };

  const handleOnShowModal = () => {
    setShowMoreFields(props?.record?.Id !== undefined);
  };

  return (
    <Modal
      onHide={() => {
        props.onHide();
        formik.resetForm();
      }}
      show={props.show}
      aria-labelledby="prescription-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName={!showMoreFields ? "modal-lg" : "modal-xxl"}
      onShow={handleOnShowModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="prescription-modal-title">
          {(props.record && `Edit Patient #${props.record.Id}`) ||
            `Add New Patient`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className={getCustomClasses("firstname")}>
              <label htmlFor="first-name-input" className="form-label">
                First Name
              </label>
              {fieldsSchema["firstname"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="first-name-input"
                test-id="first-name-input"
                name="firstname"
                value={formik.values.firstname}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <small className="text-danger">{formik.errors.firstname}</small>
              )}
            </div>
            <div className={getCustomClasses("lastname")}>
              <label htmlFor="last-name-input" className="form-label">
                Last Name
              </label>
              {fieldsSchema["lastname"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                test-id="last-name-input"
                id="last-name-input"
                name="lastname"
                value={formik.values.lastname}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <small className="text-danger">{formik.errors.lastname}</small>
              )}
            </div>

            <div className={getCustomClasses("gender")}>
              <label htmlFor="gender-ddl" className="form-label">
                Gender
              </label>
              {fieldsSchema["gender"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <Select
                test-id="gender-ddl"
                id="gender-ddl"
                name="gender"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                options={lookups?.genders}
                value={formik.values.gender}
                onBlur={formik.handleBlur}
                onChange={(item) => {
                  formik.setFieldValue("gender", item);
                }}
                formatOptionLabel={formatOptionLabel}
              />
              {formik.touched.gender && formik.errors.gender && (
                <small className="text-danger">{formik.errors.gender}</small>
              )}
            </div>
            <div className={getCustomClasses("dateOfBirth")}>
              <label htmlFor="date-of-birth-input" className="form-label">
                Date Of Birth
              </label>
              {fieldsSchema["dateOfBirth"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="date-of-birth-input"
                test-id="date-of-birth-input"
                name="dateOfBirth"
                value={formik.values.dateOfBirth}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="date"
                max={moment().format("yyyy-MM-DD")}
                className="form-control"
              />
              {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                <small className="text-danger">
                  {formik.errors.dateOfBirth}
                </small>
              )}
            </div>

            <div className={getCustomClasses("emailAddress")}>
              <label htmlFor="email-address-input" className="form-label">
                Email Address
              </label>
              {fieldsSchema["emailAddress"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                test-id="email-address-input"
                id="email-address-input"
                name="emailAddress"
                value={formik.values.emailAddress}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                className="form-control"
              />
              {formik.touched.emailAddress && formik.errors.emailAddress && (
                <small className="text-danger">
                  {formik.errors.emailAddress}
                </small>
              )}
            </div>
            <div className={getCustomClasses("mobile")}>
              <label htmlFor="mobile-number-input" className="form-label">
                Mobile Number
              </label>
              {fieldsSchema["mobile"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                test-id="mobile-number-input"
                id="mobile-number-input"
                name="mobile"
                value={formik.values.mobile}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <small className="text-danger">{formik.errors.mobile}</small>
              )}
            </div>

            <div className={getCustomClasses("address")}>
              <label htmlFor="address-input" className="form-label">
                Address
              </label>
              {fieldsSchema["address"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <textarea
                test-id="address-input"
                id="address-input"
                name="address"
                value={formik.values.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
              ></textarea>
              {formik.touched.address && formik.errors.address && (
                <small className="text-danger">{formik.errors.address}</small>
              )}
            </div>

            <div className={getCustomClasses("city")}>
              <label htmlFor="city-ddl" className="form-label">
                City
              </label>
              {fieldsSchema["city"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <AsyncSelect
                test-id="city-ddl"
                id="city-ddl"
                name="city"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                isClearable={true}
                value={formik.values.city}
                onBlur={formik.handleBlur}
                loadOptions={(inputValue, callback) =>
                  loadOptions("cities", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
                onChange={(item) => {
                  formik.setFieldValue("city", item);
                }}
              />
              {formik.touched.city && formik.errors.city && (
                <small className="text-danger">{formik.errors.city}</small>
              )}
            </div>
            <div className={getCustomClasses("preferredPharmacyBranch")}>
              <label htmlFor="preferred-pharmacy-ddl" className="form-label">
                Preferred Pharmacy
              </label>
              {fieldsSchema["preferredPharmacyBranch"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <AsyncSelect
                test-id="pharmacy-branch-ddl"
                id="pharmacy-branch-ddl"
                className="me-3 w-100"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                isClearable={true}
                value={formik.values.preferredPharmacyBranch}
                loadOptions={(inputValue, callback) =>
                  loadOptions("pharmacies", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
                onBlur={formik.handleBlur}
                onChange={(item) => {
                  formik.setFieldValue("preferredPharmacyBranch", item);
                }}
              />
              {formik.touched.preferredPharmacyBranch &&
                formik.errors.preferredPharmacyBranch && (
                  <small className="text-danger">
                    {formik.errors.preferredPharmacyBranch}
                  </small>
                )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          onClick={formik.submitForm}
          test-id="add-new-patient-save"
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            props.onHide();
            formik.resetForm();
          }}
          test-id="add-new-patient-cancel"
        >
          Cancel
        </button>

        <button
          className="btn btn-outline-primary rounded-circle btn-show-more"
          type="button"
          title={`Show ${showMoreFields ? "less" : "more"} fields`}
          onClick={() => toggleShowMoreFields()}
        >
          <i
            className={`fa-solid fa-chevron-${showMoreFields ? "up" : "down"}`}
          ></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPatientModal;
