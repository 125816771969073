import React, { useEffect, useState } from "react";
import moment from "moment";
import http from "../../utility/http";
import { useRecoilState } from "recoil";
import { patinetRequestState } from "../../atoms";
import { useDebounce } from "../../utility/useDebounce";
import ViewPatientModal from "../../components/ViewPatientModal";
import ViewPharmacyBranchModal from "../../components/ViewPharmacyBranchModal";
import EditTransferRequestModal from "../../components/EditTransferRequestModal";
import RejectTransferRequestModal from "../../components/RejectTransferRequestModal";
import Swal from "sweetalert2";

const PatientsRequestPage = () => {
  const [rejectTransferRequestModalShow, setRejectTransferRequestModalShow] =
    useState(false);
  const [transferRequestModalShow, setTransferRequestModalShow] =
    useState(false);
  const [request, setRequest] = useState({});
  const [patient, setPatient] = useState({});
  const [viewPatientModalShow, setViewPatientModalShow] = useState(false);
  const [pharmacyBranch, setPharmacyBranch] = useState({});
  const [viewPharmacyBranchModalShow, setViewPharmacyBranchModalShow] =
    useState(false);
  const [patinetRequest, setPatinetRequest] =
    useRecoilState(patinetRequestState);
  useEffect(() => {
    document.title = "TransferMyRx::Patient Requests";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Patient Requests";
    }
  }, []);
  const [sortOrder, setSortOrder] = useState("desc");

  const openViewModal = async (data) => {
    setRequest(data);
    setTransferRequestModalShow(true);
    if (data?.status == "pending") {
      const result = await http.put("/v1/patient-request-transfer", {
        status: "viewed-by-pharmacy",
        traceId: data?.traceId,
      });
    }
    // /v1/patient-request-transfer
  };
  const onReject = async (reason) => {
    const result = await http.put("/v1/patient-request-transfer", {
      status: "rejected-by-to-pharmacy",
      traceId: request?.traceId,
      reason,
    });
    await getData({});
    setTransferRequestModalShow(false);
  };
  const onSubmit = async (reason) => {
    const result = await http.put("/v1/patient-request-transfer", {
      status: "accept-by-to-pharmacy",
      traceId: request?.traceId,
    });
    await getData({});
    setTransferRequestModalShow(false);
  };

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const getData = async ({
    limit = 50,
    page = 1,
    sortBy = "createdAt",
    sortOrder = "desc",
    search = "",
    filter
  }) => {
    const result = await http.post("/v1/patient-request-transfer-pagination", {
      limit,
      page,
      sortBy,
      sortOrder,
      search,
      filter
    });
    setPatinetRequest((prev) => {
      return {
        ...patinetRequest,
        results: result?.data?.result,
        total: result?.data?.totalPending,
      };
    });
    setTotal(result?.data?.totalRecords);
  };
  useEffect(() => {
    getData({ limit, page });
  }, [page, limit]);
  const sortBy = (sortBy) => {
    const sortOrder2 = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(sortOrder2);
    getData({
      limit,
      page,
      sortBy,
      sortOrder: sortOrder2,
    });
  };

  const [search, setSearch] = useState("");
  const debounce = useDebounce(search, 500);
  const [term, setTerm] = useState("");

  useEffect(() => {
    setTerm(debounce);
  }, [debounce]);
  useEffect(() => {
    getData({ limit, page: 1, search: term });
  }, [term]);

  const _renderStatus = (status, record) => {
    switch (status) {
      case "accept-by-to-pharmacy":
        return `accepted by ${record?.toPharmacyData.Name}`;
      case "rejected-by-to-pharmacy":
        return `rejected by ${record?.toPharmacyData.Name}`;
      case "accept-by-from-pharmacy":
        return `accepted by ${record?.fromPharmacyData.Name}`;
      case "rejected-by-from-pharmacy":
        return `rejected by ${record?.fromPharmacyData.Name}`;
      case "viewed-by-pharmacy":
        return `viewed by pharmacy`;
    }
    return status;
  };
  return (
    <>
      <div className="card-body datagrid p-0">
        <input
          id="username"
          type="text"
          className="form-control"
          placeholder="Search by Request#, Patient name or pharmacy"
          onChange={(event) => setSearch(event.target.value)}
          style={{ marginBottom: 20 }}
        />
        <div className="select-menu" style={{ marginBottom: 20 }}>
          <select
            className="form-select"
            onChange={(event) => {
              // setLimit(event.target.value);
              // getAll(searchText, page, 50, null, {
              //   status: event.target.value
              // });
              if (event.target.value === "0") {
                getData({ limit, page: 1, search: term, filter: null });

              } else {
                getData({ limit, page: 1, search: term, filter: { status: event.target.value === "0" ? null : event.target.value } });

              }
            }}
          >
            <option value="0">All</option>
            <option value="accept-by-to-pharmacy">Accepted</option>
            <option value="rejected-by-to-pharmacy">Rejected</option>
            <option value="viewed-by-pharmacy">Viewed</option>
            <option value="pending">Pending</option>
          </select>
        </div>
        <table className="table mb-0" test-id="data-table">
          <thead>
            <tr>
              <th
                className="text-center"
                onClick={() => {
                  sortBy("Id");
                }}
              >
                Request# <i className="fa-solid fa-sort"></i>
              </th>
              <th
                className="text-center"
                onClick={() => {
                  sortBy("createdAt");
                }}
              >
                Request Date <i className="fa-solid fa-sort"></i>
              </th>
              <th className="text-center">Patient</th>
              <th className="text-center">From Pharmacy</th>
              <th className="text-center">To Pharmacy</th>
              <th
                className="text-center"
                onClick={() => {
                  sortBy("status");
                }}
              >
                Status <i className="fa-solid fa-sort"></i>
              </th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {patinetRequest?.results?.map((record, index) => (
              <tr
                key={index}
                className={record?.status === "pending" ? "new" : ""}
              >
                <td className="text-center">{record?.Id}</td>
                <td className="text-center">
                  {moment(record?.createdAt).format("MMMM DD YYYY")}
                </td>
                <td
                  className="text-center"
                  onClick={() => {
                    setPatient(record?.patientData);
                    setViewPatientModalShow(true);
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {record?.patientData?.patientFullName}(
                  {moment(record?.patientData?.patientBirthOfDate).format(
                    "MMMM DD YYYY"
                  )}
                  )
                </td>
                <td
                  className="text-center"
                  onClick={() => {
                    setPharmacyBranch(record?.fromPharmacyData);
                    setViewPharmacyBranchModalShow(true);
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {record?.fromPharmacyData.Name}
                </td>
                <td
                  className="text-center"
                  onClick={() => {
                    setPharmacyBranch(record?.toPharmacyData);
                    setViewPharmacyBranchModalShow(true);
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {record?.toPharmacyData.Name}
                </td>
                <td className="text-center">
                  {_renderStatus(record?.status, record)}
                </td>

                <td>
                  {(record?.status == "pending" ||
                    record.status === "viewed-by-pharmacy") && (
                      <div className="d-flex justify-content-center">
                        <a
                          href="#"
                          className={`view`}
                          title="View"
                          data-toggle="tooltip"
                          onClick={async () => {
                            openViewModal(record);
                            // record.IsRead = 1;
                            // setSelectedRowIndex(index);
                            // await getDetailedById(record?.RequestId);
                            // setEditMode(false);
                            // setViewTransferRequestModalShow(true);
                          }}
                        >
                          <i className="fa-solid fa-eye"></i>
                        </a>
                      </div>
                    )}
                  {record?.status == "rejected-by-to-pharmacy" && (
                    <div className="d-flex justify-content-center">
                      <a
                        href="#"
                        className={`view`}
                        title="View"
                        data-toggle="tooltip"
                        onClick={async () => {
                          await Swal.fire({
                            title: "",
                            text: record?.reason,
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonColor: "#6c757d",
                          });
                        }}
                      >
                        <i className="fa-solid fa-info"></i>
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="card-footer d-flex justify-content-between align-items-center bg-white">
          <div className="hint-text">
            Showing <b>{(page - 1) * limit + 1}</b> to{" "}
            <b>{Math.min(page * limit, total)}</b> of <b>{total}</b> entries
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination mb-0">
              <li className="page-item ">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {Array(Math.ceil(total / limit))
                .fill()
                .map((_, i) => (
                  <li className={`page-item ${i + 1 === page ? "active" : ""}`}>
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(i + 1);
                      }}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page < Math.ceil(total / limit)) {
                      setPage(page + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <EditTransferRequestModal
        isPatinetModal={true}
        showRejectBtn={true}
        title="Patient Request Details"
        viewMode={true}
        cb={() => { }}
        show={transferRequestModalShow}
        onHide={async (refresh, transferRequestId) => {
          await getData({});
          setTransferRequestModalShow(false);
        }}
        requesttype={0}
        request={request}
        handleRejectTransferRequestModalShow={(state) =>
          setRejectTransferRequestModalShow(true)
        }
        customSubmit={onSubmit}
      />
      <ViewPharmacyBranchModal
        show={viewPharmacyBranchModalShow}
        onHide={() => setViewPharmacyBranchModalShow(false)}
        data={pharmacyBranch}
      />
      <ViewPatientModal
        show={viewPatientModalShow}
        onHide={() => setViewPatientModalShow(false)}
        data={patient}
        isPatientRequest={true}
      />

      <RejectTransferRequestModal
        show={rejectTransferRequestModalShow}
        onHide={(refresh) => {
          setRejectTransferRequestModalShow(false);
          // getAll("", 1, 50);
          // if (refresh === true) {
          //   if (editMode) {
          //     setTransferRequestModalShow(false);
          //   }
          //   handleRefresh(editMode ? undefined : request.RequestId);
          // }
        }}
        requesttype={0}
        request={request}
        customProcced={(reason) => {
          setRejectTransferRequestModalShow(false);
          onReject(reason);
        }}
      />
    </>
  );
};

export default PatientsRequestPage;
