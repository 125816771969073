import http from "../utility/http";

const Create = async (message) => {
  return await http.post("/v1/message", message);
};
const Update = async (message) => {
  return await http.put(`/v1/message/${message.id}`, message);
};
const Delete = async (id) => {
  return await http.delete(`/v1/message/${id}`, {});
};
const GetAll = async ( pageNumber , pageSize,searchText,sortBy,sortOrder) => {
  return await http.get(
    `/v1/message/list?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}&sortBy=${sortBy}&sortOrder=${sortOrder}`
  );
};
const GetById = async (id) => {
  return await http.get(`/v1/message/${id}`);
};
const AddResponse = async (message) => {
  return await http.put(`/v1/message/add-response/${message.id}`, message);
};
const MakeMessagesIsRead = async (message) => {
  return await http.put(`/v1/message/make-messages-is-read/${message.id}`, message);
};
const CloseMessage = async (message) => {
  return await http.put(`/v1/message/close-message/${message.id}`,message);
};


const api = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  AddResponse,
  CloseMessage,
  MakeMessagesIsRead
};

export default api;
