import React, { useState, useEffect } from "react";
import images from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ROUTES } from "../../constants/routes.constants";
import userService from "../../services/user.service";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/slices/user.slice";

const LoginWithCodePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginResult = await userService.LoginWithCode(code);
    if (loginResult.error === null) {
      dispatch(userSlice.actions.login(loginResult.data));
      if (loginResult.data?.user?.notificationTypeId === 10) {
        navigate(ROUTES.MESSAGE_ANOTHER_PHARMACY_ROUTE);
      } else if(loginResult.data?.user?.notificationTypeId === 1)  {
        navigate(ROUTES.TRANSFER_OUT_REQUEST_ROUTE);
      }else if(loginResult.data?.user?.notificationTypeId === 2)  {
        navigate(ROUTES.TRANSFER_IN_REQUEST_ROUTE);
      }
      
    } else {
      //Focus on field code
    }
  };

  useEffect(() => {
    document.title = "TransferMyRx::Login with code";
  }, []);

  return (
    <div
      id="login-with-code-form"
      className="auth-form p-4 p-sm-5 shadow-lg rounded-1 border-primary bg-white animate__animated"
    >
      <div className="text-center mb-3">
        <a href="https://www.TransferMyRx.com">
          <img src={images.Logo} alt="TransferMyRx" className="img-fluid" />
        </a>
      </div>
      <h5 className="text-center mb-4">Login With Code</h5>
      <form onSubmit={(event) => handleSubmit(event)}>
        <div className="col-12 mb-3">
          <label className="form-label" htmlFor="code-txt">
            Code
          </label>
          <input
            id="code-txt"
            type="text"
            className="form-control"
            name="code"
            placeholder="Code"
            onChange={(event) => setCode(event.target.value)}
          />
        </div>

        <button type="submit" className="w-100 btn btn-default btn-lg">
          Log In
        </button>
      </form>
      <div className="new-account mt-3">
        Already have account?&nbsp;
        <Link
          id="back-to-login"
          className="link-default"
          to={ROUTES.LOGIN_ROUTE}
        >
          Log In With Account
        </Link>
      </div>
    </div>
  );
};

export default LoginWithCodePage;
