import React, { useEffect } from "react";
import MessagesDataGrid from "../../components/MessageAnotherPharmacyDataGrid";


const MessageAnotherPharmacyPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Manage Users";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Message From Another Pharmacy";
    }
  }, []);

  return <MessagesDataGrid />;
};

export default MessageAnotherPharmacyPage;