import React, { useEffect, useState } from "react";
import DrugReports from "../../components/DrugReports";


const DrugReportsReportsPage = () => {
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  useEffect(() => {
    document.title = "TransferMyRx::Drugs";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Drug Reports";
    }
    const urlParams = new URLSearchParams(window.location.search);
    const drugId = urlParams.get('drugId');
    const drugName = urlParams.get('drugName');
    setId(drugId)
    setName(drugName)
  }, []);
  useEffect(() => {
  }, [id])
  return (

    <>
      {id && <DrugReports drugId={id} drugName={name} />}
    </>
  );
};

export default DrugReportsReportsPage;
