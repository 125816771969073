import { atom } from "recoil";
const firstDoseState = atom({
  key: "firstDoseState", // unique ID (with respect to other atoms/selectors)
  default: {
    pharmacies: [
      {
        Address: "1717 W University Dr Ste 412",
        CityName: "Mckinney",
        NCPDP: 5922403,
        Name: "Sandsrx Mckinney",
        StateName: "Texas",
        PharmacyId: 69406,
      },
    ],
    isInList: false,
    inFirstDose: (pharmacyId, list) => {
      return list.findIndex((item) => item.PharmacyId === pharmacyId) !== -1;
    },
  }, // default value (aka initial value)
});
export { firstDoseState };
