import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductLocatorModal from "../ProductLocatorModal";
import "./index.scss";


const ProductLocatorDataGrid = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [productLocatorModalShow, setproductLocatorModalShow] = useState(false);


  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => {
              setproductLocatorModalShow(true);
            }}
            test-id="send-or-request-transfer"
          >
            <i className="fa-solid fa-message  me-2"></i>
            <span>Product locate</span>
          </button>

        </div>


      </div>

      <ProductLocatorModal
        show={productLocatorModalShow}
        onHide={(refresh, transferRequestId) => {
          setproductLocatorModalShow(false);
        }}
      />
    </>
  );
};

export default ProductLocatorDataGrid;
