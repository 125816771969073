import React, { useState ,useRef,useEffect} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import inService from "../../services/transfer-in-request.service";
import outService from "../../services/transfer-out-request.service";
import PrescriberService from "../../services/prescriber.service";
import "./index.scss";
import ViewTransferRequestModal from "../ViewTransferRequestModal";
import EditTransferRequestModal from "../EditTransferRequestModal";
import AcceptTransferRequestModal from "../AcceptTransferRequestModal";
import RejectTransferRequestModal from "../RejectTransferRequestModal";
import CancelTransferRequestModal from "../CancelTransferRequestModal";
import FinishReviewTransferRequestModal from "../FinishReviewTransferRequestModal";
import ViewMessageAnotherPharmacyModal from "../ViewMessageAnotherPharmacyModal";
import MessageAnotherPharmacyService from "../../services/MessageAnotherPharmacy.service";
import NotificationService from "../../services/notification.service";

import { useRecoilState } from "recoil";
import notificationService from "../../services/notification.service";
import Emitter from "../../utility/emitter";
import { notificationsState } from "../../atoms";

const HeaderNotification = (props) => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [pageNumber, setPageNumber] = useState(1);
  const scrollContainerRef = useRef(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [transferRequestModalShow, setTransferRequestModalShow] =
    useState(false);
  const [acceptTransferRequestModalShow, setAcceptTransferRequestModalShow] =
    useState(false);
  const [
    finishReviewTransferRequestModalShow,
    setFinishReviewTransferRequestModalShow,
  ] = useState(false);
  const [rejectTransferRequestModalShow, setRejectTransferRequestModalShow] =
    useState(false);
  const [cancelTransferRequestModalShow, setCancelTransferRequestModalShow] =
    useState(false);
  const [record, setRecord] = useState({});
  const [requestType, setRequestType] = useState({});
  const [viewTransfer, setViewTransfer] = useState(false);
  const [viewMessageAnotherPharmacyModalShow, setViewMessageAnotherPharmacyModalShow] =
  useState(false);
  const [notfication, setNotfication] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);

 const MakeNotificationIsRead = async()=>{
  const result = await (
    NotificationService.MakeNotificationIsRead)(notfication);
    if (result.error === null) {
      updateNotifications()
    }
 }

 const updateNotifications = () => {
  notificationService.GetAll(pageNumber).then((result) => {
    if (result.error === null) {
      setNotifications(result.data);
    }
 })
  };

  const  closeModal = async() => {
    setViewMessageAnotherPharmacyModalShow(false);
    await MakeNotificationIsRead()
  };

  const getDetailedById = async (id) => {
    const result = await PrescriberService.GetById(id);
    if (result?.error === null) {
      setRequestType(0);
      setRecord(result?.data);
    }
  };

  const handleRefresh = (id) => {
    if (id !== undefined) {
      getDetailedById(id);
    }
  };
  const getDetailedInById = async (notfication) => {
    const result = await inService.GetById(notfication?.RecordId);
    if (result?.error === null) {
      setRequestType(0);
      setRecord(result?.data);
      setViewTransfer(true);
      setNotfication(notfication)
    }
  };
 
  const getDetailedOutById = async (notfication) => {
    const result = await outService.GetById(notfication?.RecordId);
    if (result?.error === null) {
      setRequestType(1);
      setRecord(result?.data);
      setViewTransfer(true);
      setNotfication(notfication)
    }
  };
  const getDetailedMessageFromPharmacyById = async (notification) => {
    const result = await MessageAnotherPharmacyService.GetById(notification?.RecordId);
    if (result?.error === null) {
      setRecord(result?.data);
      setViewMessageAnotherPharmacyModalShow(true);
      setNotfication(notification)
    }
  };
  const onClickNoti = async (notification) => {
    if (notification.Content.includes("Message from  pharmacy")) {
      await getDetailedMessageFromPharmacyById(notification);
    }else if (notification.Content.includes("transfer in")) {
      await getDetailedInById(notification);
    } else {
      await getDetailedOutById(notification);
    }
  };
  const getData = async () => {
    try {
      const result = await notificationService.GetAll(pageNumber);
      if (result.error === null) {
        setNotifications(result.data);
        if (result.data == null && pageNumber > 1) {
          setPageNumber(prevPageNumber => prevPageNumber - 1);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData(pageNumber)
  }, [pageNumber]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
    const isScrollingDown = scrollTop > lastScrollTop;
  
    if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 10) { 
      setPageNumber(prevPageNumber => {
        const nextPageNumber = prevPageNumber + 1;
        return nextPageNumber;
      });
    } else if (!isScrollingDown && scrollTop === 0 && pageNumber > 1) {
      setPageNumber(prevPageNumber => prevPageNumber - 1);
    }
  
    setLastScrollTop(scrollTop);
  };
  
  useEffect(() => {
    // Ensure the element exists before adding the event listener
    const scrollElement = scrollContainerRef.current;
    if (scrollElement) {
      const handleScrollEvent = () => handleScroll();
      scrollElement.addEventListener("scroll", handleScrollEvent);
  
      // Return a cleanup function to remove the event listener
      return () => {
        scrollElement.removeEventListener("scroll", handleScrollEvent);
      };
    }
  }, []); //

  const handleMouseWheel = () => {
   // scrollContainerRef.current = scrollContainerRef.current;
    handleScroll();
  };
  return (
    <>
      <Dropdown className="header-notification" >
        <Dropdown.Toggle variant="">
          {props.notifications?.Total !== 0 && (
            <span>{props.notifications?.Total}</span>
          )}
          <i className="fas fa-bell"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <h6 className="m-0 p-1 ps-3">Notifications</h6>
          <Dropdown.Divider />

          {props.notifications?.notifications?.length !== 0 ? (
            <>
              <div className="dropdown-menu-scrollable" ref={scrollContainerRef}
              onWheel={handleMouseWheel}
              >
                {props.notifications?.notifications?.map(
                  (notification, index) => (
                    <Dropdown.Item 
                      key={index}
                      className={`text-wrap ${
                        notification.IsRead === 0 ? " new" : ""
                      }`}
                      onClick={() => onClickNoti(notification)}
                    >
                     
                      <div
                        dangerouslySetInnerHTML={{
                          __html:  notification.Content,
                        }}
                      ></div>
                    </Dropdown.Item>
                  )
                )}
              </div>
              <Dropdown.Divider />
              <Link className="btn btn-link text-decoration-none p-0 d-block center">
                See All Notifications
              </Link>
            </>
          ) : (
            <div className="text-center text-secondary p-3">
              You don't have any notifications right now!
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <ViewTransferRequestModal
        show={viewTransfer}
        onHide={() => { setViewTransfer(false); MakeNotificationIsRead(); }}
        requesttype={requestType}
        request={record}
        handleEditTransferRequestModalShow={(state) =>
          setTransferRequestModalShow(state)
        }
        handleAcceptTransferRequestModalShow={(state) =>
          setAcceptTransferRequestModalShow(state)
        }
        handleFinishReviewTransferRequestModalShow={(state) =>
          setFinishReviewTransferRequestModalShow(state)
        }
        handleRejectTransferRequestModalShow={(state) =>
          setRejectTransferRequestModalShow(state)
        }
        handleCancelTransferRequestModalShow={(state) =>
          setCancelTransferRequestModalShow(state)
        }
      />

      <EditTransferRequestModal
        show={transferRequestModalShow}
        onHide={(refresh, transferRequestId) => {
          setTransferRequestModalShow(false);
          if (refresh === true) {
            handleRefresh(transferRequestId);
          }
        }}
        requesttype={requestType}
        request={record}
        handleAcceptTransferRequestModalShow={(state) =>
          setAcceptTransferRequestModalShow(state)
        }
        handleFinishReviewTransferRequestModalShow={(state) =>
          setFinishReviewTransferRequestModalShow(state)
        }
        handleRejectTransferRequestModalShow={(state) =>
          setRejectTransferRequestModalShow(state)
        }
        handleCancelTransferRequestModalShow={(state) =>
          setCancelTransferRequestModalShow(state)
        }
      />

      <AcceptTransferRequestModal
        show={acceptTransferRequestModalShow}
        onHide={(refresh) => {
          setAcceptTransferRequestModalShow(false);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : record.RequestId);
          }
        }}
        requesttype={requestType}
        request={record}
      />

      <FinishReviewTransferRequestModal
        show={finishReviewTransferRequestModalShow}
        onHide={(refresh) => {
          setFinishReviewTransferRequestModalShow(false);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : record.RequestId);
          }
        }}
        requesttype={requestType}
        request={record}
      />

      <RejectTransferRequestModal
        show={rejectTransferRequestModalShow}
        onHide={(refresh) => {
          setRejectTransferRequestModalShow(false);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : record.RequestId);
          }
        }}
        requesttype={requestType}
        request={record}
      />
      <CancelTransferRequestModal
        show={cancelTransferRequestModalShow}
        onHide={(refresh) => {
          setCancelTransferRequestModalShow(false);
          if (refresh === true) {
            if (editMode) {
              setTransferRequestModalShow(false);
            }
            handleRefresh(editMode ? undefined : record.RequestId);
          }
        }}
        request={record}
      />
      {viewMessageAnotherPharmacyModalShow && <ViewMessageAnotherPharmacyModal
        isOpen={viewMessageAnotherPharmacyModalShow}
        onClose={closeModal}
        record={record}
      />
      }
    </>
  );
};

export default HeaderNotification;
