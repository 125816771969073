/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import EditModal from "../EditPharmacyBranchModal";
import ViewModal from "../ViewPharmacyBranchModal";
import PharmacyBranchService from "../../services/pharmacy-branch.service";
import helpers from "../../utility/helpers";
import Emitter from "../../utility/emitter";
import Swal from "sweetalert2";
import SearchService from "../../services/search.service";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "./index.scss";
import { Pagination } from "../Pagination";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../constants/routes.constants";
import axios from "axios";
const ReferalsDataGrid = (props) => {
  const [records, setRecords] = useState([])
  const navigate = useNavigate();
  const getAll = async (refBy) => {
    const result = await axios
      .post(`${process.env.REACT_APP_API_SERVER}/v1/pharmacy-branch-by-ref`, { refBy })
    console.log("result", result)
    setRecords(result?.data?.data?.data)
  }
  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          <div className="search-box">
            <i className="fa fa-search"></i>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search…"
                spellCheck="false"
                data-ms-editor="true"
                onChange={(event) => getAll(event.target.value)}
              />
            </form>
          </div>
        </div>

        <div className="card-body datagrid p-0">
          <table className="table mb-0" test-id="data-table">
            <thead>
              <tr>
                <th
                  className="text-center"
                  // onClick={() => {
                  //   setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  //   getAll(
                  //     "pharmacies",
                  //     "",
                  //     page,
                  //     "id",
                  //     sortOrder === "asc" ? "desc" : "asc"
                  //   );
                  // }}
                  style={{ width: 50 }}
                >
                  {/* # <i className="fa fa-sort"></i> */}#
                </th>

                <th className="text-center">Name</th>
                <th className="text-center">NCPDP</th>
                <th className="text-center">State</th>
                <th className="text-center">City</th>
                <th className="text-center">Address</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((record, index) => (
                <tr
                  key={index}
                >
                  <td className="text-center">{record.Id}</td>
                  <td className="text-left">{record.Name}</td>
                  <td className="text-center">{record.NCPDP}</td>
                  <td className="text-center">{record.StateName}</td>
                  <td className="text-center">{record.CityName}</td>
                  <td className="text-left">{record.Address}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <div
                        title="View"
                        data-toggle="tooltip"
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          navigate(`${ROUTES.PHARMACY_BRANCH_REPORTS_ROUTE}?pharmacyBranchId=${record?.Id}&pharmacyBranchName=${record?.Name}`);
                        }}
                      >
                        <i className="fa fa-info"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default ReferalsDataGrid;
