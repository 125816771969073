import http from "../utility/http";

const Create = async (pharmacyBranchUser) => {
  return await http.post("/v1/pharmacy-branch-user", pharmacyBranchUser);
};

const Update = async (pharmacyBranchUser) => {
  return await http.put(`/v1/pharmacy-branch-user`, pharmacyBranchUser);
};

const Delete = async (id) => {
  return await http.delete(`/v1/pharmacy-branch-user/${id}`, {});
};
const GetAll = async (keyword, page = 1, limit = 50, sort, filter) => {
  let sortQuery = "";
  let filterQuery = ""
  if (filter.status && filter.status !== "0") {
    filterQuery += `&status=${filter.status}`
  }
  if (sort) {
    sortQuery = `&sortBy=${sort?.sortBy}&sortOrder=${sort?.sortOrder}`;
  }
  return await http.get(
    `/v1/pharmacy-branch-user/list?q=${keyword}&page=${page}&limit=${limit}${sortQuery}${filterQuery}`
  );
};

const GetById = async (id) => {
  return await http.get(`/v1/pharmacy-branch-user/${id}`);
};

const Enable = async (pharmacyBranchUserId) => {
  return await http.post("/v1/pharmacy-branch-user/enable", {
    pharmacyBranchUserId,
  });
};

const Disable = async (pharmacyBranchUserId) => {
  return await http.post("/v1/pharmacy-branch-user/disable", {
    pharmacyBranchUserId,
  });
};

const Activate = async (pharmacyBranchUserId, pharmacyBranchId) => {
  return await http.post("/v1/pharmacy-branch-user/activate", {
    pharmacyBranchUserId,
    pharmacyBranchId,
  });
};

const Deactivate = async (pharmacyBranchUserId, pharmacyBranchId) => {
  return await http.post("/v1/pharmacy-branch-user/deactivate", {
    pharmacyBranchUserId,
    pharmacyBranchId,
  });
};

const Invite = async (pharmacyBranchUserId, pharmacyBranchId) => {
  return await http.post("/v1/pharmacy-branch-user/invite", {
    pharmacyBranchUserId,
    pharmacyBranchId,
  });
};

const api = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  Enable,
  Disable,
  Activate,
  Deactivate,
  Invite,
};

export default api;
