import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Select, { components, MenuProps } from "react-select";
import { debounce } from "lodash";
import SearchService from "../../services/search.service";
import AsyncCreatableSelect from "react-select/async-creatable";
import Emitter from "../../utility/emitter";
import moment from "moment";
import helpers from "../../utility/helpers";
import Card from "react-bootstrap/Card";
import DrugRefillsHistoryDataGrid from "../DrugRefillsHistoryDataGrid";

const AllActiveRxForm = (props) => {
  const _renderError = (prescription, field) => {
    try {
      if (props.prescriptionsErrors) {
        if (props.prescriptionsErrors[prescription.index]) {
          if (
            props.prescriptionsErrors[prescription.index][field]?.length > 0
          ) {
            return (
              <small className="text-danger">
                {props.prescriptionsErrors[prescription.index][field]}
              </small>
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeState = (prescription, value) => {
    props.setPrescriptions((prev) => {
      return prev.map((itemInMap) =>
        itemInMap.index === prescription.index
          ? {
              ...itemInMap,
              ...value,
            }
          : itemInMap
      );
    });
  };
  const findPrescription = (index) => {
    return props.prescriptions.find(
      (prescription) => prescription.index === index
    );
  };
  const _renderRxNumber = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("rxNumber")}
        style={{
          height: 100,
        }}
      >
        <label htmlFor="rx-number-input" className="form-label">
          Rx Number
        </label>
        <input
          test-id="rx-number-input"
          id="rx-number-input"
          value={prescription.rxNumber || ""}
          onChange={(event) => {
            props.setPrescriptions(
              props.prescriptions.map((item) =>
                item.index === prescription.index
                  ? { ...item, rxNumber: event.target.value }
                  : item
              )
            );
          }}
          type="text"
          className="form-control"
        />
        {_renderError(prescription, "rxNumber")}
      </div>
    );
  };

  const [autoFocus, setAutoFocus] = useState(false);
  // useEffect(() => {
  //   if (props.prescriptionsErrors) {
  //     if (props.prescriptionsErrors[1]) {
  //       if (
  //         props.prescriptionsErrors[1]?.prescribedDrug === "Field is required"
  //       ) {
  //         console.log("HEEEE");
  //         const input = document.getElementById(`prescribed-drug-ddl-${1}`).click();

  //         setAutoFocus(true);
  //       }
  //     }
  //   }
  // }, [props.prescriptionsErrors]);
  const _renderPrescribedDrug = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("prescribedDrug")}
        key={`prescribedDrug-${prescription.index}`}
        style={{
          height: 100,
        }}
      >
        <label htmlFor="prescribed-drug-ddl" className="form-label">
          Prescribed Drug
        </label>
        <AsyncCreatableSelect
          onInputChange={(item) => {}}
          id={`prescribed-drug-ddl-${prescription.index}`}
          test-id={`prescribed-drug-ddl-${prescription.index}`}
          className="me-3 w-100"
          classNamePrefix="react-select"
          menuPosition="fixed"
          getOptionValue={(option) => option.id}
          isClearable={true}
          value={prescription.prescribedDrug}
          onCreateOption={(inputValue) => {
            props.setPrescriptions((prev) => {
              return prev.map((item) =>
                item.index === prescription.index
                  ? {
                      ...item,
                      prescribedDrug: {
                        id: null,
                        label: inputValue,
                      },
                      dispensedDrug: {
                        id: null,
                        label: inputValue,
                      },
                    }
                  : item
              );
            });
          }}
          onChange={(item) => {
            console.log(item);
            if (item?.controlledSubstanceCode === "2") {
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        prescribedDrug: null,
                        dispensedDrug: null,
                      }
                    : itemInMap
                );
              });
              Emitter.emit("SHOW_NOTIFICATION", {
                message: "Unable to proceed with the selected prescribed drug!",
                isError: true,
              });
              return;
            }
            if (
              props.requesttype === 1 &&
              item?.controlledSubstanceCode !== undefined &&
              item?.controlledSubstanceCode !== ""
            ) {
              props.setCsCheckpointModalShow(true);
            } else {
              props.setCsCheckpointResult({});
            }
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      prescribedDrug: item,
                      dispensedDrug: item,
                    }
                  : itemInMap
              );
            });

            if (!!findPrescription(prescription.index)?.rxIssueDate) {
              const rxIssueDateValue = moment(
                findPrescription(prescription.index)?.rxIssueDate
              );
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        expirationDate: rxIssueDateValue
                          .add(
                            item?.controlledSubstanceCode !== undefined &&
                              item?.controlledSubstanceCode !== ""
                              ? 179
                              : 364,
                            "days"
                          )
                          .format("yyyy-MM-DD"),
                      }
                    : itemInMap
                );
              });
            }
            if (
              !!findPrescription(prescription.index)?.firstFillDate &&
              item !== null &&
              findPrescription(prescription.index)?.qtyPrescribed > 0
            ) {
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        refillsHistory: [
                          {
                            Date: props.prescriptions[prescription.index]
                              .firstFillDate,
                            DrugId: item.id,
                            DrugName: item.label,
                            Qty: props.prescriptions[prescription.index]
                              .qtyPrescribed,
                            editable: false,
                            isFirstRefill: true,
                          },
                          ...findPrescription(
                            prescription.index
                          ).refillsHistory.filter(
                            (refillHistory) =>
                              refillHistory.isFirstRefill !== true
                          ),
                        ],
                      }
                    : itemInMap
                );
              });
            }
          }}
          loadOptions={(inputValue, callback) =>
            props.loadOptions("drugs", inputValue, callback)
          }
          formatOptionLabel={props.formatOptionLabel}
        />
        {_renderError(prescription, "prescribedDrug")}
      </div>
    );
  };

  const _renderPrescriber = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("prescriber")}
        style={{
          height: 100,
        }}
      >
        <label htmlFor="prescriber-ddl" className="form-label">
          Prescriber
        </label>
        <div className="d-flex" key={`${prescription.index}-prescriber`}>
          <AsyncSelect
            id="prescriber-ddl"
            test-id="prescriber-ddl"
            className="me-3 w-100"
            classNamePrefix="react-select"
            menuPosition="fixed"
            getOptionValue={(option) => option.id}
            isClearable={true}
            value={prescription.prescriber}
            loadOptions={(inputValue, callback) =>
              props.loadOptions("prescribers", inputValue, callback)
            }
            components={{
              Menu: props.PrescriberMenu,
              SingleValue: props.SingleValue,
            }}
            formatOptionLabel={(dataRow, { inputValue }) => {
              return (
                <div className="row">
                  <div className="col">{dataRow.label}</div>
                  <div className="col">{dataRow.npi}</div>
                  <div className="col">
                    {(dataRow.address &&
                      helpers.getPharmacyFormattedAddress(
                        dataRow.state,
                        dataRow.city,
                        dataRow.address
                      )) ||
                      ""}
                  </div>
                </div>
              );
            }}
            onChange={(item) => {
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        prescriber: item,
                      }
                    : itemInMap
                );
              });
              if (item?.PrescriberSupervisorId) {
                props.setPrescriptions((prev) => {
                  return prev.map((itemInMap) =>
                    itemInMap.index === prescription.index
                      ? {
                          ...itemInMap,
                          prescriberSupervisor: {
                            id: item.PrescriberSupervisorId,
                            label: item.PrescriberSupervisorName,
                          },
                        }
                      : itemInMap
                  );
                });
              }
            }}
          />
          <button
            className="btn btn-outline-primary rounded-circle"
            type="button"
            title="Add New Prescriber"
            onClick={() => props.setPrescriberModalShow(1)}
          >
            <i className="fa-solid fa-plus"></i>
          </button>
        </div>
        {_renderError(prescription, "prescriber")}
      </div>
    );
  };
  const _renderPrescriberSupervisor = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("prescriberSupervisor")}
        style={{
          height: 100,
        }}
      >
        <label htmlFor="prescriber-supervisor-ddl" className="form-label">
          Prescriber Supervisor
        </label>
        <div
          className="d-flex"
          key={`${prescription.index}-prescriber-supervisor`}
        >
          <AsyncSelect
            id="prescriber-supervisor-ddl"
            test-id="prescriber-supervisor-ddl"
            className="me-3 w-100"
            classNamePrefix="react-select"
            menuPosition="fixed"
            getOptionValue={(option) => option.id}
            isClearable={true}
            value={prescription.prescriberSupervisor}
            components={{
              Menu: props.PrescriberMenu,
              SingleValue: props.SingleValue,
            }}
            formatOptionLabel={(dataRow, { inputValue }) => {
              return (
                <div className="row">
                  <div className="col">{dataRow.label}</div>
                  <div className="col">{dataRow.npi}</div>
                  <div className="col">
                    {(dataRow.address &&
                      helpers.getPharmacyFormattedAddress(
                        dataRow.state,
                        dataRow.city,
                        dataRow.address
                      )) ||
                      ""}
                  </div>
                </div>
              );
            }}
            loadOptions={(inputValue, callback) =>
              props.loadOptions("prescribers", inputValue, callback)
            }
            onChange={(item) => {
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        prescriberSupervisor: item,
                      }
                    : itemInMap
                );
              });
            }}
          />
          <button
            className="btn btn-outline-primary rounded-circle"
            type="button"
            title="Add New Prescriber"
            onClick={() => props.setPrescriberModalShow(2)}
          >
            <i className="fa-solid fa-plus"></i>
          </button>
        </div>
        {_renderError(prescription, "prescriberSupervisor")}
      </div>
    );
  };

  const _renderIssueDate = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("rxIssueDate")}
        style={{
          height: 100,
        }}
        key={`issue-date-${prescription.index}`}
      >
        <label htmlFor="issue-date-input" className="form-label">
          Date Written
        </label>
        <input
          id="issue-date-input"
          test-id="issue-date-input"
          value={prescription.rxIssueDate}
          onChange={(event) => {
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      rxIssueDate: event.target.value,
                    }
                  : itemInMap
              );
            });

            const item =
              findPrescription(prescription.index)?.prescribedDrug ||
              findPrescription(prescription.index)?.dispensedDrug;
            const rxIssueDateValue = moment(event.target.value);
            if (!!item && rxIssueDateValue) {
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        expirationDate: rxIssueDateValue
                          .add(
                            item?.controlledSubstanceCode !== undefined &&
                              item?.controlledSubstanceCode !== ""
                              ? 179
                              : 364,
                            "days"
                          )
                          .format("yyyy-MM-DD"),
                      }
                    : itemInMap
                );
              });
            }
          }}
          type="date"
          max={moment().format("yyyy-MM-DD")}
          min={moment().subtract(1, "years").format("yyyy-MM-DD")}
          className="form-control"
        />

        {_renderError(prescription, "rxIssueDate")}
      </div>
    );
  };
  const _renderDispenseAsWrittenType = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("dispenseAsWrittenType")}
        style={{ height: 100 }}
      >
        <label htmlFor="dispenseAsWrittenType-ddl" className="form-label">
          Dispense As Written (DAW)
        </label>
        <Select
          id="dispenseAsWrittenType-ddl"
          test-id="dispenseAsWrittenType-ddl"
          name="dispenseAsWrittenType"
          classNamePrefix="react-select"
          getOptionValue={(option) => option.id}
          isClearable={true}
          menuPlacement={"auto"}
          options={props.lookups?.dispenseAsWrittenTypes}
          value={prescription.dispenseAsWrittenType}
          onChange={(item) => {
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      dispenseAsWrittenType: item,
                    }
                  : itemInMap
              );
            });
          }}
          formatOptionLabel={props.formatOptionLabel}
        />
        {_renderError(prescription, "dispenseAsWrittenType")}
      </div>
    );
  };
  const _renderExpirationDate = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("expirationDate")}
        key={`expiration-date-${prescription.index}`}
        style={{ height: 100 }}
      >
        <label htmlFor="expiration-date-input" className="form-label">
          Expiration Date
        </label>
        <input
          id="expiration-date-input"
          test-id="expiration-date-input"
          value={prescription.expirationDate}
          min={moment().format("yyyy-MM-DD")}
          onChange={(event) => {
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      expirationDate: event.target.value,
                    }
                  : itemInMap
              );
            });
          }}
          type="date"
          className="form-control"
        />

        {_renderError(prescription, "expirationDate")}
      </div>
    );
  };
  const _renderQtyPrescribed = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("qtyPrescribed")}
        key={`prescription-qty-${prescription.index}`}
        style={{
          height: 100,
        }}
      >
        <label htmlFor="qty-prescribed-input" className="form-label">
          Qty Prescribed
        </label>
        {props.requesttype === 1 ? <i className="fa fa-asterisk"></i> : null}
        <input
          id="qty-prescribed-input"
          test-id="qty-prescribed-input"
          type="number"
          min="1"
          className="form-control"
          value={prescription.qtyPrescribed}
          onChange={(event) => {
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      qtyPrescribed: event?.target.valueAsNumber,
                    }
                  : itemInMap
              );
            });
            if (
              findPrescription(prescription.index)?.refillsRemaining !==
                undefined &&
              findPrescription(prescription.index)?.refillsRemaining !== 0
            ) {
              let extra = findPrescription(prescription.index)?.neverFilled
                ? event?.target.valueAsNumber
                : 0;
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        qtyRemaining:
                          event?.target.valueAsNumber *
                            findPrescription(prescription.index)
                              ?.refillsRemaining +
                          extra,
                      }
                    : itemInMap
                );
              });
            }

            if (
              !!findPrescription(prescription.index)?.firstFillDate &&
              findPrescription(prescription.index)?.prescribedDrug !== null &&
              event?.target.valueAsNumber > 0
            ) {
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        refillsHistory: [
                          {
                            Date: findPrescription(prescription.index)
                              ?.firstFillDate,
                            DrugId:
                              props?.prescriptions[prescription.index]
                                ?.prescribedDrug.id,
                            DrugName:
                              props?.prescriptions[prescription.index]
                                ?.prescribedDrug.label,
                            Qty: event?.target.valueAsNumber,
                            editable: false,
                            isFirstRefill: true,
                          },
                          ...findPrescription(
                            prescription.index
                          )?.refillsHistory.filter(
                            (refillHistory) =>
                              refillHistory.isFirstRefill !== true
                          ),
                        ],
                      }
                    : itemInMap
                );
              });
            }
          }}
        />
        {_renderError(prescription, "qtyPrescribed")}
      </div>
    );
  };
  const _renderOriginalRefills = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("originalRefills")}
        style={{ height: 100 }}
        key={`originalRefills-${prescription.index}`}
      >
        <label htmlFor="original-refills-input" className="form-label">
          Original Refills
        </label>
        {props.requesttype === 1 ? (
          <span className="form-field-optional-mark">(optional)</span>
        ) : null}
        <input
          id="original-refills-input"
          test-id="original-refills-input"
          type="number"
          min="1"
          className="form-control"
          value={prescription.originalRefills}
          onChange={(event) =>
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      originalRefills: event?.target.valueAsNumber,
                    }
                  : itemInMap
              );
            })
          }
        />
      </div>
    );
  };
  const _renderRefillsRemaining = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("refillsRemaining")}
        style={{ height: 100 }}
        key={`refillsRemaining-${prescription.index}`}
      >
        <label htmlFor="refills-remaining-input" className="form-label">
          Refills Remaining
        </label>
        {props.requesttype === 1 ? (
          <span className="form-field-optional-mark">(optional)</span>
        ) : null}
        <input
          id="refills-remaining-input"
          test-id="refills-remaining-input"
          type="number"
          min="1"
          className="form-control"
          value={prescription.refillsRemaining}
          onChange={(event) => {
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      refillsRemaining: event?.target.valueAsNumber,
                    }
                  : itemInMap
              );
            });
            if (
              findPrescription(prescription.index)?.qtyPrescribed !==
                undefined &&
              findPrescription(prescription.index)?.qtyPrescribed !== 0
            ) {
              let extra = findPrescription(prescription.index)?.neverFilled
                ? findPrescription(prescription.index)?.qtyPrescribed
                : 0;
              props.setPrescriptions((prev) => {
                return prev.map((itemInMap) =>
                  itemInMap.index === prescription.index
                    ? {
                        ...itemInMap,
                        qtyRemaining:
                          event?.target.valueAsNumber *
                            findPrescription(prescription.index)
                              ?.qtyPrescribed +
                          extra,
                      }
                    : itemInMap
                );
              });
            }
          }}
        />
        {_renderError(prescription, "refillsRemaining")}
      </div>
    );
  };
  const _renderQtyRemaining = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("qtyRemaining")}
        style={{ height: 100 }}
        key={`refillsRemaining-${prescription.index}`}
      >
        <label htmlFor="qty-remaining-input" className="form-label">
          Qty Remaining
        </label>
        <input
          id="qty-remaining-input"
          test-id="qty-remaining-input"
          type="number"
          min="1"
          className="form-control"
          value={findPrescription(prescription.index)?.qtyRemaining}
          onChange={(event) => {
            props.setPrescriptions((prev) => {
              return prev.map((itemInMap) =>
                itemInMap.index === prescription.index
                  ? {
                      ...itemInMap,
                      qtyRemaining: event?.target.valueAsNumber,
                    }
                  : itemInMap
              );
            });
          }}
        />
        {_renderError(prescription, "qtyRemaining")}
      </div>
    );
  };
  const _renderUploadImage = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("uploadImg")}
        style={{ height: 100 }}
        key={`uploadImg-${prescription.index}`}
      >
        <label htmlFor="upload-img-input" className="form-label">
          Upload Image
        </label>
        {props.requesttype === 1 ? (
          <span className="form-field-optional-mark">(optional)</span>
        ) : null}
        <input
          id="upload-img-input"
          test-id="upload-img-input"
          type="file"
          className="form-control"
          // value={qtyRemaining}
          onChange={(e) =>
            props.uploadHandler(e, (key) => {
              onChangeState(prescription, { fileKey: key });
            })
          }
        />
      </div>
    );
  };
  const _renderNotes = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("notes")}
        style={{ height: 100 }}
        key={`notes-${prescription.index}`}
      >
        <label htmlFor="address-input" className="form-label">
          Notes
        </label>
        {props.requesttype === 1 ? (
          <span className="form-field-optional-mark">(optional)</span>
        ) : null}
        <textarea
          id="notes-input"
          test-id="notes-input"
          name="notes"
          className="form-control"
          value={prescription.notes}
          onChange={(event) =>
            onChangeState(prescription, { notes: event?.target.value })
          }
        ></textarea>

        {_renderError(prescription, "notes")}
      </div>
    );
  };
  const _renderDirections = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("directions")}
        style={{ height: 100 }}
        key={`directions-${prescription.index}`}
      >
        <label htmlFor="address-input" className="form-label">
          Directions
        </label>
        <textarea
          id="directions-input"
          test-id="directions-input"
          name="directions"
          className="form-control"
          value={prescription.directions}
          onChange={(event) =>
            onChangeState(prescription, { directions: event?.target.value })
          }
        ></textarea>
        {_renderError(prescription, "directions")}
      </div>
    );
  };
  const _renderNeverFilled = (prescription) => {
    return (
      <div
        className={props.getCustomClasses("neverFilled")}
        key={`neverFilled-${prescription.index}`}
      >
        {_renderError(prescription, "refillsHistory")}
        <Card>
          <Card.Header>
            <div
              className="form-check"
              id="never-filled-checkbox"
              test-id="never-filled-checkbox"
            >
              <input
                id="never-filled-cb"
                test-id="never-filled-cb"
                type="checkbox"
                className="form-check-input"
                disabled={
                  prescription.csCheckpointResult?.answer1 === true ||
                  !!props.request?.ControlledSubstanceCode
                }
                checked={prescription.neverFilled === 1}
                onChange={(event) => {
                  onChangeState(prescription, {
                    neverFilled: event.target.checked === true ? 1 : 0,
                  });
                  if (event.target.checked) {
                    onChangeState(prescription, {
                      qtyRemaining:
                        findPrescription(prescription.index)?.qtyRemaining +
                        findPrescription(prescription.index)?.qtyPrescribed,
                    });
                  } else {
                    onChangeState(prescription, {
                      qtyRemaining:
                        findPrescription(prescription.index)?.qtyRemaining -
                        findPrescription(prescription.index)?.qtyPrescribed,
                    });
                  }
                }}
              />
              <label className="form-check-label" htmlFor="never-filled-cb">
                Never Filled
              </label>
            </div>
          </Card.Header>

          <Card.Body className={`${prescription.neverFilled ? "d-none" : ""}`}>
            <div className={props.getCustomClasses("firstFillDate")}>
              <label htmlFor="first-fill-date-input" className="form-label">
                First Fill Date
              </label>
              <input
                id="first-fill-date-input"
                test-id="first-fill-date-input"
                type="date"
                value={findPrescription(prescription.index)?.firstFillDate}
                max={moment().format("yyyy-MM-DD")}
                min={
                  findPrescription(prescription.index)?.rxIssueDate ||
                  moment().format("yyyy-MM-DD")
                }
                className="form-control"
                onChange={(event) => {
                  onChangeState(prescription, {
                    firstFillDate: event.target.value,
                  });
                  if (
                    !!event.target.value &&
                    findPrescription(prescription.index)?.prescribedDrug !==
                      null &&
                    findPrescription(prescription.index)?.qtyPrescribed > 0
                  ) {
                    onChangeState(prescription, {
                      refillsHistory: [
                        {
                          Date: event.target.value,
                          DrugId: findPrescription(prescription.index)
                            ?.prescribedDrug.id,
                          DrugName: findPrescription(prescription.index)
                            ?.prescribedDrug.label,
                          Qty: findPrescription(prescription.index)
                            ?.qtyPrescribed,
                          editable: false,
                          isFirstRefill: true,
                        },
                        ...findPrescription(
                          prescription.index
                        )?.refillsHistory?.filter(
                          (refillHistory) =>
                            refillHistory.isFirstRefill !== true
                        ),
                      ],
                    });
                  }
                }}
              />

              {/* <small className="text-danger">
                {props.formErrors.firstFillDate}
              </small> */}
            </div>

            <div className={props.getCustomClasses("firstFillDate")}>
              <label htmlFor="last-fill-date-input" className="form-label">
                Last Fill Date
              </label>
              <input
                id="last-fill-date-input"
                test-id="last-fill-date-input"
                type="date"
                value={findPrescription(prescription.index)?.lastFillDate}
                max={moment().format("yyyy-MM-DD")}
                min={
                  findPrescription(prescription.index)?.rxIssueDate ||
                  moment().format("yyyy-MM-DD")
                }
                className="form-control"
                onChange={(event) =>
                  onChangeState(prescription, {
                    lastFillDate: event.target.value,
                  })
                }
              />
              {/* <small className="text-danger">
                {props.formErrors.lastFillDate}
              </small> */}
            </div>

            <div className={props.getCustomClasses("refillsHistory")}>
              <label className="form-label">Refills History</label>
              <DrugRefillsHistoryDataGrid
                refills={
                  findPrescription(prescription.index)?.refillsHistory || []
                }
                firstFill={findPrescription(prescription.index)?.firstFillDate}
                setDrugRefillsHistory={(refills) => {
                  console.log("refills", refills);
                  onChangeState(prescription, { refillsHistory: refills });
                }}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  };
  return (
    <>
      <div className="container">
        <div className={props.getCustomClasses("patient")}>
          <label htmlFor="patient-ddl" className="form-label">
            Patient
          </label>
          <i className="fa fa-asterisk"></i>
          <div className="d-flex">
            {props?.viewMode ? (
              <>{props?.request?.patientData?.patientFullName}</>
            ) : (
              <AsyncSelect
                id="patient-ddl"
                test-id="patient-ddl"
                className="me-3 w-100"
                classNamePrefix="react-select"
                menuPosition="fixed"
                components={{
                  Menu: props.PatientMenu,
                  SingleValue: props.SingleValue,
                }}
                getOptionValue={(option) => option.id}
                isClearable={true}
                value={props.patient}
                loadOptions={(inputValue, callback) =>
                  props.loadOptions("patients", inputValue, callback)
                }
                formatOptionLabel={(dataRow, { inputValue }) => {
                  return (
                    <div className="row">
                      <div className="col">{dataRow.label}</div>
                      <div className="col">{dataRow.mobile}</div>
                      <div className="col">{dataRow.dateOfBirth}</div>
                    </div>
                  );
                }}
                onChange={(item) => {
                  props.setPatient(item);
                  if (item?.PreferredPharmacyBranchId) {
                    props.setPharmacyBranch({
                      id: item.PreferredPharmacyBranchId,
                      label: item.PreferredPharmacyBranch,
                    });
                  }
                }}
              />
            )}
            {!props.viewMode && (
              <button
                className="btn btn-outline-primary rounded-circle"
                type="button"
                title="Add New Patient"
                test-id="add-new-patient"
                onClick={() => props.setPatientModalShow(true)}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            )}
          </div>

          <small className="text-danger">{props.formErrors.patient}</small>
        </div>
        <div className={props.getCustomClasses("pharmacyBranch")}>
          <label htmlFor="pharmacy-ddl" className="form-label">
            Pharmacy
          </label>
          <i className="fa fa-asterisk"></i>

          <AsyncSelect
            test-id="pharmacy-ddl"
            id="pharmacy-ddl"
            className="me-3 w-100"
            classNamePrefix="react-select"
            menuPosition="fixed"
            getOptionValue={(option) => option.id}
            isClearable={true}
            value={props.pharmacyBranch}
            loadOptions={(inputValue, callback) =>
              props.loadOptions("pharmacies", inputValue, callback)
            }
            formatOptionLabel={props.formatOptionLabel}
            onChange={(item) => {
              props.setPharmacyBranch(item);
            }}
          />
          <small className="text-danger">
            {props.formErrors.pharmacyBranch}
          </small>
        </div>
        <div>
          {props.prescriptions.map((prescription, index) => {
            return (
              <div
                style={{
                  borderRadius: 10,
                  border: "1px solid #000",
                  padding: 10,
                  marginBottom: 10,
                }}
              >
                <div
                  onClick={() => {
                    props.setPrescriptions(
                      props.prescriptions.filter(
                        (item) => item.index !== prescription.index
                      )
                    );
                  }}
                  style={{
                    width: 40
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-danger rounded-circle"
                    title="Delete Drug Refill History"
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderRxNumber(prescription)}
                  {_renderPrescribedDrug(prescription)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderPrescriber(prescription)}
                  {_renderPrescriberSupervisor(prescription)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderIssueDate(prescription)}
                  {_renderExpirationDate(prescription)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderDispenseAsWrittenType(prescription)}
                  {_renderQtyPrescribed(prescription)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderOriginalRefills(prescription)}
                  {_renderRefillsRemaining(prescription)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderQtyRemaining(prescription)}
                  {_renderUploadImage(prescription)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderNotes(prescription)}
                  {_renderDirections(prescription)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {_renderNeverFilled(prescription)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AllActiveRxForm;
