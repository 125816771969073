import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

const CsCheckpointModal = (props) => {
  const [steps, setSteps] = useState(1);
  const [answer1, setAnswer1] = useState(null);
  const [answer2, setAnswer2] = useState(null);

  const handleProceed = async () => {
    // Pass to parent to save result with the request
    setSteps(1);
    props.onHide(true, { answer1, answer2 });
  };
  return (
    <Modal
      onHide={() => {
        setSteps(1);
        props.onHide(false, { answer1, answer2 });
      }}
      show={props.show}
      aria-labelledby="accept-transfer-request-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="accept-transfer-request-modal-title">
          Controlled Substance Checkpoint
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex pb-2">
          <i className="fa-solid fa-triangle-exclamation text-warning me-3 fs-1"></i>
          <div>
            The prescribed drug is a <b>Controlled Substance</b>, please answer
            the following questions to proceed:
          </div>
        </div>
        <div className={`row p-3${steps !== 1 ? " d-none" : ""}`}>
          <label className="fw-bold">
            Has this prescription been filled before?
          </label>
          <div className="form-check ms-3">
            <input
              type="radio"
              className="form-check-input"
              id="a21"
              name="q2"
              value="yes"
              onClick={() => setAnswer1(true)}
            />
            <label className="form-check-label" htmlFor="a21">
              Yes
            </label>
          </div>
          <div className="form-check ms-3">
            <input
              type="radio"
              className="form-check-input"
              id="a22"
              name="q2"
              value="no"
              onClick={() => setAnswer1(false)}
            />
            <label className="form-check-label" htmlFor="a22">
              No
            </label>
          </div>
        </div>
        <div className={`row p-3${steps !== 2 ? " d-none" : ""}`}>
          <label className="fw-bold">
            According to your State and Federal law is this prescription
            transferable?
          </label>
          <div className="form-check ms-3">
            <input
              type="radio"
              className="form-check-input"
              id="a13"
              name="q3"
              value="yes"
              onClick={() => setAnswer2(true)}
            />
            <label className="form-check-label" htmlFor="a13">
              Yes
            </label>
          </div>
          <div className="form-check ms-3">
            <input
              type="radio"
              className="form-check-input"
              id="a23"
              name="q3"
              value="no"
              onClick={() => setAnswer2(false)}
            />
            <label className="form-check-label" htmlFor="a23">
              No
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <button
          type="button"
          className={`btn btn-info text-light${steps === 1 ? " d-none" : ""}`}
          onClick={() => {
            setSteps(steps - 1);
          }}
        >
          <i className="fa-solid fa-chevron-left me-2"></i>Back
        </button>
        <button
          type="button"
          disabled={answer1 === null}
          className={`btn btn-info text-light${steps === 2 ? " d-none" : ""}`}
          onClick={() => {
            if (!answer1) {
              Swal.fire({
                title: "",
                text: "According to the law; this prescription is not transferable since it has never been filled before!",
                icon: "error",
              }).then((result) => {
                setSteps(1);
                props.onHide(false, { answer1, answer2 });
              });
            } else {
              setSteps(steps + 1);
            }
          }}
        >
          Next<i className="fa-solid fa-chevron-right ms-2"></i>
        </button>
        <div>
          <button
            type="button"
            disabled={answer2 === null}
            className={`btn btn-danger me-2${steps === 1 ? " d-none" : ""}`}
            onClick={() => {
              if (!answer2) {
                Swal.fire({
                  title: "",
                  text: "According to the law; this prescription is not transferable in your State!",
                  icon: "error",
                }).then((result) => {
                  setSteps(1);
                  props.onHide(false, { answer1, answer2 });
                });
              } else {
                handleProceed();
              }
            }}
          >
            Proceed
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setSteps(1);
              props.onHide(false, { answer1, answer2 });
            }}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CsCheckpointModal;
