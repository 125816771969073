import React, { useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import Modal from "react-bootstrap/Modal";
import { debounce } from "lodash";

import PharmacyBranchService from "../../services/pharmacy-branch.service";
import SearchService from "../../services/search.service";

const EditPharmacyBranchModal = (props) => {
  const fieldsSchema = {
    name: { isRequired: true },
    ncpdp: { isRequired: true },
    deaNumber: { isRequired: true },
    storeNumber: { isRequired: false },
    emailAddress: { isRequired: false },
    mobile: { isRequired: false },
    phone: { isRequired: false },
    fax: { isRequired: true },
    address: { isRequired: false, cssClass: " w-100" },
    city: { isRequired: false },
  };

  const [showMoreFields, setShowMoreFields] = useState(false);

  const loadOptions = async (category, inputValue, callback) => {
    const data = await SearchService.Search(category, inputValue);
    callback(data?.data);
  };
  const formatOptionLabel = ({ Name, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return Name;
    }
    const highlighted = Name?.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  const toggleShowMoreFields = () => {
    setShowMoreFields(!showMoreFields);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.record?.Id || "",
      name: props.record?.Name || "",
      deaNumber: props.record?.DEANumber || "",
      ncpdp: props.record?.NCPDP || "",
      storeNumber: props.record?.StoreNumber || "",
      emailAddress: props.record?.EmailAddress || "",
      mobile: props.record?.Mobile || "",
      phone: props.record?.Phone || "",
      fax: props.record?.Fax || "",
      city: props.record
        ? { id: props.record?.CityId, label: props.record?.CityName }
        : null,
      address: props.record?.Address || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Field is required"),
      deaNumber: Yup.string().required("Field is required"),
      ncpdp: Yup.string().required("Field is required"),
      emailAddress: Yup.string().email("Invalid email address"),
      fax: Yup.string().required("Field is required"),
    }),
    onSubmit: async (values) => {
      console.log("values.city", values.city);
      const newValues = {
        id: values.id,
        name: values.name,
        deaNumber: values.deaNumber,
        ncpdp: values.ncpdp,
        storeNumber: values.storeNumber,
        emailAddress: values.emailAddress,
        mobile: values.mobile,
        phone: values.phone,
        fax: values.fax,
        cityId: values.city?.Id,
        address: values.address,
      };
      const result = await (!newValues.id
        ? PharmacyBranchService.Create
        : PharmacyBranchService.Update)(newValues);
      if (result.error === null) {
        props.onHide(result?.data);
      }
    },
  });

  const getCustomClasses = (fieldName) => {
    return !showMoreFields && !fieldsSchema[fieldName].isRequired
      ? "d-none"
      : `form-group ${!!formik.errors[fieldName] ? " invalid" : ""}${
          fieldsSchema[fieldName].cssClass
            ? fieldsSchema[fieldName].cssClass
            : ""
        }${!showMoreFields ? " w-100" : ""}`;
  };

  const handleOnShowModal = () => {
    setShowMoreFields(props?.record?.Id !== undefined);
  };

  return (
    <Modal
      onHide={() => {
        props.onHide();
        formik.resetForm();
      }}
      show={props.show}
      aria-labelledby="pharmacy-edit-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName={!showMoreFields ? "modal-lg" : "modal-xxl"}
      onShow={handleOnShowModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="pharmacy-edit-modal-title">
          {(props.record && `Edit Pharmacy #${props.record.Id}`) ||
            "Add New Pharmacy"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className={getCustomClasses("name")}>
              <label htmlFor="name-input" className="form-label">
                Name
              </label>
              {fieldsSchema["name"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="first-name-input"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.name && formik.errors.name && (
                <small className="text-danger">{formik.errors.name}</small>
              )}
            </div>

            <div className={getCustomClasses("deaNumber")}>
              <label htmlFor="deaNumber-input" className="form-label">
                DEA Number
              </label>
              {fieldsSchema["deaNumber"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="deaNumber-input"
                name="deaNumber"
                value={formik.values.deaNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.deaNumber && formik.errors.deaNumber && (
                <small className="text-danger">{formik.errors.deaNumber}</small>
              )}
            </div>

            <div className={getCustomClasses("ncpdp")}>
              <label htmlFor="ncpdp-input" className="form-label">
                NCPDP
              </label>
              {fieldsSchema["ncpdp"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="ncpdp-input"
                name="ncpdp"
                value={formik.values.ncpdp}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.ncpdp && formik.errors.ncpdp && (
                <small className="text-danger">{formik.errors.ncpdp}</small>
              )}
            </div>

            <div className={getCustomClasses("storeNumber")}>
              <label htmlFor="store-number-input" className="form-label">
                Store Number
              </label>
              {fieldsSchema["storeNumber"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="last-name-input"
                name="storeNumber"
                value={formik.values.storeNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.storeNumber && formik.errors.storeNumber && (
                <small className="text-danger">
                  {formik.errors.storeNumber}
                </small>
              )}
            </div>

            <div className={getCustomClasses("emailAddress")}>
              <label htmlFor="email-address-input" className="form-label">
                Email Address
              </label>
              {fieldsSchema["emailAddress"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="email-address-input"
                name="emailAddress"
                value={formik.values.emailAddress}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                className="form-control"
              />
              {formik.touched.emailAddress && formik.errors.emailAddress && (
                <small className="text-danger">
                  {formik.errors.emailAddress}
                </small>
              )}
            </div>

            <div className={getCustomClasses("mobile")}>
              <label htmlFor="mobile-number-input" className="form-label">
                Mobile Number
              </label>
              {fieldsSchema["mobile"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="mobile-number-input"
                name="mobile"
                value={formik.values.mobile}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="tel"
                className="form-control"
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <small className="text-danger">{formik.errors.mobile}</small>
              )}
            </div>

            <div className={getCustomClasses("phone")}>
              <label htmlFor="phone-number-input" className="form-label">
                Phone Number
              </label>
              {fieldsSchema["phone"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="phone-number-input"
                name="phone"
                value={formik.values.phone}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="tel"
                className="form-control"
              />
              {formik.touched.phone && formik.errors.phone && (
                <small className="text-danger">{formik.errors.phone}</small>
              )}
            </div>

            <div className={getCustomClasses("fax")}>
              <label htmlFor="fax-number-input" className="form-label">
                Fax Number
              </label>
              {fieldsSchema["fax"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="fax-number-input"
                name="fax"
                value={formik.values.fax}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="tel"
                className="form-control"
              />
              {formik.touched.fax && formik.errors.fax && (
                <small className="text-danger">{formik.errors.fax}</small>
              )}
            </div>

            <div className={getCustomClasses("address")}>
              <label htmlFor="address-input" className="form-label">
                Address
              </label>
              {fieldsSchema["address"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <textarea
                id="address-input"
                name="address"
                value={formik.values.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
              ></textarea>
              {formik.touched.address && formik.errors.address && (
                <small className="text-danger">{formik.errors.address}</small>
              )}
            </div>

            <div className={getCustomClasses("city")}>
              <label htmlFor="city-ddl" className="form-label">
                City
              </label>
              {fieldsSchema["city"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <AsyncSelect
                id="city-ddl"
                name="city"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                isClearable={true}
                value={formik.values.city}
                onBlur={formik.handleBlur}
                loadOptions={(inputValue, callback) =>
                  loadOptions("cities", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
                onChange={(item) => formik.setFieldValue("city", item)}
              />
              {formik.touched.city && formik.errors.city && (
                <small className="text-danger">{formik.errors.city}</small>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          onClick={formik.submitForm}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            props.onHide();
            formik.resetForm();
          }}
        >
          Cancel
        </button>

        <button
          className="btn btn-outline-primary rounded-circle btn-show-more"
          type="button"
          title={`Show ${showMoreFields ? "less" : "more"} fields`}
          onClick={() => toggleShowMoreFields()}
        >
          <i
            className={`fa-solid fa-chevron-${showMoreFields ? "up" : "down"}`}
          ></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPharmacyBranchModal;
