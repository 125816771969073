/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import EditModal from "../EditPrescriberModal";
import ViewModal from "../ViewPrescriberModal";
import PrescriberService from "../../services/prescriber.service";
import helpers from "../../utility/helpers";
import Emitter from "../../utility/emitter";
import SearchService from "../../services/search.service";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "./index.scss";

const PrescriberDataGrid = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const userData = currentUser && currentUser.user;

  const getAll = debounce((category, inputValue) => {
    setSearchText(inputValue);
    SearchService.Search(category, inputValue).then((data) =>
      setRecords(data?.data)
    );
  }, 300);

  const [editMode, setEditMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState({});
  const [record, setRecord] = useState({});
  const [records, setRecords] = useState([]);
  const [editRecordModalShow, setEditRecordModalShow] = useState(false);
  const [viewRecordModalShow, setViewRecordModalShow] = useState(false);

  const getDetailedById = async (id) => {
    const result = await PrescriberService.GetById(id);
    if (result?.error === null) {
      setRecord(result?.data);
    }
  };

  const handleRefresh = (id) => {
    if (id !== undefined) {
      getDetailedById(id);
    }
  };

  useEffect(() => {
    //Emitter.on("update-patient-list", () => {
    getAll("prescribers", searchText);
    //});
    /*Emitter.emit("update-patient-list");
    return () => {
      Emitter.off("update-patient-list");
    };*/
  }, []);

  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => {
              setRecord(null);
              setEditMode(true);
              setEditRecordModalShow(true);
            }}
          >
            <i className="fa fa-plus-circle me-1"></i>Add New
          </button>
          <div className="search-box">
            <i className="fa fa-search"></i>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search…"
                spellCheck="false"
                data-ms-editor="true"
                onChange={(event) => getAll("prescribers", event.target.value)}
              />
            </form>
          </div>
        </div>

        <div className="card-body datagrid p-0">
          <table className="table mb-0" test-id="data-table">
            <thead>
              <tr>
                <th className="text-center">
                  # <i className="fa fa-sort"></i>
                </th>

                <th className="text-center">
                  Name <i className="fa fa-sort"></i>
                </th>
                <th className="text-center">
                  DEA Number <i className="fa-solid fa-sort"></i>
                </th>
                <th className="text-center">
                  NPI <i className="fa fa-sort"></i>
                </th>
                <th className="text-center">
                  Mobile <i className="fa fa-sort"></i>
                </th>
                <th className="text-center">
                  State <i className="fa fa-sort"></i>
                </th>
                <th className="text-center">
                  City <i className="fa fa-sort"></i>
                </th>
                <th className="text-center">
                  Address <i className="fa fa-sort"></i>
                </th>
                <th className="text-center">
                  Supervisor <i className="fa fa-sort"></i>
                </th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((record, index) => (
                <tr
                  key={index}
                  className={index === selectedRowIndex ? "selected" : ""}
                >
                  <td className="text-center">{record.id}</td>
                  <td className="text-left">{record.label}</td>
                  <td className="text-center">{record.deaNumber}</td>
                  <td className="text-center">{record.npi}</td>
                  <td className="text-center">{record.mobile}</td>
                  <td className="text-center">{record.state}</td>
                  <td className="text-center">{record.city}</td>
                  <td className="text-left">{record.address}</td>
                  <td className="text-left">{record.prescriberSupervisor}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <a
                        href="#"
                        title="View"
                        data-toggle="tooltip"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedById(record?.id);
                          setEditMode(false);
                          setViewRecordModalShow(true);
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </a>
                      <a
                        href="#"
                        title="Edit"
                        data-toggle="tooltip"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedById(record?.id);
                          setEditMode(true);
                          setEditRecordModalShow(true);
                        }}
                      >
                        <i className="fa fa-edit"></i>
                      </a>
                      <a
                        href="#"
                        title="Delete"
                        data-toggle="tooltip"
                        onClick={() => {}}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center bg-white">
          <div className="hint-text">
            Showing <b>{records?.length}</b> to <b>{records?.length}</b> of{" "}
            <b>{records?.length}</b> entries
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination mb-0">
              <li className="page-item disabled">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <EditModal
        show={editRecordModalShow}
        onHide={(refresh, recordId) => {
          setEditRecordModalShow(false);
          getAll("prescribers", searchText);
          if (refresh === true) {
            handleRefresh(recordId);
          }
        }}
        record={record}
        handleRefresh={handleRefresh}
      />
      <ViewModal
        show={viewRecordModalShow}
        onHide={(refresh, recordId) => {
          setViewRecordModalShow(false);
          if (refresh === true) {
            handleRefresh(recordId);
          }
        }}
        data={record}
        handleRefresh={handleRefresh}
      />
    </>
  );
};

export default PrescriberDataGrid;
