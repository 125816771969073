import React, { useEffect } from "react";
import UserDataGrid from "../../components/UserDataGrid";


const UserPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Manage Users";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Manage Users";
    }
  }, []);
  return (
      <UserDataGrid />
  );
};

export default UserPage;
