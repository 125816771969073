import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import lookupSlice from "./slices/lookup.slice";
import userSlice from "./slices/user.slice";

const reducers = combineReducers({ lookups: lookupSlice, currentUser: userSlice });

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware:[thunk]
});

export default store;
