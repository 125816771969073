import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import images from "../../assets/images";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes.constants";
import userService from "../../services/user.service";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/slices/user.slice";
import "./index.scss";

const ChoosePharmacyAccountPage = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [pharmacyBranches, setPharmacyBranches] = useState([]);

  const handleLogoutClick = async () => {
    await userService.Logout();
    dispatch(userSlice.actions.logout());
    navigate(ROUTES.LOGIN_ROUTE);
  };

  const getUserPharmacyBranches = async () => {
    const userPharmacyBranches = await userService.GetPharmacyBranches();
    setPharmacyBranches(userPharmacyBranches.data);
  };

  const handleSetBranchClick = async (pharmacyBranchId) => {
    const result = await userService.SetPharmacyBranch(pharmacyBranchId);
    if(result.error === null){
      dispatch(userSlice.actions.login(result.data));
      navigate(ROUTES.DASHBOARD_ROUTE);
    }
  };

  useEffect(() => {
    if (currentUser?.user) {
      getUserPharmacyBranches();
    }
  }, []);

  useEffect(() => {
    document.title = "TransferMyRx::Choose your pharmacy account";
  }, []);

  return !currentUser || !currentUser.user ? (
    <Navigate to={{ pathname: ROUTES.LOGIN_ROUTE }} state={location} />
  ) : (
    <div id="choose-pharmacy" className="auth-form col-12 p-4 p-sm-5 shadow-lg rounded-1 border-primary bg-white animate__animated">
      <div className="text-center mb-3">
        <a href="https://www.TransferMyRx.com">
          <img src={images.Logo} alt="TransferMyRx" className="img-fluid" />
        </a>
      </div>
      <div className="text-center">
        <h2 className="mb-4">
          Welcome back,
          <br />
          {`${currentUser.user.firstname} ${currentUser.user.lastname}`}
        </h2>
      </div>
      <h5 className="text-center mb-4 text-secondary">
        Choose your pharmacy to continue:
      </h5>

      <div className="d-flex mb-4 flex-column pharmacy-list">
        {pharmacyBranches &&
          pharmacyBranches.length !== 0 &&
          pharmacyBranches?.map((pharmacyBranch, index) => (
            <div
              key={index}
              onClick={() => handleSetBranchClick(pharmacyBranch.Id)}
            >
              {/* <img
              src={pharmacy.Logo || images.NoImageAvailable}
              alt={`${pharmacy.Name}'s logo`}
            /> */}
              <h4>{pharmacyBranch.Name}</h4>
              <h6>{`${pharmacyBranch.Address}${
                pharmacyBranch.CityName
                  ? `${pharmacyBranch.Address ? ", " : ""}${pharmacyBranch.CityName}`
                  : ""
              }${
                pharmacyBranch.StateName
                  ? `${pharmacyBranch.Address || pharmacyBranch.CityName ? ", " : ""}${
                      pharmacyBranch.StateName
                    }`
                  : ""
              }`}</h6>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          ))}
      </div>

      <div className="new-account mt-3 text-center">
        <span className="link-default" onClick={handleLogoutClick}>
          Sign Out
        </span>
      </div>
    </div>
  );
};
export default ChoosePharmacyAccountPage;
