/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import http from "../../utility/http";

const PharmacyBranchTransferInReports = ({
  pharmacyBranchId,
  fromDate,
  toDate,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [transferInCount, setTransferInCount] = useState(0);
  const getData = async () => {
    const response = await http.post("/v1/transfer/transfer-in-reports", {
      pharmacyBranchId: pharmacyBranchId,
      fromDate: fromDate,
      toDate: toDate,
    });

    setTransferInCount(response.data.result);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, [pharmacyBranchId, fromDate, toDate]);
  return (
    <>
      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px",
          margin: "10px 0",
        }}
      >
        <h3 style={{ margin: "0", color: "#333", fontSize: 16 }}>
          Transfer In Count
        </h3>
        <p style={{ margin: "0", color: "#666", fontSize: 14 }}>
          {isLoading ? "Loading" : transferInCount}
        </p>
      </div>
    </>
  );
};

export default PharmacyBranchTransferInReports;
