import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import TransferInRequestService from "../../services/transfer-in-request.service";
import TransferOutRequestService from "../../services/transfer-out-request.service";

const RejectTransferRequestModal = (props) => {
  const rejectReasons = useSelector((state) =>
    props.requesttype === 1
      ? state.lookups.rejectOutReasons
      : state.lookups.rejectInReasons
  );
  const [reasonText, setReasonText] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (id) => {
    setSelectedOption(id);
  };

  const handleProceed = async () => {
    const traceId = props.request.trxTraceId
    const reasonId = selectedOption
    let reason = rejectReasons.find(reason => reason.id === reasonId)
    if (reason?.label === "Other:") {
      reason = reasonText
    } else {
      reason = reason.label
    }
    console.log("reason", reason)
    console.log("traceId", traceId)



    if (props.customProcced) {
      if (selectedOption === 7) {
        props.customProcced("Medication is not available");
      } else {
        props.customProcced(reasonText);
      }
      setReasonText("");
      setSelectedOption(null);
    } else {
      const actionParams = {
        transferRequestId: props?.request?.RequestId,
        actionReasonId: selectedOption,
        actionReasonText:
          selectedOption && selectedOption < 0 ? reasonText : null,
        reason,
        traceId
      };
      const result = await (props.requesttype === 1
        ? TransferOutRequestService
        : TransferInRequestService
      ).Reject(actionParams);
      setReasonText("");
      setSelectedOption(null);
      if (!result.error) {
        props.onHide(true);
      }
    }
  };

  return (
    <Modal
      onHide={() => {
        setReasonText("");
        setSelectedOption(null);
        props.onHide();
      }}
      show={props.show}
      aria-labelledby="reject-transfer-request-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="reject-transfer-request-modal-title">
          Reject Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="mb-3">
            Are you sure you want to REJECT request #`
            <b>{props?.request?.RequestId || props?.request?.Id}</b>`?
          </div>
          <div className="row">
            {rejectReasons?.map((rejectReason, index) => (
              <div key={index} className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="action-reason"
                  id={`action-reason-${index}`}
                  value={rejectReason.id}
                  onChange={() => handleOptionChange(rejectReason.id)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`action-reason-${index}`}
                >
                  {rejectReason.label}
                </label>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <textarea
                value={reasonText}
                disabled={selectedOption === null || selectedOption > 0}
                className="form-control"
                title="Other action Reason"
                placeholder=""
                onChange={(event) => setReasonText(event.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-danger"
          disabled={
            (selectedOption === null || selectedOption < 0) && reasonText === ""
          }
          onClick={handleProceed}
        >
          Proceed
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setReasonText("");
            setSelectedOption(null);
            props.onHide();
          }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectTransferRequestModal;
