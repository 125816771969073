import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import UserService from "../../services/user.service";
import SearchService from "../../services/search.service";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/slices/user.slice";
import Emitter from "../../utility/emitter";
import "./index.scss";
import images from "../../assets/images";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes.constants";
const SignUp = () => {
  const [signedUp, setSignedUp] = useState(false);
  const loadOptions = debounce((category, inputValue, callback) => {
    SearchService.Search(category, inputValue).then((data) =>
      callback(data?.data)
    );
  }, 300);

  const formatOptionLabel = ({ label, value }, { inputValue }) => {
    if (!inputValue || value === inputValue) {
      return label;
    }
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  const [queryParameters] = useSearchParams();
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      pharmacyBranch: "",
      licenseNumber: "",
      mobile: "",
      signUpEmailAddress: "",
      signUpPassword: "",
      signUpConfirmPassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Field is required"),
      lastname: Yup.string().required("Field is required"),
      //pharmacyBranchId: Yup.string().required("Field is required"),
      signUpEmailAddress: Yup.string()
        .email("Invalid email address")
        .required("Field is required"),
      mobile: Yup.string().required("Field is required"),
      signUpPassword: Yup.string()
        .required("Field is required")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[{\]};:<>|./?]).{8,}$/,
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one numeric character, and one special character (!@#$%^&*)"
        ),
      signUpConfirmPassword: Yup.string()
        .required("Retype your password")
        .oneOf([Yup.ref("signUpPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const refBy = queryParameters.get("ref");
      const newValues = {
        firstname: values.firstname,
        lastname: values.lastname,
        pharmacyBranchId: values.pharmacyBranch?.id,
        licenseNumber: values.licenseNumber,
        mobile: values.mobile,
        emailAddress: values.signUpEmailAddress,
        password: values.signUpPassword,
        confirmPassword: values.signUpConfirmPassword,
        refelarBy: refBy,
      };

      const result = await UserService.SignUp(newValues);
      if (result.error === null) {
        Emitter.emit("SHOW_NOTIFICATION", {
          message:
            "Your account has been created successfully and pending activation",
        });
        setSignedUp(true);
      } else {
        console.error(result.error);
      }
    },
  });

  return (
    <div
      id="signup-form"
      className="col-12 p-4 p-sm-5 shadow-lg rounded-1 border-primary bg-white animate__animated"
    >
      <div className="text-center mb-3">
        <a href="https://www.TransferMyRx.com">
          <img src={images.Logo} alt="TransferMyRx" className="img-fluid" />
        </a>
      </div>
      <h5 className="text-center mb-4">
        {!signedUp
          ? "Create a new account"
          : "Your account has been created and pending approval"}
      </h5>
      {!signedUp ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6 col-sm-12 mb-3">
                <label htmlFor="first-name-input" className="form-label">
                  First Name
                </label>
                <i className="fa fa-asterisk"></i>
                <input
                  id="first-name-input"
                  name="firstname"
                  value={formik.values.firstname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <small className="text-danger">
                    {formik.errors.firstname}
                  </small>
                )}
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <label htmlFor="last-name-input" className="form-label">
                  Last Name
                </label>
                <i className="fa fa-asterisk"></i>
                <input
                  id="last-name-input"
                  name="lastname"
                  value={formik.values.lastname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                />

                {formik.touched.lastname && formik.errors.lastname && (
                  <small className="text-danger">
                    {formik.errors.lastname}
                  </small>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className={`col-md-6 col-sm-12 mb-3`}>
                <label htmlFor="pharmacy-ddl" className="form-label">
                  Pharmacy
                </label>
                <i className="fa fa-asterisk"></i>
                <AsyncSelect
                  id="pharmacy-ddl"
                  className="me-3 w-100"
                  classNamePrefix="react-select"
                  getOptionValue={(option) => option.id}
                  isClearable={true}
                  value={formik.values.pharmacyBranch}
                  loadOptions={(inputValue, callback) =>
                    loadOptions("pharmacies", inputValue, callback)
                  }
                  formatOptionLabel={formatOptionLabel}
                  onBlur={formik.handleBlur}
                  onChange={(item) =>
                    formik.setFieldValue("pharmacyBranch", item)
                  }
                />
                {/* {formik.touched.pharmacyBranch && formik.errors.pharmacyBranch && (
              <small className="text-danger">
                {formik.errors.pharmacyBranch}
              </small>
            )} */}
              </div>

              <div className="col-md-6 col-sm-12 mb-3">
                <label htmlFor="license-number-input" className="form-label">
                  License Number
                </label>
                <input
                  id="license-number-input"
                  name="licenseNumber"
                  value={formik.values.licenseNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                />
                {formik.touched.licenseNumber &&
                  formik.errors.licenseNumber && (
                    <small className="text-danger">
                      {formik.errors.licenseNumber}
                    </small>
                  )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 col-sm-12">
                <label htmlFor="signup-email-input" className="form-label">
                  Email Address
                </label>
                <i className="fa fa-asterisk"></i>
                <input
                  id="signup-email-input"
                  name="signUpEmailAddress"
                  value={formik.values.signUpEmailAddress}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  className="form-control"
                />
                {formik.touched.signUpEmailAddress &&
                  formik.errors.signUpEmailAddress && (
                    <small className="text-danger">
                      {formik.errors.signUpEmailAddress}
                    </small>
                  )}
              </div>

              <div className="col-md-6 col-sm-12 mb-3">
                <label htmlFor="mobile-input" className="form-label">
                  Mobile
                </label>
                <i className="fa fa-asterisk"></i>
                <input
                  id="mobile-input"
                  name="mobile"
                  value={formik.values.mobile}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <small className="text-danger">{formik.errors.mobile}</small>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 col-sm-12 mb-3">
                <label htmlFor="signup-password-input" className="form-label">
                  Password
                </label>
                <i className="fa fa-asterisk"></i>
                <input
                  id="signup-password-input"
                  name="signUpPassword"
                  value={formik.values.signUpPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  className="form-control"
                />
                {formik.touched.signUpPassword &&
                  formik.errors.signUpPassword && (
                    <small className="text-danger">
                      {formik.errors.signUpPassword}
                    </small>
                  )}
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <label
                  htmlFor="signup-confirm-password-input"
                  className="form-label"
                >
                  Confirm Password
                </label>
                <i className="fa fa-asterisk"></i>
                <input
                  id="signup-confirm-password-input"
                  name="signUpConfirmPassword"
                  value={formik.values.signUpConfirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  className="form-control"
                />
                {formik.touched.signUpConfirmPassword &&
                  formik.errors.signUpConfirmPassword && (
                    <small className="text-danger">
                      {formik.errors.signUpConfirmPassword}
                    </small>
                  )}
              </div>
            </div>
            <div class="agreement-text mb-3">
              By clicking Submit, you have read and agree to the&nbsp;
              <a
                class="link-default"
                href="https://www.transfermyrx.com/#/terms-and-conditions"
                target="_blank"
              >Terms and Conditions</a>&nbsp;and&nbsp;<a
                class="link-default"
                href="https://www.transfermyrx.com/#/privacy-policy"
                target="_blank"
              >Privacy Policy</a>.
            </div>
            <button type="submit" className="w-100 btn btn-default btn-lg">
              Sign Up
            </button>
          </form>
          <div className="new-account mt-3">
            Already have account?&nbsp;
            <Link
              id="back-to-login2"
              className="link-default"
              to={ROUTES.LOGIN_ROUTE}
            >
              Sign In
            </Link>
          </div>
        </>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <Link
            id="back-to-login2"
            className="btn btn-lg btn-default"
            to={ROUTES.LOGIN_ROUTE}
          >
            Log In
          </Link>
        </div>
      )}
    </div>
  );
};

export default SignUp;
