import React from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "./index.scss";

const ViewPharmacyBranchModal = (props) => {
  const getStatusStyle = () => {
    switch (props.record?.StatusId) {
      case 2:
        return "warning";
      default:
        return "";
    }
  };

  const getStatusIcon = () => {
    switch (props.record?.StatusId) {
      case 2:
        return "fa-solid fa-circle-exclamation";
      default:
        return "";
    }
  };

  const getStatusMessage = () => {
    switch (props.record?.StatusId) {
      case 2:
        return <span className="w-100">User is inactive</span>;
      default:
        return null;
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="view-pharmacy-branch-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="view-pharmacy-branch-modal-title" className="ms-3">
          View Pharmacy Details #{props.data?.Id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-3">
              <div className="card-header">General Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Name</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Name || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">DEA Number:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.DEANumber || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">NCPDP:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.NCPDP || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">StoreNumber:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.StoreNumber || "-"}
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Contact Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Email Address:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.EmailAddress || "-"}
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Mobile:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Mobile || "-"}
                    </span>
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Phone:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Phone || "-"}
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Fax:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Fax || "-"}
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Address Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Address:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Address || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">City:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.CityName || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-4 col-sm-12">State:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.StateName || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewPharmacyBranchModal;
