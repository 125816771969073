import React, { useEffect } from "react";
import DataGrid from "../../components/ReferalsDataGrid";


const ReferalsPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Referals";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Referals";
    }
  }, []);
  return (
      <DataGrid />
  );
};

export default ReferalsPage;
