/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import EditModal from "../EditPharmacyBranchModal";
import ViewModal from "../ViewPharmacyBranchModal";
import PharmacyBranchService from "../../services/pharmacy-branch.service";
import helpers from "../../utility/helpers";
import Emitter from "../../utility/emitter";
import Swal from "sweetalert2";
import SearchService from "../../services/search.service";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "./index.scss";
import { Pagination } from "../Pagination";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../constants/routes.constants";
const PharmacyBranchDataGrid = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const userData = currentUser && currentUser.user;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);

  const getAll = debounce((category, inputValue, page, sortBy, sortOrder) => {
    setSearchText(inputValue);
    SearchService.Search(
      category,
      inputValue,
      limit,
      page,
      sortBy,
      sortOrder,
      true
    ).then((data) => {
      setRecords(data?.data);
      setTotal(data?.responseData?.data?.totalPages);
    });
  }, 300);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  useEffect(() => {
    getAll("pharmacies", searchText, page, sortBy, sortOrder, true);
  }, [page]);

  const [editMode, setEditMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState({});
  const [record, setRecord] = useState({});
  const [records, setRecords] = useState([]);
  const [editRecordModalShow, setEditRecordModalShow] = useState(false);
  const [viewRecordModalShow, setViewRecordModalShow] = useState(false);

  const getDetailedById = async (id) => {
    const result = await PharmacyBranchService.GetById(id);
    if (result?.error === null) {
      setRecord(result?.data[0][0]);
    }
  };

  const handleRefresh = (id) => {
    if (id !== undefined) {
      getDetailedById(id);
    }
  };

  const deleteById = async (id) => {
    await PharmacyBranchService.Delete(id);
    await getAll("pharmacies", searchText);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => {
              setRecord(null);
              setEditMode(true);
              setEditRecordModalShow(true);
            }}
          >
            <i className="fa fa-plus-circle me-1"></i>Add New
          </button>
          <div className="search-box">
            <i className="fa fa-search"></i>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search…"
                spellCheck="false"
                data-ms-editor="true"
                onChange={(event) => getAll("pharmacies", event.target.value)}
              />
            </form>
          </div>
        </div>

        <div className="card-body datagrid p-0">
          <table className="table mb-0" test-id="data-table">
            <thead>
              <tr>
                <th
                  className="text-center"
                  // onClick={() => {
                  //   setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  //   getAll(
                  //     "pharmacies",
                  //     "",
                  //     page,
                  //     "id",
                  //     sortOrder === "asc" ? "desc" : "asc"
                  //   );
                  // }}
                  style={{ width: 50 }}
                >
                  {/* # <i className="fa fa-sort"></i> */}#
                </th>

                <th className="text-center">Name</th>
                <th className="text-center">NCPDP</th>
                <th className="text-center">State</th>
                <th className="text-center">City</th>
                <th className="text-center">Address</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((record, index) => (
                <tr
                  key={index}
                  className={index === selectedRowIndex ? "selected" : ""}
                >
                  <td className="text-center">{record.id}</td>
                  <td className="text-left">{record.name}</td>
                  <td className="text-center">{record.ncpdp}</td>
                  <td className="text-center">{record.state}</td>
                  <td className="text-center">{record.city}</td>
                  <td className="text-left">{record.address}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <div
                        title="View"
                        data-toggle="tooltip"
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          navigate(`${ROUTES.PHARMACY_BRANCH_REPORTS_ROUTE}?pharmacyBranchId=${record?.id}&pharmacyBranchName=${record?.name}`);
                        }}
                      >
                        <i className="fa fa-info"></i>
                      </div>
                      <a
                        href="#"
                        title="View"
                        data-toggle="tooltip"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedById(record?.id);
                          setEditMode(false);
                          setViewRecordModalShow(true);
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </a>
                      <a
                        href="#"
                        title="Edit"
                        data-toggle="tooltip"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedById(record?.id);
                          setEditMode(true);
                          setEditRecordModalShow(true);
                        }}
                      >
                        <i className="fa fa-edit"></i>
                      </a>
                      <a
                        href="#"
                        title="Delete"
                        data-toggle="tooltip"
                        onClick={async () => {
                          // await deleteById(record);
                          const result = await Swal.fire({
                            title: "",
                            text: `Are you sure you want to delete this? ?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#6c757d",
                            confirmButtonText: "Yes",
                          });
                          if (result.isConfirmed) {
                            await deleteById(record?.id);
                          }
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination page={page} total={total} limit={limit} setPage={setPage} />
      </div>

      <EditModal
        show={editRecordModalShow}
        onHide={async (refresh, recordId) => {
          setEditRecordModalShow(false);
          await getAll("pharmacies", searchText);
          if (refresh === true) {
            await handleRefresh(recordId);
          }
        }}
        record={record}
        handleRefresh={handleRefresh}
      />
      <ViewModal
        show={viewRecordModalShow}
        onHide={(refresh, recordId) => {
          setViewRecordModalShow(false);
          if (refresh === true) {
            handleRefresh(recordId);
          }
        }}
        data={record}
        handleRefresh={handleRefresh}
      />
    </>
  );
};

export default PharmacyBranchDataGrid;
