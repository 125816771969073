import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";
import MessageAnotherPharmacyModal from "../MessageAnotherPharmacyModal";
import MessageAnotherPharmacyService from "../../services/MessageAnotherPharmacy.service";
import ResponseMesageAnotherPharmacy from "../ResponseMesageAnotherPharmacy";
import Form from 'react-bootstrap/Form';
import "./index.scss";
import Pusher from 'pusher-js';


const MessageAnotherPharmacyDataGrid = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [request, setRequest] = useState({});
  const [messageAnotherPharmacyModalShow, setMessageAnotherPharmacyModalShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState({});
  const [record, setRecord] = useState({});
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [sortOrder, setSortOrder] = useState('desc');
  const [defualtSortBy, setDefualtSortBy] = useState("Id");

  const getAllMessages = async (searchText, sortBy, sortOrder) => {
    let  result
    if (currentUser?.user?.firstname =="Guest"){
      result = await MessageAnotherPharmacyService.GetById(currentUser?.user?.recordId);
      if (result?.error === null) {
        setRecords([result?.data]);
        setTotal(1);
      }
    }else{
      result = await MessageAnotherPharmacyService.GetAll(page, limit, searchText, sortBy, sortOrder);
      if (result?.error === null) {
        setRecords(result?.data.data);
        setTotal(result?.data?.total);
      }
    };
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (selectedrecord) => {
    setModalOpen(true);
    setRecord(selectedrecord);
    makeMessagesIsRead(selectedrecord);
  };

  const closeModal = () => {
    setModalOpen(false);
    getAllMessages("", defualtSortBy, sortOrder);
  };

  const closeMessage = async (id) => {
    const newValues = {
      id: id,
    };
    const result = await (//!values.id ?
      MessageAnotherPharmacyService.CloseMessage)(newValues);
    if (result.error === null) {
    }
    getAllMessages("", defualtSortBy, sortOrder);
  };

  const makeMessagesIsRead = async (selectedrecord) => {
    const updatedMessages = selectedrecord.messages.map(message => ({
      ...message,
      isRead: message.fromPharmacy !== currentUser?.user?.pharmacyBranchId ? true : message.isRead
    }));
    const newMessages = JSON.stringify(updatedMessages);
    const newValues = {
      id: selectedrecord.Id,
      messages: newMessages,
    };
    const result = await (//!values.id ?
      MessageAnotherPharmacyService.MakeMessagesIsRead)(newValues);
    if (result.error === null) {
    }
  };

  const debouncedSearch = debounce((searchText) => {
    setSearchText(searchText);
    getAllMessages(searchText, defualtSortBy, sortOrder);
  }, 300);


  const sortBy = (sortBy, currentSortOrder) => {
    const newSortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    getAllMessages(searchText, sortBy, currentSortOrder);
  };
  useEffect(() => {
    getAllMessages("", defualtSortBy, sortOrder);
  }, [page]);

  useEffect(() => {
    let pusher;
    let channel;
    console.log(currentUser);
    if (currentUser?.user?.pharmacyId) {
      pusher = new Pusher("bd3ebe27f306e286419c", {
        cluster: 'mt1'
      });

      channel = pusher.subscribe(`pharmacy-${currentUser.user.pharmacyBranchId}`);
      console.log(`pharmacy-${currentUser.user.pharmacyBranchId}`);
      channel.bind('update-message-another-pharmacy-list', function (data) {
        console.log('Received update:', data);
        getAllMessages("", defualtSortBy, sortOrder);
      });
    }

    return () => {
      if (channel) {
        channel.unbind_all();
        channel.unsubscribe();
      }
      if (pusher) {
        pusher.disconnect();
      }
    };
  }, [currentUser?.user?.id]);


  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => {
              setMessageAnotherPharmacyModalShow(true);
            }}
            test-id="send-or-request-transfer"
          >
            <i className="fa-solid fa-message  me-2"></i>
            <span>Message Another Pharmacy</span>
          </button>

          <div className="search-box">
            <i className="fa-solid fa-magnifying-glass"></i>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search…"
                spellCheck="false"
                data-ms-editor="true"
                onChange={(event) => debouncedSearch(event.target.value)}
              />
            </form>
          </div>
        </div>
        <div className="card-body datagrid p-0">
          <table className="table mb-0" test-id="data-table">
            <thead>
              <tr>
                <th className="text-center"
                  onClick={() => sortBy("Id", sortOrder)}
                >
                  # <i className="fa fa-sort"></i>

                </th>
                <th className="text-center small-col " onClick={() => sortBy("IsClosed", sortOrder)} >
                  Messages<i className="fa fa-sort"></i>
                </th>
                <th className="text-center small-col ">in/out</th>
                <th className="text-center" onClick={() => sortBy("FromPharmacyName", sortOrder)}  >
                  FromPharmacy <i className="fa fa-sort"></i>
                </th>
                <th className="text-center" onClick={() => sortBy("ToPharmacyName", sortOrder)} >
                  ToPharmacy <i className="fa fa-sort"></i>
                </th>
                <th className="text-center" onClick={() => sortBy("RequestType", sortOrder)}>
                  RequestType <i className="fa fa-sort"></i>
                </th>
                <th className="text-center" onClick={() => sortBy("CreatedDate", sortOrder)}  >
                  CreatedDate <i className="fa fa-sort"></i>
                </th>
               
              </tr>
            </thead>
            <tbody>
              {records?.map((record, index) => (
                <tr
                  key={record.Id}
                  className={index === selectedRowIndex ? "selected" : ""}
                >
                  <td className="text-center"> {record.Id} </td>
                  <td className="small-col" >
                    <div className="d-flex justify-content-center">
                      <a
                        href="#"
                        title="Add Rsponse"
                        data-toggle="tooltip"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          openModal(record);
                        }}
                        style={{ display: 'inline-block', position: 'relative', textDecoration: 'none' }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <i className={`fa-regular fa-message ${record.IsClosed === 0 ? "text-success" : "text-secondary"}`}
                            style={{ fontSize: '25px' }}></i>
                          <span className="badge bg-success" style={{ fontSize: '10px', padding: '3px', marginTop: '-25px' }}>
                            {record.NumberOfUnreadMessage == 0 ? "" : record.NumberOfUnreadMessage}
                          </span>
                        </div>
                      </a>
                    </div>
                  </td>
                  <td className="text-center small-col">
                    {record.FromPharmacyId ===
                      currentUser.user.pharmacyBranchId ? (
                      <i className="fa fa-arrow-up text-primary">
                        <span style={{ fontSize: "xx-small" }}>out</span>
                      </i>
                    ) : (
                      <i className="fa fa-arrow-down  text-success">
                        <span style={{ fontSize: "xx-small" }}>in</span>
                      </i>
                    )}
                  </td>
                  <td className="text-left large-Col">{record.FromPharmacyName}</td>
                  <td className="text-center large-Col" >{record.ToPharmacyName}</td>
                  <td className="text-center large-Col" >
                    {record.RequestType == 1 && <>Reversed Claim</>}
                    {record.RequestType == 2 && (
                      <>Check if medicine is in stock</>
                    )}
                    {record.RequestType == 3 && <>{record.MessageText}</>}
                  </td>
                  <td className="text-center">
                    {moment(record.CreatedDate).format("L")}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="card-footer d-flex justify-content-between align-items-center bg-white">
          <div className="hint-text">
            Showing <b>{(page - 1) * limit + 1}</b> to{" "}
            <b>{Math.min(page * limit, total)}</b> of <b>{total}</b> entries
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination mb-0">
              <li className="page-item ">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {Array(Math.ceil(total / limit))
                .fill()
                .map((_, i) => (
                  <li  key={i}  className={`page-item ${i + 1 === page ? "active" : ""}`}>
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(i + 1);
                      }}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page < Math.ceil(total / limit)) {
                      setPage(page + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <MessageAnotherPharmacyModal
        show={messageAnotherPharmacyModalShow}
        onHide={(refresh, transferRequestId) => {
          setMessageAnotherPharmacyModalShow(false);
          getAllMessages("", defualtSortBy, sortOrder);
          if (refresh === true) {
          }
        }}
        requesttype={props.requesttype}
        request={request}
      />
      <ResponseMesageAnotherPharmacy
        isOpen={isModalOpen}
        onClose={closeModal}
        record={record}
      />
    </>
  );
};

export default MessageAnotherPharmacyDataGrid;
