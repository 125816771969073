import React from "react";
import "./index.scss";

const StatisticsWidget = (props) => {
  return (
    <div className="statistics-widget">
      <h2>{props.title}</h2>
      <div className="statistics text-success">{props.in}</div>
      <span className="text-success">In</span>
      <div className="splitter"></div>
      <div className="statistics text-danger">{props.out}</div>
      <span className="text-danger">Out</span>
    </div>
  );
};

export default StatisticsWidget;
