/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PharmacyBranchTransferInReports from "./transferInReports";
import PharmacyBranchTransferOutReports from "./transferOutReports";
import moment from "moment";
import PharmacyBranchTransferInAndOutReports from "./totalTransferInAndOutReports";

const PharmacyBranchReports = ({ pharmacyBranchId, pharmacyBranchName }) => {
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  return (
    <>
      <h2>{pharmacyBranchName} Reports:</h2>
      <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>

        <input
          id="date-of-birth-input"
          test-id="date-of-birth-input"

          name="fromDate"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          type="date"
          max={moment().format("yyyy-MM-DD")}
          className="form-control"
          style={{ width: 250 }}
        />
        <input
          id="date-of-birth-input"
          test-id="date-of-birth-input"

          name="toDate"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          type="date"
          max={moment().format("yyyy-MM-DD")}
          className="form-control"
          style={{ width: 250 }}
        />
      </div>
      <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
        <PharmacyBranchTransferInReports pharmacyBranchId={pharmacyBranchId} fromDate={fromDate} toDate={toDate} />
        <PharmacyBranchTransferOutReports pharmacyBranchId={pharmacyBranchId} fromDate={fromDate} toDate={toDate} />
        <PharmacyBranchTransferInAndOutReports pharmacyBranchId={pharmacyBranchId} fromDate={fromDate} toDate={toDate} />
      </div>
    </>
  );
};

export default PharmacyBranchReports;
