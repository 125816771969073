import http from "../utility/http";

const GetTopTransferedInDrugs = async () => {
  return await http.get("/v1/dashboard/top-transfered-in-drugs");
};

const GetTopTransferedOutDrugs = async () => {
  return await http.get("/v1/dashboard/top-transfered-out-drugs");
};

const GetTransferRequestsStatistics = async () => {
  return await http.get("/v1/dashboard/transfer-requests-statistics");
};
const TopPharmaciesTransferOut = async () => {
  return await http.get("/v1/dashboard/top-transfered-out-pharmacies");
};

const TopPharmaciesTransferIn = async () => {
  return await http.get("/v1/dashboard/top-transfered-in-pharmacies");
};

const api = {
  GetTopTransferedInDrugs,
  GetTopTransferedOutDrugs,
  GetTransferRequestsStatistics,
  TopPharmaciesTransferOut,
  TopPharmaciesTransferIn
};

export default api;
