import React from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "./index.scss";
import PharmacyBranchUserService from "../../services/pharmacy-branch-user.service";
import Swal from "sweetalert2";

const ViewUserModal = (props) => {
  const handleChangeUserStatus = async (id, statusId, pharmacyBranchId) => {
    const result = await Swal.fire({
      title: "",
      text: `Are you sure you want to ${
        statusId === 1 ? "Deactivate" : statusId === 2 ? "Activate" : "Invite"
      } user # ${id} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes",
    });
    if (result.isConfirmed) {
      const result = await (statusId === 2
        ? PharmacyBranchUserService.Activate
        : statusId === 1
        ? PharmacyBranchUserService.Deactivate
        : PharmacyBranchUserService.Invite)(id, pharmacyBranchId);
      if (!result.error) {
        props.handleRefresh(id);
      }
    }
  };

  const getStatusStyle = () => {
    switch (props.data?.StatusId) {
      case 1:
        return "success";
      case 2:
      default:
        return "secondary";
    }
  };

  const getStatusIcon = () => {
    switch (props.data?.StatusId) {
      case 1:
        return "fa-solid fa-circle-check";
      case 2:
      default:
        return "fa-solid fa-circle-exclamation";
    }
  };

  const getStatusMessage = () => {
    switch (props?.data?.StatusId) {
      case 1:
        return <span className="w-100">User is active</span>;
      case 2:
        return <span className="w-100">User is inactive</span>;
      case 3:
        return <span className="w-100">User is not registered</span>;
      case 4:
        return <span className="w-100">User is invited</span>;
      default:
        return null;
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="view-user-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="view-user-modal-title" className="ms-3">
          View User Details #{props.data?.Id}
        </Modal.Title>
      </Modal.Header>
      <div
        className={`alert alert-${getStatusStyle()} d-flex align-items-center`}
        role="alert"
      >
        <i className={`${getStatusIcon()} me-3`}></i>
        {getStatusMessage()}

        <button
          className={`btn btn-${
            props.data?.StatusId === 1
              ? "secondary"
              : props.data?.StatusId === 2
              ? "default"
              : "primary"
          } me-2`}
          onClick={() =>
            handleChangeUserStatus(
              props.data?.Id,
              props.data?.StatusId,
              props.data?.PharmacyBranchId
            )
          }
        >
          {props.data?.StatusId === 1
            ? "Deactivate"
            : props.data?.StatusId === 2
            ? "Activate"
            : "Re-send Invite"}
        </button>
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-3">
              <div className="card-header">General Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">First Name</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.FirstName || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Last Name:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.LastName || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Gender:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.GenderName || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Date Of Birth:</label>
                    <span className="col-md-8 col-sm-12">{"" || "-"}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Contact Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Email Address:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.EmailAddress || "-"}
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Mobile:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Mobile || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Phone:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Phone || "-"}
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Fax:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Fax || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Address Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Address:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Address || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">City:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.CityName || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">State:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.StateName || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Other Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">License #:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.LicenseNumber || "-"}
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Role:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.RoleName || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="view-transfer-request-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName="modal-xxl"
    >
      <Modal.Header closeButton>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-secondary" title="Previous">
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <button type="button" className="btn btn-secondary" title="Next">
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
        <Modal.Title id="view-transfer-request-modal-title" className="ms-3">
          View User Details #{props.data?.UserId}
        </Modal.Title>
      </Modal.Header>
      <div
        className={`alert alert-${getStatusStyle()} d-flex align-items-center`}
        role="alert"
      >
        <i className={`${getStatusIcon()} me-3`}></i>
        {getStatusMessage()}
        {props.data?.StatusId === 1 ? (
          <button
            className="btn btn-secondary"
            onClick={() => props.handleDeactivateUser()}
          >
            Deactivate
          </button>
        ) : null}
        {props.data?.StatusId === 2 ? (
          <button
            className="btn btn-success"
            onClick={() => props.handleActivateUser()}
          >
            Activate
          </button>
        ) : null}
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-3">
              <div className="card-header">Rx Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Rx Number:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.RxNumber || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Dispensed Drug:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.DispensedDrugName || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Dispensed Manufacturer:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.DispensedDrugManufacturerName || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Date Written:</label>
                    <span className="col-md-8 col-sm-12">
                      {moment(props.data?.RxIssueDate)?.format(
                        "MMMM DD YYYY"
                      ) || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Expiration Date:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {moment(props.data?.ExpirationDate)?.format(
                        "MMMM DD YYYY"
                      ) || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Dispense as Written (DAW):
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.DAW === 1 ? (
                        <i className="text-success fa-solid fa-check ms-1"></i>
                      ) : (
                        <i className="text-danger fa-solid fa-xmark ms-1"></i>
                      )}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Prescribed Drug:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.PrescribedDrugName || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Qty Prescribed:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.QtyPrescribed || 0}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Original Refills:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.OriginalRefills || 0}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">
                      Refills Remaining:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.RefillsRemaining || 0}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Qty Remaining:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.QtyRemaining || 0}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-2 col-sm-12">Directions:</label>
                    <span className="col-md-10 col-sm-12">
                      {props.data?.Directions || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-2 col-sm-12">Notes:</label>
                    <span className="col-md-10 col-sm-12">
                      {props.data?.Notes || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewUserModal;
