const Pagination = ({ page, total, limit, setPage, maxPage = 0 }) => {
  const totalPages = Math.ceil(total / limit);
  const effectiveMaxPage =
    maxPage > 0 ? Math.min(totalPages, maxPage) : totalPages;

  if (totalPages > 1) {
    return (
      <div className="card-footer d-flex justify-content-between align-items-center bg-white">
        <div className="hint-text">
          Showing <b>{(page - 1) * limit + 1}</b> to{" "}
          <b>{Math.min(page * limit, total)}</b> of <b>{total}</b> entries
        </div>
        <nav aria-label="Page navigation">
          <ul className="pagination mb-0">
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                aria-label="Previous"
                onClick={(e) => {
                  e.preventDefault();
                  if (page > 1) {
                    setPage(page - 1);
                  }
                }}
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {Array(effectiveMaxPage)
              .fill()
              .map((_, i) => i + 1)
              .filter(
                (p) =>
                  p === 1 ||
                  p === effectiveMaxPage ||
                  (p >= page - 1 && p <= page + 1)
              )
              .map((p) => {
                return (
                  <li
                    className={`page-item ${p === page ? "active" : ""}`}
                    key={p}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(p);
                      }}
                    >
                      {p}
                    </a>
                  </li>
                );
              })}
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                aria-label="Next"
                onClick={(e) => {
                  e.preventDefault();
                  if (page < effectiveMaxPage) {
                    setPage(page + 1);
                  }
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  } else {
    return null;
  }
};
export { Pagination };
