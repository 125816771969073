import React, { useEffect, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { Link } from "react-router-dom";
import DashboardService from "../../services/dashboard.service";

import "./index.scss";

const TopPharmaciesTransferOutDataGrid = (props) => {
  const [radioValue, setRadioValue] = useState("1");
  const radios = [
    { name: "Monthly", value: "1" },
    { name: "Weekly", value: "2" },
    { name: "Today", value: "3" },
  ];

  const [records, setRecords] = useState([]);
  const TopPharmaciesTransferOut = async () => {
    const result = await DashboardService.TopPharmaciesTransferOut()
    if (result.error === null) {
      setRecords(result.data);
    }
  };

  useEffect(() => {
    TopPharmaciesTransferOut();
  }, []);

  return (
    <div className="card h-100">
      <div className="card-header d-flex justify-content-between align-items-center bg-white">
        <h3 className="fs-5">
          Top 10 Transfered Out Pharmacies
        </h3>
      </div>

      <div className="card-body datagrid p-0">
        <table className="table mb-0" test-id="data-table">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">Pharmacy Name</th>
              <th className="text-center">Total Transfers</th>
            </tr>
          </thead>
          <tbody>
            {records?.map((record, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{record.PharmacyBranchName}</td>
                <td className="text-center">{record.TotalTransfers}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopPharmaciesTransferOutDataGrid;
