import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "users",
  initialState: {},
  reducers: {
    login: (state, { payload }) => {
      return payload;
    },
    logout: (state, { payload }) => {
      return null;
    },
  },
});

export default userSlice.reducer;
