import http from "../utility/http";

const Create = async (pharmacyBranch) => {
  return await http.post("/v1/pharmacy-branch", pharmacyBranch);
};
const Update = async (pharmacyBranch) => {
  return await http.put(
    `/v1/pharmacy-branch/${pharmacyBranch.id}`,
    pharmacyBranch
  );
};
const Delete = async (id) => {
  return await http.delete(`/v1/pharmacy-branch/${id}`, {});
};
const GetAll = async () => {
  //add pagination + sorting + filteration
  return await http.get("/v1/pharmacy-branch/list");
};

const GetById = async (id) => {
  return await http.get(`/v1/pharmacy-branch/${id}`);
};

const GetByPharmacyId = async (pharmacyId) => {
  return await http.get(`/v1/pharmacy-branch/${pharmacyId}`);
};

const api = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  GetByPharmacyId,
};

export default api;
