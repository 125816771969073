import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import images from "../../assets/images";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes.constants";
import UserService from "../../services/user.service";
import Emitter from "../../utility/emitter";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/slices/user.slice";

const AcceptInvite = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Reset Password";
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const changePasswordForm = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Field is required")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[{\]};:<>|./?]).{8,}$/,
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one numeric character, and one special character (!@#$%^&*)"
        ),
      confirmPassword: Yup.string()
        .required("Retype your password")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const token = queryParameters.get("token");
      const action = queryParameters.get("action");
      const newValues = {
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        token: token,
        action
      };
      if(action=="accept-invite"){

      }
      const result = await UserService.ChangePassword(newValues);
      if (result.error === null) {
        Emitter.emit("SHOW_NOTIFICATION", {
          message: "Your password has been changed successfully!",
        });
        dispatch(userSlice.actions.login(result.data));
        navigate(ROUTES.DASHBOARD_ROUTE);
      } else {
        navigate(ROUTES.NOT_FOUND_ROUTE);
      }
    },
  });

  return (
    <div
      id="reset-password-form"
      className="auth-form p-4 p-sm-5 shadow-lg rounded-1 border-primary bg-white animate__animated"
    >
      <div className="text-center mb-3">
        <a href="https://www.TransferMyRx.com">
          <img src={images.Logo} alt="TransferMyRx" className="img-fluid" />
        </a>
      </div>
      <h5 className="text-center mb-4">Reset Password</h5>
      <form onSubmit={changePasswordForm.handleSubmit}>
        <div className="col-12 mb-3">
          <label htmlFor="new-password-input" className="form-label">
            New Password
          </label>
          {changePasswordForm.touched.newPassword &&
            changePasswordForm.errors.newPassword && (
              <small className="text-danger">
                {changePasswordForm.errors.newPassword}
              </small>
            )}
          <input
            id="new-password-input"
            name="newPassword"
            value={changePasswordForm.values.newPassword}
            onBlur={changePasswordForm.handleBlur}
            onChange={changePasswordForm.handleChange}
            type="password"
            placeholder="New Password"
            className="form-control"
          />
        </div>

        <div className="form-floating mb-3">
          <label htmlFor="confirm-password-input" className="form-label">
            Confirm Password
          </label>
          {changePasswordForm.touched.confirmPassword &&
            changePasswordForm.errors.confirmPassword && (
              <small className="text-danger">
                {changePasswordForm.errors.confirmPassword}
              </small>
            )}
          <input
            id="confirm-password-input"
            name="confirmPassword"
            value={changePasswordForm.values.confirmPassword}
            onBlur={changePasswordForm.handleBlur}
            onChange={changePasswordForm.handleChange}
            type="password"
            placeholder="Confirm Password"
            className="form-control"
          />
        </div>

        <div className="d-grid">
          <button type="submit" className="btn btn-default btn-lg">
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
};
export default AcceptInvite;
