import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Emitter from "./utility/emitter";
import Pages from "./pages/index";

import { ROUTES } from "./constants/routes.constants";
import Swal from "sweetalert2";
import Layouts from "./layouts";

import "./assets/styles/custom.bootstrap.scss";
import "../node_modules/bootstrap-print-css/css/bootstrap-print.css";
import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

import "animate.css";
import "./assets/styles/custom.sweetalert2.scss";
import "./assets/styles/main.scss";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  showCloseButton: true,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const App = () => {
  const showNotification = (payload) => {
    Toast.fire({
      icon: payload.isError ? "error" : "success",
      title: payload.message,
    });
  };
  useEffect(() => {
    Emitter.on("SHOW_NOTIFICATION", showNotification);
    return () => {
      Emitter.off("SHOW_NOTIFICATION", showNotification);
    };
  }, []);
  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route element={<Layouts.PublicLayout />}>
            <Route path={ROUTES.LOGIN_ROUTE} element={<Pages.Login />} />
            <Route path={ROUTES.SIGNUP_ROUTE} element={<Pages.SignUp />} />
            <Route
              path={ROUTES.RESETPASSWORD_ROUTE}
              element={<Pages.ResetPassword />}
            />
            <Route
              path={ROUTES.ACCEPTINVITE_ROUTE}
              element={<Pages.AcceptInvite />}
            />
            <Route
              path={ROUTES.LOGIN_WITH_CODE_ROUTE}
              element={<Pages.LoginWithCode />}
            />
            <Route
              path={ROUTES.FORGOTPASSWORD_ROUTE}
              element={<Pages.ForgotPassword />}
            />
            <Route
              path={ROUTES.LOGIN_WITH_TOKEN_ROUTE}
              element={<Pages.LoginWithToken />}
            />
            <Route
              path={ROUTES.CHOOSE_PHARMACY_ACCOUNT_ROUTE}
              element={<Pages.ChoosePharmacyAccount />}
            />
            <Route path={ROUTES.ROOT_ROUTE} element={<Pages.Root />} />
            <Route path="*" element={<Pages.NotFound />} />
          </Route>
          <Route element={<Layouts.ProtectedLayout />}>
            <Route
              path={ROUTES.DASHBOARD_ROUTE}
              element={<Pages.Dashboard />}
            />
            <Route
              path={ROUTES.PRODUCT_LOCATOR_ROUTE}
              element={<Pages.ProductLocatorPage />}
            />
            <Route
              path={ROUTES.PHARMACY_BRANCH_REPORTS_ROUTE}
              element={<Pages.PharmacyBranchReportsPage />}
            />
            <Route
              path={ROUTES.DRUG_REPORTS_ROUTE}
              element={<Pages.DrugReportsReportsPage />}
            />
            <Route
              path={ROUTES.REPORT_ROUTE}
              element={<Pages.ReportsPage />}
            />

            <Route
              path={ROUTES.PHARMACY_ROUTE}
              element={<Pages.PharmacyBranch />}
            />

            <Route
              path={ROUTES.REFERAL_ROUTE}
              element={<Pages.Referals />}
            />

            <Route path={ROUTES.PATIENT_ROUTE} element={<Pages.Patient />} />
            <Route
              path={ROUTES.PRESCRIBER_ROUTE}
              element={<Pages.Prescriber />}
            />
            <Route path={ROUTES.DRUG_ROUTE} element={<Pages.Drug />} />

            <Route
              path={ROUTES.USER_PROFILE_ROUTE}
              element={<Pages.UserProfile />}
            />
            <Route
              path={ROUTES.TRANSFER_IN_REQUEST_ROUTE}
              element={<Pages.TransferInRequest />}
            />
            <Route
              path={ROUTES.TRANSFER_OUT_REQUEST_ROUTE}
              element={<Pages.TransferOutRequest />}
            />
            <Route
              path={ROUTES.PATIENTS_REQUEST_ROUTE}
              element={<Pages.PatientsRequestPage />}
            />
             <Route path={ROUTES.MESSAGE_ANOTHER_PHARMACY_ROUTE}
           element={<Pages.MessageAnotherPharmacy />}
           />
            <Route path={ROUTES.USER_ROUTE} element={<Pages.User />} />
          </Route>
        </Routes>
      </Router>
    </RecoilRoot>
  );
};
export default App;



