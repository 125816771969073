import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes.constants'

const NotFoundPage = () => {
    const navigate = useNavigate()

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.DASHBOARD_ROUTE)
    }

    return (
        <div className="d-flex justify-content-center">
            <h1>Oops 404!</h1>
            Go to
            <span className="btn btn-link" onClick={() => redirectToHomePage()}>
                Home page
            </span>
        </div>
    )
}

export default NotFoundPage
