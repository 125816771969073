import React, { useState } from "react";
import Select, { components, MenuProps } from "react-select";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import Modal from "react-bootstrap/Modal";
import { debounce } from "lodash";

import PrescriberService from "../../services/prescriber.service";
import SearchService from "../../services/search.service";
import helpers from "../../utility/helpers";
const EditPrescriberModal = (props) => {
  const fieldsSchema = {
    firstname: { isRequired: true },
    lastname: { isRequired: true },
    npi: { isRequired: true },
    deaNumber: { isRequired: true },
    emailAddress: { isRequired: false },
    mobile: { isRequired: true },
    address: { isRequired: false, cssClass: " w-100" },
    city: { isRequired: false },
    prescriberSupervisor: { isRequired: false },
  };

  const [showMoreFields, setShowMoreFields] = useState(false);

  const loadOptions = debounce((category, inputValue, callback) => {
    SearchService.Search(category, inputValue).then((data) =>
      callback(data?.data)
    );
  }, 300);

  const formatOptionLabel = ({ label, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  const toggleShowMoreFields = () => {
    setShowMoreFields(!showMoreFields);
  };

  const PrescriberMenu = (props) => {
    return (
      <div className="position-relative">
        <div className="position-absolute container text-center react-select-menu-header">
          <div className="row">
            <div className="col">Name</div>
            <div className="col">NPI</div>
            <div className="col">Address</div>
          </div>
        </div>
        <components.Menu {...props} className="pt-4">
          {props.children}
        </components.Menu>
      </div>
    );
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {props.data.label}
      </components.SingleValue>
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.record?.Id || "",
      firstname: props.record?.FirstName || "",
      lastname: props.record?.LastName || "",
      emailAddress: props.record?.EmailAddress || "",
      mobile: props.record?.Mobile || "",
      phone: props.record?.Phone || "",
      dateOfBirth: props.record?.DateOfBirth,
      avatarImageUrl: props.record?.AvatarImageUrl || "",
      city: props.record
        ? { id: props.record?.CityId, label: props.record?.CityName }
        : null,
      address: props.record?.Address || "",
      npi: props.record?.NPI || "",
      deaNumber: props.record?.DEANumber || "",
      prescriberSupervisor: props.record
        ? {
            id: props.record?.PrescriberSupervisorId,
            label: props.record?.PrescriberSupervisorName,
          }
        : null,
    },
    validationSchema: Yup.object({
      npi: Yup.string().required("Field is required"),
      deaNumber: Yup.string().required("Field is required"),
      firstname: Yup.string().required("Field is required"),
      lastname: Yup.string().required("Field is required"),
      emailAddress: Yup.string().email("Invalid email address"),
      mobile: Yup.string().required("Field is required"),
      phone: Yup.string().phone("US", "Invalid phone number"),
    }),
    onSubmit: async (values) => {
      const newValues = {
        id: values.id,
        firstname: values.firstname,
        middlename: values.middlename,
        lastname: values.lastname,
        emailAddress: values.emailAddress,
        mobile: values.mobile,
        phone: values.phone,
        avatarImageUrl: values.avatarImageUrl,
        cityId: values.city?.id,
        address: values.address,
        npi: values.npi,
        deaNumber: values.deaNumber,
        prescriberSupervisorId: values.prescriberSupervisor?.id,
      };
      const result = await (!newValues.id
        ? PrescriberService.Create
        : PrescriberService.Update)(newValues);
      if (result.error === null) {
        props.onHide(result?.data);
        formik.resetForm();
      }
    },
  });

  const getCustomClasses = (fieldName) => {
    return !showMoreFields && !fieldsSchema[fieldName].isRequired
      ? "d-none"
      : `form-group ${!!formik.errors[fieldName] ? " invalid" : ""}${
          fieldsSchema[fieldName].cssClass
            ? fieldsSchema[fieldName].cssClass
            : ""
        }${!showMoreFields ? " w-100" : ""}`;
  };

  const handleOnShowModal = () => {
    setShowMoreFields(props?.record?.Id !== undefined);
  };

  return (
    <Modal
      onHide={() => {
        props.onHide();
        formik.resetForm();
      }}
      show={props.show}
      aria-labelledby="prescription-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName={!showMoreFields ? "modal-lg" : "modal-xxl"}
      onShow={handleOnShowModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="prescription-modal-title">
          {(props.record && `Edit Prescriber #${props.record.Id}`) ||
            "Add New Prescriber"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className={getCustomClasses("npi")}>
              <label htmlFor="npi-input" className="form-label">
                NPI
              </label>
              {fieldsSchema["npi"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="npi-input"
                name="npi"
                value={formik.values.npi}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.npi && formik.errors.npi && (
                <small className="text-danger">{formik.errors.npi}</small>
              )}
            </div>
            <div className={getCustomClasses("deaNumber")}>
              <label htmlFor="deaNumber-input" className="form-label">
                DEA Number
              </label>
              {fieldsSchema["deaNumber"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="deaNumber-input"
                name="deaNumber"
                value={formik.values.deaNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.deaNumber && formik.errors.deaNumber && (
                <small className="text-danger">{formik.errors.deaNumber}</small>
              )}
            </div>

            <div className={getCustomClasses("firstname")}>
              <label htmlFor="first-name-input" className="form-label">
                First Name
              </label>
              {fieldsSchema["firstname"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="first-name-input"
                name="firstname"
                value={formik.values.firstname}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <small className="text-danger">{formik.errors.firstname}</small>
              )}
            </div>
            <div className={getCustomClasses("lastname")}>
              <label htmlFor="last-name-input" className="form-label">
                Last Name
              </label>
              {fieldsSchema["lastname"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="last-name-input"
                name="lastname"
                value={formik.values.lastname}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <small className="text-danger">{formik.errors.lastname}</small>
              )}
            </div>

            <div className={getCustomClasses("emailAddress")}>
              <label htmlFor="email-address-input" className="form-label">
                Email Address
              </label>
              {fieldsSchema["emailAddress"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="email-address-input"
                name="emailAddress"
                value={formik.values.emailAddress}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                className="form-control"
              />
              {formik.touched.emailAddress && formik.errors.emailAddress && (
                <small className="text-danger">
                  {formik.errors.emailAddress}
                </small>
              )}
            </div>
            <div className={getCustomClasses("mobile")}>
              <label htmlFor="mobile-number-input" className="form-label">
                Mobile Number
              </label>
              {fieldsSchema["mobile"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="mobile-number-input"
                name="mobile"
                value={formik.values.mobile}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="tel"
                className="form-control"
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <small className="text-danger">{formik.errors.mobile}</small>
              )}
            </div>

            <div className={getCustomClasses("address")}>
              <label htmlFor="address-input" className="form-label">
                Address
              </label>
              {fieldsSchema["address"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <textarea
                id="address-input"
                name="address"
                value={formik.values.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
              ></textarea>
              {formik.touched.address && formik.errors.address && (
                <small className="text-danger">{formik.errors.address}</small>
              )}
            </div>

            <div className={getCustomClasses("city")}>
              <label htmlFor="city-ddl" className="form-label">
                City
              </label>
              {fieldsSchema["city"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <AsyncSelect
                id="city-ddl"
                name="city"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                isClearable={true}
                value={formik.values.city}
                onBlur={formik.handleBlur}
                loadOptions={(inputValue, callback) =>
                  loadOptions("cities", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
                onChange={(item) => formik.setFieldValue("city", item)}
              />
              {formik.touched.city && formik.errors.city && (
                <small className="text-danger">{formik.errors.city}</small>
              )}
            </div>

            <div className={getCustomClasses("prescriberSupervisor")}>
              <label htmlFor="prescriber-supervisor-ddl" className="form-label">
                Prescriber Supervisor
              </label>
              {fieldsSchema["prescriberSupervisor"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <div className="d-flex">
                <AsyncSelect
                  id="prescriber-supervisor-ddl"
                  className="me-3 w-100"
                  classNamePrefix="react-select"
                  getOptionValue={(option) => option.id}
                  menuPlacement={"auto"}
                  isClearable={true}
                  value={formik.values.prescriberSupervisor}
                  loadOptions={(inputValue, callback) =>
                    loadOptions("prescribers", inputValue, callback)
                  }
                  components={{ Menu: PrescriberMenu, SingleValue }}
                  formatOptionLabel={(dataRow, { inputValue }) => {
                    return (
                      <div className="row">
                        <div className="col">{dataRow.label}</div>
                        <div className="col">{dataRow.npi}</div>
                        <div className="col">
                          {(dataRow.address &&
                            helpers.getPharmacyFormattedAddress(
                              dataRow.state,
                              dataRow.city,
                              dataRow.address
                            )) ||
                            ""}
                        </div>
                      </div>
                    );
                  }}
                  onChange={(item) => {
                    formik.setFieldValue("prescriberSupervisor", item);
                  }}
                />
              </div>
              {formik.touched.prescriberSupervisor &&
                formik.errors.prescriberSupervisor && (
                  <small className="text-danger">
                    {formik.errors.prescriberSupervisor}
                  </small>
                )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          onClick={formik.submitForm}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            props.onHide();
            formik.resetForm();
          }}
        >
          Cancel
        </button>

        <button
          className="btn btn-outline-primary rounded-circle btn-show-more"
          type="button"
          title={`Show ${showMoreFields ? "less" : "more"} fields`}
          onClick={() => toggleShowMoreFields()}
        >
          <i
            className={`fa-solid fa-chevron-${showMoreFields ? "up" : "down"}`}
          ></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPrescriberModal;
