import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes.constants";
import userService from "../../services/user.service";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/slices/user.slice";

const LoginWithTokenPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const LoginWithToken = async () => {
    const token = queryParameters.get("token");
    const loginResult = await userService.LoginWithCode(undefined, token);
    if (loginResult.error === null) {
      dispatch(userSlice.actions.login(loginResult.data));
      navigate(ROUTES.DASHBOARD_ROUTE);
    } else {
      navigate(ROUTES.NOT_FOUND_ROUTE);
    }
  };

  useEffect(() => {
    LoginWithToken();
  }, []);

  useEffect(() => {
    document.title = "TransferMyRx::Login with token";
  }, []);

  return;
};

export default LoginWithTokenPage;
