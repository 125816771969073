/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import EditUserModal from "../EditUserModal";
import ViewUserModal from "../ViewUserModal";
import PharmacyBranchUserService from "../../services/pharmacy-branch-user.service";

import Emitter from "../../utility/emitter";

// import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "./index.scss";
import { Pagination } from "../Pagination";
import Swal from "sweetalert2";

const UserDataGrid = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const userData = currentUser && currentUser.user;
  const debouncedSearch = debounce((keyword) => {
    setSearchText(keyword);
    getAll(keyword);
  }, 300);

  const [editMode, setEditMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState({});
  const [record, setRecord] = useState({});
  const [records, setRecords] = useState([]);
  const [editRecordModalShow, setEditRecordModalShow] = useState(false);
  const [viewRecordModalShow, setViewRecordModalShow] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("request");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [status, setStatus] = useState(2);
  const getAll = async (keyword, page, limit, sort = null, filter = {}) => {
    const result = await PharmacyBranchUserService.GetAll(
      keyword,
      page,
      limit,
      sort,
      filter
    );
    if (result?.error === null) {
      setRecords(result?.data?.list);
      setTotal(result?.data?.totalRecords);
    }
  };

  useEffect(() => {
    getAll(searchText, page, limit, null, {
      status,
    });
  }, [page, status]);
  const [total, setTotal] = useState(0);
  const getDetailedById = async (id) => {
    const result = await PharmacyBranchUserService.GetById(id);
    if (result?.error === null) {
      setRecord(result?.data);
      Emitter.emit("update-notifications");
    }
  };

  const getStatusIcon = (statusId) => {
    switch (statusId) {
      case 1:
        return "success";
      case 2:
      case 3:
        return "secondary";
      case 4:
        return "primary";
      default:
        return "";
    }
  };

  const handleRefresh = (id) => {
    if (id !== undefined) {
      getDetailedById(id);
    }
  };

  useEffect(() => {
    Emitter.on("update-pharmacy-branch-user-list", () => {
      getAll(searchText, page, limit, null, {
        status,
      });
    });
    Emitter.emit("update-pharmacy-branch-user-list");
    return () => {
      Emitter.off("update-pharmacy-branch-user-list");
    };
  }, []);

  const deleteById = async (id) => {
    await PharmacyBranchUserService.Delete(id);
    getAll(searchText, page, limit, null, {
      status,
    });
  };

  return (
    <>
      <div className="card h-100">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => {
              setRecord(null);
              setEditMode(true);
              setEditRecordModalShow(true);
            }}
          >
            <i className="fa fa-plus-circle me-1"></i>Add New
          </button>
          <div className="select-menu">
            <select
              className="form-select"
              onChange={(event) => {
                setStatus(event.target.value);
                // getAll(searchText, page, 50, null, {
                //   status: event.target.value,
                // });
              }}
            >
              <option value="2">Inactive</option>
              <option value="1">Active</option>
              <option value="3">Not Registered </option>
              <option value="4">Invited</option>
              <option value="0">All</option>
            </select>
          </div>
          <div className="search-box">
            <i className="fa fa-search"></i>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                type="text"
                className="form-control rounded-pill"
                placeholder="Search…"
                spellCheck="false"
                data-ms-editor="true"
                onChange={(event) => debouncedSearch(event.target.value)}
              />
            </form>
          </div>
        </div>

        <div className="card-body datagrid p-0">
          <table className="table mb-0" test-id="data-table">
            <thead>
              <tr>
                <th
                  className="text-center"
                  onClick={() => {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    getAll(
                      searchText,
                      page,
                      limit,
                      {
                        sortOrder: sortOrder === "asc" ? "desc" : "asc",
                        sortBy: "request",
                      },
                      { status }
                    );
                  }}
                >
                  # <i className="fa fa-sort"></i>
                </th>

                <th className="text-center">First Name</th>

                <th className="text-center">Last Name</th>
                <th className="text-center">Email</th>
                {userData?.roleId === 1 && (
                  <th className="text-center">Pharmacy</th>
                )}
                <th
                  className="text-center"
                  onClick={() => {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    getAll(searchText, page, limit, {
                      sortOrder: sortOrder === "asc" ? "desc" : "asc",
                      sortBy: "role",
                    },
                    { status });
                  }}
                >
                  Role <i className="fa fa-sort"></i>
                </th>

                <th
                  className="text-center"
                  onClick={() => {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                    getAll(searchText, page, limit, {
                      sortOrder: sortOrder === "asc" ? "desc" : "asc",
                      sortBy: "status",
                    },
                    { status });
                  }}
                >
                  Status <i className="fa fa-sort"></i>
                </th>

                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((record, index) => (
                <tr
                  key={index}
                  className={
                    index === selectedRowIndex
                      ? "selected"
                      : record?.IsRead === 0
                      ? "new"
                      : ""
                  }
                >
                  <td className="text-center">{record.Id}</td>
                  <td className="text-center">{record.FirstName}</td>
                  <td className="text-center">{record.LastName}</td>
                  <td className="text-center">{record.EmailAddress}</td>
                  {userData?.roleId === 1 && (
                    <td>{record.PharmacyBranchName}</td>
                  )}
                  <td className="text-center">{record.UserRoleName}</td>

                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <i
                        className={`fa fa-circle text-${getStatusIcon(
                          record?.StatusId
                        )} me-1`}
                      ></i>
                      <div>{record?.StatusName || "Inactive"}</div>
                    </div>
                    {/* <Button
                      variant={record?.StatusId === 1 ? "success" : "secondary"}
                      className="w-100"
                      onClick={() => {
                        handleChangeUserStatus(
                          record?.UserId,
                          record?.StatusId
                        );
                      }}
                    >
                      {record?.StatusName}
                    </Button> */}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <a
                        href="#"
                        className={`view${
                          !record?.actions?.view ? " disabled" : ""
                        }`}
                        title="View"
                        data-toggle="tooltip"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedById(record?.Id);
                          setEditMode(false);
                          setViewRecordModalShow(true);
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </a>
                      <a
                        href="#"
                        className={`edit${
                          !record?.actions?.edit ? " disabled" : ""
                        }`}
                        title="Edit"
                        data-toggle="tooltip"
                        onClick={async () => {
                          setSelectedRowIndex(index);
                          await getDetailedById(record?.Id);
                          setEditMode(true);
                          setEditRecordModalShow(true);
                        }}
                      >
                        <i className="fa fa-edit"></i>
                      </a>
                      <a
                        href="#"
                        className={`delete${
                          !record?.actions?.delete ? " disabled" : ""
                        }`}
                        title="Delete"
                        data-toggle="tooltip"
                        onClick={async () => {
                          // await deleteById(record);
                          const result = await Swal.fire({
                            title: "",
                            text: `Are you sure you want to delete this? ?`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#6c757d",
                            confirmButtonText: "Yes",
                          });
                          if (result.isConfirmed) {
                            await deleteById(record?.Id);
                          }
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          total={total}
          limit={limit}
          setPage={setPage}
          maxPage={20}
        />
        {/* <div className="card-footer d-flex justify-content-between align-items-center bg-white">
          <div className="hint-text">
            Showing <b>{(page - 1) * limit + 1}</b> to{" "}
            <b>{Math.min(page * limit, total)}</b> of <b>{total}</b> entries
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination mb-0">
              <li className="page-item ">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {Array(Math.ceil(total / limit))
                .fill()
                .map((_, i) => (
                  <li className={`page-item ${i + 1 === page ? "active" : ""}`}>
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(i + 1);
                      }}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page < Math.ceil(total / limit)) {
                      setPage(page + 1);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>

      <EditUserModal
        show={editRecordModalShow}
        onHide={async (refresh, recordId) => {
          setEditRecordModalShow(false);
          if (refresh === true) {
            handleRefresh(recordId);
          }
          await getAll(searchText, page, limit);
        }}
        record={record}
        handleRefresh={handleRefresh}
      />
      <ViewUserModal
        show={viewRecordModalShow}
        onHide={(refresh, recordId) => {
          setViewRecordModalShow(false);
          if (refresh === true) {
            handleRefresh(recordId);
          }
        }}
        data={record}
        handleRefresh={handleRefresh}
      />
    </>
  );
};

export default UserDataGrid;
