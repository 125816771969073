import React, { Fragment  } from 'react';
import { Modal  } from 'react-bootstrap';
import moment from "moment";
import MessagePharmacyCard from '../MessagePharmacyCard';
import "./index.scss";



const ViewMessageAnotherPharmacyModal = ({ isOpen, onClose, record }) => {

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Message From {record.FromPharmacyName} Pharmacy </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MessagePharmacyCard record={record}/>
     
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  );

};

export default ViewMessageAnotherPharmacyModal;