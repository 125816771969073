import React from "react";
import Modal from "react-bootstrap/Modal";
import TransferOutRequestService from "../../services/transfer-out-request.service";

const FinishReviewTransferRequestModal = (props) => {
  const handleProceed = async () => {
    if (props.handleSubmit) {
      props.handleSubmit(1);
    } else {
      const result = await TransferOutRequestService.FinishReview({
        transferRequestId: props?.request?.RequestId,
        viewMode: true,
      });
      if (!result.error) {
        props.onHide(true);
      }
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="finish-reviewing-transfer-request-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="finish-reviewing-transfer-request-modal-title">
          Finish Reviewing Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to Finish Reviewing request #`
        <b>{props?.request?.RequestId}</b>`?
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          onClick={handleProceed}
        >
          Proceed
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FinishReviewTransferRequestModal;
