const getPharmacyFormattedAddress = (
  address,
  cityName,
  stateName,
  autoSurround
) => {
  const pharmacyFormattedAddress = `${address}${
    cityName ? `${address ? ", " : ""}${cityName}` : ""
  }${stateName ? `${address || cityName ? ", " : ""}${stateName}` : ""}`;
  if (autoSurround && pharmacyFormattedAddress !== "") {
    return `(${pharmacyFormattedAddress})`;
  }else{
    return pharmacyFormattedAddress;
  }
};

export default { getPharmacyFormattedAddress };
