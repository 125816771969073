import React, { useEffect } from "react";
import TransferRequestDataGrid from "../../components/TransferRequestDataGrid";
const TransferOutRequestPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Transfer Out";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Transfer Out";
    }
  }, []);
  return <TransferRequestDataGrid requesttype={1} />;
};

export default TransferOutRequestPage;
