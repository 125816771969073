import React, { useEffect } from "react";
import DataGrid from "../../components/PatientDataGrid";


const PatientPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Patients";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Patients";
    }
  }, []);
  return (
      <DataGrid />
  );
};

export default PatientPage;
