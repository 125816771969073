import React from "react";
import { Outlet } from "react-router-dom";
import Loading from "../../components/Loading";
const PublicLayout = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <Outlet />
        <Loading></Loading>
      </div>
    </div>
  );
};

export default PublicLayout;
