import React, { useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import moment from "moment";
import Swal from "sweetalert2";
import SearchService from "../../services/search.service";
import "./index.scss";

const DrugRefillsHistoryDataGrid = (props) => {
  const [date, setDate] = useState();
  const [drug, setDrug] = useState();
  const [qty, setQty] = useState();

  const handleDeleteRefillHistory = (refillNumber) => {
    const newDrugRefillsHistory = [];
    for (const refillHistory of props.refills) {
      if (refillHistory.refillNumber !== refillNumber) {
        if (refillHistory.refillNumber > refillNumber) {
          refillHistory.refillNumber -= 1;
        }
        newDrugRefillsHistory.push(refillHistory);
      }
    }
    props.setDrugRefillsHistory(newDrugRefillsHistory);
  };
  const handleChangeQtuRefillHistory = (index, qty) => {
    let newDrugRefillsHistory = [...props.refills];
    newDrugRefillsHistory[index] = {
      ...newDrugRefillsHistory[index],
      Qty: qty,
    };

    props.setDrugRefillsHistory(newDrugRefillsHistory);
  };

  const loadOptions = async (category, inputValue, callback) => {
    const data = await SearchService.Search(category, inputValue);
    callback(data?.data);
  };

  const formatOptionLabel = ({ label, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  return (
    <div className="card">
      <div className="card-body datagrid p-0 rounded overflow-visible">
        <table className="table mb-0" test-id="data-table">
          <thead>
            <tr>
              <th className="text-center col-sm-2">Refill Number</th>
              <th className="text-center col-sm-3">Date</th>
              <th className="text-center col-sm-3">Drug Name</th>
              <th className="text-center col-sm-3">Qty</th>
              <th className="text-center col-sm-1">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="col-sm-2 text-center">#</td>
              <td className="col-sm-3">
                <input
                  type="date"
                  min={props.firstFill}
                  max={moment().format("yyyy-MM-DD")}
                  onChange={(event) => setDate(event.target.value)}
                  className="form-control"
                  title="Date"
                />
              </td>
              <td className="col-sm-3 overflow-visible">
                <AsyncCreatableSelect
                  id="prescribed-drug-ddl"
                  className="me-3 w-100"
                  classNamePrefix="react-select"
                  getOptionValue={(option) => option.id}
                  isClearable={true}
                  value={drug}
                  menuPlacement={"auto"}
                  onCreateOption={(inputValue) => {
                    setDrug({ id: null, label: inputValue });
                  }}
                  onChange={(item) => {
                    setDrug(item);
                  }}
                  loadOptions={(inputValue, callback) =>
                    loadOptions("drugs", inputValue, callback)
                  }
                  formatOptionLabel={formatOptionLabel}
                />
              </td>
              <td className="text-center col-sm-3">
                <input
                  type="number"
                  className="text-center form-control"
                  title="Qty"
                  min="1"
                  onChange={(event) => setQty(event.target.value)}
                />
              </td>
              <td className="text-center col-sm-1">
                <button
                  type="button"
                  className="btn btn-outline-primary rounded-circle"
                  title="Add New Refill History"
                  onClick={() => {
                    if (!props.firstFill) {
                      Swal.fire({
                        title: "",
                        text: "`First Fill Date` field is required!",
                        icon: "error",
                      });
                      return;
                    }

                    if (!date) {
                      Swal.fire({
                        title: "",
                        text: "`Fill Date` field is required!",
                        icon: "error",
                      });
                      return;
                    }

                    if (props.firstFill > new Date(date)) {
                      Swal.fire({
                        title: "",
                        text: "`Fill Date` must be greater than or equals `First Fill Date`!",
                        icon: "error",
                      });
                      return;
                    }

                    props.setDrugRefillsHistory(
                      [
                        ...props.refills,
                        {
                          Date: date === "" ? null : date,
                          DrugId: drug.id,
                          DrugName: drug.label,
                          Qty: qty,
                          editable: true,
                        },
                      ]
                        .sort((a, b) => Date.parse(a.Date) - Date.parse(b.Date))
                        ?.map((a, index) => {
                          a.refillNumber = index + 1;
                          return a;
                        })
                    );
                  }}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </td>
            </tr>
            {props.refills?.map((record, index) => (
              <tr key={index}>
                <td className="text-center col-sm-2">{index + 1}</td>
                <td className="text-center col-sm-3">
                  {moment(record.Date).format("MMMM DD YYYY")}
                </td>
                <td className="text-center col-sm-3">{record.DrugName}</td>
                <td className="text-center col-sm-3">
                  <input
                    type="number"
                    className="text-center form-control"
                    title="Qty"
                    min="1"
                    onChange={(event) =>
                      handleChangeQtuRefillHistory(index, event.target.value)
                    }
                    value={record.Qty}
                  />
                </td>
                <td className="text-center col-sm-1">
                  <button
                    type="button"
                    className="btn btn-outline-danger rounded-circle"
                    title="Delete Drug Refill History"
                    onClick={() => handleDeleteRefillHistory(index + 1)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DrugRefillsHistoryDataGrid;
