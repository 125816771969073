import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTES } from "../../constants/routes.constants";

const RootPage = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (!currentUser || !currentUser.user) {
      navigate(ROUTES.LOGIN_ROUTE);
    } else {
      if (currentUser.user.roleId === 7) {
        navigate(ROUTES.TRANSFER_IN_REQUEST_ROUTE);
      } else {
        navigate(ROUTES.DASHBOARD_ROUTE);
      }
    }
  }, []);

  return null;
};

export default RootPage;
