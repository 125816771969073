import React, { Fragment  } from 'react';
import { Modal  } from 'react-bootstrap';
import moment from "moment";
import "./index.scss";
import Card from 'react-bootstrap/Card';

const MessagePharmacyCard = ({  record }) => {

  return (
   
        <Card>
          <Card.Header>
            <div className="card-Text">
            <span className='span-style'>
            <h6>Message From : </h6>  
            </span>
            {record.FromPharmacyName} Pharmacy
            </div>

           <div className="card-Text">
           <span className='span-style'>
           <h6>Message To : </h6> 
           </span>
           {record.ToPharmacyName} Pharmacy  
           
          </div>
          </Card.Header>
          <Card.Body>
            {record.RequestType === "1" ? (
              <Fragment>
                <Card.Title>Reverse Claim</Card.Title>
                <Card.Text className="card-Text">
                  Reverse Claim for Patient:
                  <span className='span-style'>
                    <h6>{record.PatientName}</h6>
                  </span>
                </Card.Text>
                <Card.Text className="card-Text">
                  Prescribed Drug : 
                  <span className='span-style'>
                  <h6>{record.PrescribedDrugName}</h6>
                  </span>
                </Card.Text>
                <Card.Text className="card-Text">
                  CreatedDate :    
                  <span className='span-style'>
                  <h6>{moment(record.CreatedDate).format('YYYY-MM-DD HH:mm:ss')} </h6>
                  </span> 
                </Card.Text>
              </Fragment>
            ) : record.RequestType === "2" ? (
              <Fragment>
                <Card.Title>Check If Medicine In Stock</Card.Title>
                <Card.Text className="card-Text">
                  Prescribed Drug :
                  <span className='span-style'>
                    <h6>{record.PrescribedDrugName}</h6>
                  </span>
                </Card.Text>
                <Card.Text className="card-Text">
                  CreatedDate :
                  <span className='span-style'>
                    <h6> {moment(record.CreatedDate).format("LL")}</h6>
                  </span>
                </Card.Text>
              </Fragment>

            ) : (
              <Fragment>
                <Card.Title>Other</Card.Title>
                <Card.Text className="card-Text">
                  message :
                  <span className='span-style'>
                    <h6>{record.MessageText}</h6>
                  </span>
                </Card.Text>
                <Card.Text className="card-Text">
                  CreatedDate :
                  <span className='span-style'>
                    <h6>{moment(record.CreatedDate).format("LL")}</h6>
                  </span>
                </Card.Text>
              </Fragment>
            )
            }
              <Fragment>
              
                <Card.Text className="card-Text">
                  Last Response :
                  <span className='span-style'>
                    <h6>{record.ResponseMessage}</h6>
                  </span>
                </Card.Text>
              </Fragment>
          </Card.Body>
        </Card>
  );

};

export default MessagePharmacyCard;