import http from "../utility/http";

const GetAll = async (pageNumber) => {
  //add pagination + sorting + filteration
 
  return await http.get(`/v1/notification/list?pageNumber=${pageNumber}`);
};
const MakeNotificationIsRead = async (notfication) => {
  return await http.put(`/v1/notification/make-notification-is-read/${notfication?.id}`,notfication);
};

const api = {
  GetAll,
  MakeNotificationIsRead
};

export default api;
