import React, { useEffect, useState } from 'react'
import './index.scss'
import Emitter from '../../utility/emitter'

const Loading = () => {
    const [loading, setLoading] = useState(false)
    const [animationMode, setAnimationMode] = useState(0)
    const [animating, setAnimating] = useState(false)

    const onAnimationStart = () => {
        setAnimating(true)
    }

    const onAnitionationEnd = () => {
        setAnimating(false)
        if (animationMode === 2) {
            setLoading(false)
        }
    }

    const ajaxSend = () => {
        setLoading(true)
        setAnimationMode(1)
    }

    const ajaxComplete = (payload :any) => {
        setAnimationMode(2)
    }

    useEffect(() => {
        Emitter.on('AJAX_SEND', ajaxSend)
        Emitter.on('AJAX_COMPLETE', ajaxComplete)
        return () => {
            Emitter.off('AJAX_SEND', ajaxSend)
            Emitter.off('AJAX_COMPLETE', ajaxComplete)
        }
    }, [])
    return (
        <div className={loading ? 'card w-100 h-100 d-flex justify-content-center align-items-center position-absolute bg-transparent loading-container' : 'd-none'}>
            <div className="w-100 h-100 position-absolute bg-white opacity-75"></div>
            <div
                onAnimationStart={onAnimationStart}
                onAnimationEnd={onAnitionationEnd}
                className={`d-flex justify-content-flex-start align-items-center position-absolute p-3 border border-dark shadow-lg rounded-1 loading animate__animated animate__faster ${
                    animationMode === 1 ? 'animate__zoomIn' : animationMode === 2 ? 'animate__zoomOut' : ''
                }`}
            >
                <div className="spinner-border text-success me-3"></div>
                <span className="">Processing...</span>
            </div>
        </div>
    )
}

export default Loading
