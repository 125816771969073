/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import http from "../../utility/http";

const DrugTransactionsReports = ({ drugId, fromDate, toDate, pharmacyBranchId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(0)
  const getData = async () => {
    const response = await http.post('/v1/drug/transactions', {
      "drugId": drugId,
      "fromDate": fromDate,
      "toDate": toDate,
      "pharmacyBranchId": pharmacyBranchId
    })
    setData(response.data)
    setIsLoading(false)
  }
  useEffect(() => {
    getData()
  }, [drugId, fromDate, toDate])
  return (
    <>
      {data && (
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: 20 }}>
          <thead>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>From Pharmacy</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>To Pharmacy</th>
              <th style={{ padding: '10px', border: '1px solid #ddd' }}>Total Transfers</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([key, value]) => (
              <tr key={key} style={{ backgroundColor: '#fff' }}>
                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{value.FromPharmacyBranchName}</td>
                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{value.ToPharmacyBranchName}</td>
                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{value.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default DrugTransactionsReports;
