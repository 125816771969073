import React, { useEffect } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserService from "../../services/user.service";
import Emitter from "../../utility/emitter";
import SearchService from "../../services/search.service";

const UserProfile = (props) => {
  const lookups = useSelector((state) => state.lookups);
  const userInfo = useSelector((state) => state.currentUser.user);
  const loadOptions = async (category, inputValue, callback) => {
    const data = await SearchService.Search(category, inputValue);
    callback(data?.data);
  };

  const formatOptionLabel = ({ label, value }, { inputValue }) => {
    if (!inputValue || value === inputValue) {
      return label;
    }
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };
  useEffect(() => {
    document.title = "TransferMyRx::User Profile";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "User Profile";
    }
  }, []);

  const userProfileForm = useFormik({
    initialValues: {
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
      emailAddress: userInfo.email,
      mobile: userInfo.mobile,
      phone: userInfo.phone,
      avatarImageUrl: userInfo.avatarImageUrl,
      city: { value: userInfo.cityId, label: userInfo.cityName },
      address: userInfo.address,
      licenseNumber: userInfo.licenseNumber,
      dateOfBirth: userInfo.dateOfBirth,
    },
    validationSchema: Yup.object({
      // licenseNumber: Yup.string().required("Field is required"),
      firstname: Yup.string().required("Field is required"),
      lastname: Yup.string().required("Field is required"),
      emailAddress: Yup.string().email("Invalid email address"),
      mobile: Yup.string().required("Field is required"),
    }),
    onSubmit: async (values) => {
      const newValues = {
        firstname: values.firstname,
        lastname: values.lastname,
        emailAddress: values.email,
        mobile: values.mobile,
        phone: values.phone,
        avatarImageUrl: values.avatarImageUrl,
        cityId: values.city?.value,
        address: values.address,
        licenseNumber: values.licenseNumber,
        dateOfBirth: values.dateOfBirth,
      };
      const result = await UserService.UpdateProfileDetails(newValues);
      if (result.error === null) {
        Emitter.emit("SHOW_NOTIFICATION", {
          message: "Your profile details have been updated successfully!",
        });
      }
    },
  });

  const changePasswordForm = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Field is required"),
      newPassword: Yup.string()
        .required("Field is required")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[{\]};:<>|./?]).{8,}$/,
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one numeric character, and one special character (!@#$%^&*)"
        ),
      confirmPassword: Yup.string()
        .required("Retype your password")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const result = await UserService.UpdatePassword(values);
      if (result.error === null) {
        Emitter.emit("SHOW_NOTIFICATION", {
          message: "Your password has been updated successfully!",
        });
      }
    },
  });

  return (
    <Tabs defaultActiveKey="profile">
      <Tab eventKey="profile" title="Profile" className="tab">
        <form onSubmit={userProfileForm.handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="first-name-input" className="form-label">
                First Name
              </label>
              <input
                id="first-name-input"
                name="firstname"
                value={userProfileForm.values.firstname}
                onBlur={userProfileForm.handleBlur}
                onChange={userProfileForm.handleChange}
                type="text"
                className="form-control"
              />
              {userProfileForm.touched.firstname &&
                userProfileForm.errors.firstname && (
                  <small className="text-danger">
                    {userProfileForm.errors.firstname}
                  </small>
                )}
            </div>
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="last-name-input" className="form-label">
                Last Name
              </label>
              <input
                id="last-name-input"
                name="lastname"
                value={userProfileForm.values.lastname}
                onBlur={userProfileForm.handleBlur}
                onChange={userProfileForm.handleChange}
                type="text"
                className="form-control"
              />
              {userProfileForm.touched.lastname &&
                userProfileForm.errors.lastname && (
                  <small className="text-danger">
                    {userProfileForm.errors.lastname}
                  </small>
                )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="licenseNumber-input" className="form-label">
                License Number
              </label>
              <input
                id="licenseNumber-input"
                name="licenseNumber"
                value={userProfileForm.values.licenseNumber}
                onBlur={userProfileForm.handleBlur}
                onChange={userProfileForm.handleChange}
                type="text"
                className="form-control"
              />
              {userProfileForm.touched.licenseNumber &&
                userProfileForm.errors.licenseNumber && (
                  <small className="text-danger">
                    {userProfileForm.errors.licenseNumber}
                  </small>
                )}
            </div>
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="date-of-birth-input" className="form-label">
                Date Of Birth
              </label>
              <input
                id="date-of-birth-input"
                name="dateOfBirth"
                value={userProfileForm.values.dateOfBirth}
                onBlur={userProfileForm.handleBlur}
                onChange={userProfileForm.handleChange}
                type="date"
                className="form-control"
              />
              {userProfileForm.touched.dateOfBirth &&
                userProfileForm.errors.dateOfBirth && (
                  <small className="text-danger">
                    {userProfileForm.errors.dateOfBirth}
                  </small>
                )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="email-address-input" className="form-label">
                Email Address
              </label>
              <input
                id="email-address-input"
                name="emailAddress"
                value={userProfileForm.values.emailAddress}
                onBlur={userProfileForm.handleBlur}
                onChange={userProfileForm.handleChange}
                type="email"
                className="form-control"
              />
              {userProfileForm.touched.emailAddress &&
                userProfileForm.errors.emailAddress && (
                  <small className="text-danger">
                    {userProfileForm.errors.emailAddress}
                  </small>
                )}
            </div>
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="mobile-number-input" className="form-label">
                Mobile Number
              </label>
              <input
                id="mobile-number-input"
                name="mobile"
                value={userProfileForm.values.mobile}
                onBlur={userProfileForm.handleBlur}
                onChange={userProfileForm.handleChange}
                type="tel"
                className="form-control"
              />
              {userProfileForm.touched.mobile &&
                userProfileForm.errors.mobile && (
                  <small className="text-danger">
                    {userProfileForm.errors.mobile}
                  </small>
                )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-sm-12">
              <label htmlFor="address-input" className="form-label">
                Address
              </label>
              <textarea
                id="address-input"
                name="address"
                value={userProfileForm.values.address}
                onBlur={userProfileForm.handleBlur}
                onChange={userProfileForm.handleChange}
                className="form-control"
              ></textarea>
              <div className="invalid-feedback"></div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="city-ddl" className="form-label">
                City
              </label>
              <AsyncSelect
                id="city-ddl"
                name="cityId"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                isClearable={true}
                value={userProfileForm.values.city}
                onBlur={userProfileForm.handleBlur}
                loadOptions={(inputValue, callback) =>
                  loadOptions("cities", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
                onChange={(item) => userProfileForm.setFieldValue("city", item)}
              />
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-default"
              onClick={userProfileForm.submitForm}
            >
              Save Changes
            </button>
          </div>
        </form>
      </Tab>
      <Tab eventKey="change-password" title="Change Password" className="tab">
        <form onSubmit={changePasswordForm.handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="old-password-input" className="form-label">
                Old Password
              </label>
              <input
                id="old-password-input"
                name="oldPassword"
                value={changePasswordForm.values.oldPassword}
                onBlur={changePasswordForm.handleBlur}
                onChange={changePasswordForm.handleChange}
                type="password"
                className="form-control"
              />
              {changePasswordForm.touched.oldPassword &&
                changePasswordForm.errors.oldPassword && (
                  <small className="text-danger">
                    {changePasswordForm.errors.oldPassword}
                  </small>
                )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="new-password-input" className="form-label">
                New Password
              </label>
              <input
                id="new-password-input"
                name="newPassword"
                value={changePasswordForm.values.newPassword}
                onBlur={changePasswordForm.handleBlur}
                onChange={changePasswordForm.handleChange}
                type="password"
                className="form-control"
              />
              {changePasswordForm.touched.newPassword &&
                changePasswordForm.errors.newPassword && (
                  <small className="text-danger">
                    {changePasswordForm.errors.newPassword}
                  </small>
                )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6 col-sm-12 mb-sm-3">
              <label htmlFor="confirm-password-input" className="form-label">
                Confirm Password
              </label>
              <input
                id="confirm-password-input"
                name="confirmPassword"
                value={changePasswordForm.values.confirmPassword}
                onBlur={changePasswordForm.handleBlur}
                onChange={changePasswordForm.handleChange}
                type="password"
                className="form-control"
              />
              {changePasswordForm.touched.confirmPassword &&
                changePasswordForm.errors.confirmPassword && (
                  <small className="text-danger">
                    {changePasswordForm.errors.confirmPassword}
                  </small>
                )}
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-default"
              onClick={changePasswordForm.submitForm}
            >
              Update Password
            </button>
          </div>
        </form>
      </Tab>
      <Tab eventKey="contact" title="Contact" disabled></Tab>
    </Tabs>
  );
};

export default UserProfile;
