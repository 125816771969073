import http from "../utility/http";

const Create = async (patient) => {
  return await http.post("/v1/patient", patient);
};
const Update = async (patient) => {
  return await http.put(`/v1/patient/${patient.id}`, patient);
};
const Delete = async (id) => {
  return await http.delete(`/v1/patient/${id}`, {});
};
const GetAll = async () => {
  //add pagination + sorting + filteration
  return await http.get("/v1/patient/list");
};

const GetById = async (id) => {
  return await http.get(`/v1/patient/${id}`);
};

const api = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
};

export default api;
