import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Modal, Button, Form, FormGroup } from 'react-bootstrap';
import MessageAnotherPharmacyService from "../../services/MessageAnotherPharmacy.service";
import moment from "moment";
import "./index.scss";
import { useSelector } from "react-redux";
import MessagePharmacyCard from '../MessagePharmacyCard';

const ResponseMesageAnotherPharmacy = ({ isOpen, onClose, record }) => {
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [selectedCalim_AnswerOther, setSelectedCalim_AnswerOther] = useState('');
  const [showClaimOtherMessage, setShowClaimOtherMessage] = useState(false);
  const [messages, setMessages] = useState([record.messages]);
  const currentUser = useSelector((state) => state.currentUser);
  const messageContainerRef = useRef(null);
  const [toPharmacyHasResponse, setToPharmacyHasResponse] = useState(false);
  const [isCheckedMap, setIsCheckedMap] = useState({});

  const addMessageToJson = () => {
    const dateTime = moment().format();
    const newMessage = {
      "fromPharmacy": currentUser.user.pharmacyBranchId,
      "fromPharmacyName": currentUser.user.pharmacyName,
      "datetime": dateTime,
      "pharmasticName": currentUser.user.firstname + " " + currentUser.user.lastname,
      "message": selectedAnswer,
      "isRead": false
    };

    const updatedMessages = [...(messages || []), newMessage];
    return updatedMessages;
  };

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
    setShowClaimOtherMessage(false);
    setSelectedCalim_AnswerOther('')
  };

  const handleShowClaimOtherMessage = (event) => {
    setShowClaimOtherMessage(true);
    setSelectedAnswer('')
    setSelectedCalim_AnswerOther(event.target.value)
  };

  const handleAnswerChange_cliamOther = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const handleSubmit = async () => {
    const updated_messages_array = addMessageToJson()
    const updatedMessages = updated_messages_array.map(message => ({
      ...message,
      isRead: message.fromPharmacy !== currentUser.user.pharmacyBranchId ? true : message.isRead
    }));
    if (selectedAnswer === "") return
    const newMessages = JSON.stringify(updatedMessages);
    const Created_date = moment().format();
    const newValues = {
      id: record.Id,
      responseMessage: selectedAnswer,
      responseDate: Created_date,
      messages: newMessages,
    };

    const result = await (//!values.id ?
      MessageAnotherPharmacyService.AddResponse)(newValues);
    if (result.error === null) {
    }
    setSelectedAnswer("");
    onClose();
  };

  const checkIfToPharmacyHasResponse = async () => {
    const hasResponse = record.messages?.some(message => message?.fromPharmacy === record?.ToPharmacyId);

    if (hasResponse) {
      setToPharmacyHasResponse(true)
    } else {
      setToPharmacyHasResponse(false)
    }
  }
  const closeMessage = async (id) => {
    const newValues = {
      id: id,
    };
    const result = await (//!values.id ?
      MessageAnotherPharmacyService.CloseMessage)(newValues);
    if (result.error === null) {
    }
  };

  useEffect(() => {
    setMessages(record.messages);
    checkIfToPharmacyHasResponse();
    const timer = setTimeout(() => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [record]);


  return (
    <Modal show={isOpen} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Responses
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>


          <MessagePharmacyCard record={record} />


          <div
            ref={messageContainerRef}
            className='chat-main-div'
          >
            {messages?.map((message, index) => (
              message && (
                <div
                  key={index}
                  className={`chat-div ${currentUser.user.pharmacyBranchId === message.fromPharmacy ? 'reverse' : 'normal'}`}
                >
                  <div className="message-container">
                    <p className="pharmacy-name">{message.fromPharmacyName} - {message.pharmasticName}</p>
                    <p className="message">{message.message}</p>
                    <p className="date">{moment(message.datetime).format('YYYY-MM-DD HH:mm:ss')}</p>
                  </div>
                </div>
              )
            ))}
          </div>

          {record.IsClosed === 0 && (

            <Fragment>
              {toPharmacyHasResponse == false &&
                record.RequestType === "1" ? (
                <Fragment>
                  <h6>Reverse Claim</h6>
                  <FormGroup className="radio-group ">

                    <div key="inline-radio" className="mb-3 displayItmes" >
                      {/* <p><span>Select Answer</span></p> */}
                      <Form.Check
                        inline
                        label="Reversed"
                        type="radio"
                        id="Reversed"
                        value="Reversed"
                        checked={selectedAnswer === 'Reversed'}
                        onChange={handleAnswerChange}

                      />
                      <Form.Check
                        inline
                        label="No Prescription on file"
                        type="radio"
                        id="no-prescr"
                        value="No Prescription on file"
                        checked={selectedAnswer === 'No Prescription on file'}
                        onChange={handleAnswerChange}

                      />
                      <Form.Check
                        inline
                        label="Prescription Picked up"
                        type="radio"
                        id="Prescription Picked up"
                        value="Prescription Picked up"
                        checked={selectedAnswer === 'Prescription Picked up'}
                        onChange={handleAnswerChange}

                      />
                      <Form.Check
                        inline
                        label="other"
                        type="radio"
                        id="other"
                        value="other"
                        checked={selectedCalim_AnswerOther === 'other'}
                        onChange={(event) => {
                          handleShowClaimOtherMessage(event);
                        }}

                      />
                      <div>
                        {showClaimOtherMessage && <Form.Control
                          id="other-input"
                          name="other"
                          as="textarea"
                          value={selectedAnswer}
                          onChange={handleAnswerChange_cliamOther}
                          placeholder="Enter Other"
                          style={{ height: '100px' }}

                        />
                        }
                      </div>
                    </div>
                  </FormGroup>
                </Fragment>
              ) : toPharmacyHasResponse == false && record.RequestType === "2" ? (
                <Fragment>

                  <FormGroup className="radio-group displayItmes">
                    <p>Check If Medicine In Stock</p>
                    <div key="inline-radio" className="mb-3  displayItmes" >

                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        id="Yes"
                        value="Yes"
                        checked={selectedAnswer === 'Yes'}
                        onChange={handleAnswerChange}

                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        id="No"
                        value="No"
                        checked={selectedAnswer === 'No'}
                        onChange={handleAnswerChange}

                      />
                    </div>
                  </FormGroup>
                </Fragment>
              ) :
                <Fragment>
                  <div>
                    <Form.Control
                      id="other-input"
                      name="other"
                      as="textarea"
                      value={selectedAnswer}
                      onChange={handleAnswerChange}
                      placeholder="Enter Other"
                      style={{ height: '100px' }}

                    />


                  </div>
                </Fragment>
              }

            </Fragment>
          )}
          <div>
            {record.IsClosed === 0 ? (

              <Form.Check
                type="switch"
                id={`custom-switch-${record.id}`}
                label="End Conversation"
                defaultChecked={true}
                onChange={() => closeMessage(record.Id)}
                className={isCheckedMap[record.Id] ? "green-switch" : "check-radio-component"}
              />

            ) : (
              <Form.Check
                disabled
                type="switch"
                label="End Conversation"
                className={"check-radio-component"}
              />

            )}
          </div>

        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        {record.IsClosed === 0 && <Button className="btn btn-default" variant="primary" onClick={handleSubmit}>
          Send
        </Button>
        }
      </Modal.Footer>
    </Modal>
    //   </div>
  );

};

export default ResponseMesageAnotherPharmacy;