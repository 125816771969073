import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import images from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes.constants";
import UserService from "../../services/user.service";
import Emitter from "../../utility/emitter";

const ForgotPasswordPage = () => {
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    document.title = "TransferMyRx::Forgot Password";
  }, []);

  const formik = useFormik({
    initialValues: {
      forgotPasswordEmail: "",
    },
    validationSchema: Yup.object({
      forgotPasswordEmail: Yup.string()
        .email("Invalid email address")
        .required("Field is required"),
    }),
    onSubmit: async (values) => {
      const newValues = {
        emailAddress: values.forgotPasswordEmail,
      };
      const result = await UserService.ResetPassword(newValues);
      if (result.error === null) {
        Emitter.emit("SHOW_NOTIFICATION", {
          message: "Your submissions has been received",
        });
        setResetPassword(true);
      } else {
        console.error(result.error);
      }
    },
  });

  return (
    <div
      id="forgot-password-form"
      className="auth-form p-4 p-sm-5 shadow-lg rounded-1 border-primary bg-white animate__animated"
    >
      <div className="text-center mb-3">
        <a href="https://www.TransferMyRx.com">
          <img src={images.Logo} alt="TransferMyRx" className="img-fluid" />
        </a>
      </div>
      <h5 className="text-center mb-4">
        {!resetPassword
          ? "Forgot Password"
          : "Your submissions has been received. If we have an account matching your email address, you will receive an email with a link to reset your password"}
      </h5>
      {!resetPassword ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
              <label className="form-label" htmlFor="forgot-password-email-txt">
                Email
              </label>
              {formik.touched.forgotPasswordEmail &&
                formik.errors.forgotPasswordEmail && (
                  <small className="text-danger">
                    {formik.errors.forgotPasswordEmail}
                  </small>
                )}
              <input
                id="forgot-password-email-txt"
                type="email"
                className="form-control"
                name="forgotPasswordEmail"
                placeholder="Email"
                value={formik.values.forgotPasswordEmail}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </div>
            <button type="submit" className="w-100 btn btn-default btn-lg">
              Send Recovery Email
            </button>
          </form>

          <div className="new-account mt-3">
            Just remembered?&nbsp;
            <Link
              id="back-to-login"
              className="link-default"
              to={ROUTES.LOGIN_ROUTE}
            >
              Sign In
            </Link>
          </div>
        </>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <Link
            id="back-to-login2"
            className="btn btn-lg btn-default"
            to={ROUTES.LOGIN_ROUTE}
          >
            Log In
          </Link>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordPage;
