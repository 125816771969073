import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./index.scss";

const SideMenuItem = (props) => {
  const location = useLocation();
  return (
    <li
      className={`side-menu-item${
        location.pathname === props.route ? " active" : ""
      }`}
    >
      <Link to={props.route}>
        <i className={props.icon}></i>
        <span> {props.text} </span>
        {props.badge !== undefined && props.badge !== 0 ? (
          <span className="badge">{props.badge}</span>
        ) : null}
      </Link>
    </li>
  );
};

export default SideMenuItem;
