import React, { useEffect } from "react";
import ProductLocatorDataGrid from "../../components/ProductLocatorDataGrid";


const ProductLocatorPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Product Locator";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Product Locator";
    }
  }, []);

  return <ProductLocatorDataGrid />;
};

export default ProductLocatorPage;