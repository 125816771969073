import { createSlice } from "@reduxjs/toolkit";

export const lookupSlice = createSlice({
  name: "lookups",
  initialState: {},
  reducers: {
    getAll: (state, { payload }) => {
      return payload;
    },
  },
});

export default lookupSlice.reducer;
