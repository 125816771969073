import React, { useEffect } from "react";
import DrugDataGrid from "../../components/DrugDataGrid";


const DrugPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Drugs";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Drugs";
    }
  }, []);
  return (
      <DrugDataGrid />
  );
};

export default DrugPage;
