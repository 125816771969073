import React from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "./index.scss";

const ViewPrescriberModal = (props) => {
  const getAge = (dateOfBirth) => {
    const duration = moment.duration(moment().diff(moment(dateOfBirth)));
    if (duration._data.years > 0) {
      return `${duration._data.years} Year${
        duration._data.years > 1 ? "s" : ""
      }`;
    } else if (duration._data.months > 0) {
      return `${duration._data.months} Month${
        duration._data.months > 1 ? "s" : ""
      }`;
    } else {
      return `${duration._data.days} Day${duration._data.days > 1 ? "s" : ""}`;
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="view-patient-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="view-patient-modal-title" className="ms-3">
          View Prescriber Details #{props.data?.Id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-3">
              <div className="card-header">General Information</div>
              <div className="card-body">

              <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">DEA Number</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.DEANumber || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">NPI:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.NPI || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">First Name</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.FirstName || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Last Name:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.LastName || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Gender:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.GenderName || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Date Of Birth:</label>
                    <span className="col-md-8 col-sm-12">
                      {`${moment(props.data?.DateOfBirth)?.format(
                        "MMMM DD YYYY"
                      )} (${getAge(props.data?.DateOfBirth)})` || "-"}
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Contact Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Email Address:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.EmailAddress || "-"}
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Mobile:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Mobile || "-"}
                    </span>
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Phone:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Phone || "-"}
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Fax:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Fax || "-"}
                    </span>
                  </div>
                </div>

              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Address Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">Address:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.Address || "-"}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">City:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.CityName || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-4 col-sm-12">State:</label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.StateName || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Other Information</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-4 col-sm-12">
                    Prescriber Supervisor:
                    </label>
                    <span className="col-md-8 col-sm-12">
                      {props.data?.PrescriberSupervisorName || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewPrescriberModal;
