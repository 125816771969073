import React, { useEffect } from "react";
import TransferRequestDataGrid from "../../components/TransferRequestDataGrid";
const TransferInRequestPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Transfer In";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Transfer In";
    }
  }, []);
  return <TransferRequestDataGrid requesttype={0} />;
};

export default TransferInRequestPage;
