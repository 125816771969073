import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ROUTES } from "../../constants/routes.constants";
import userService from "../../services/user.service";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/slices/user.slice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginResult = await userService.Login(username, password);
    if (loginResult.error === null) {
      dispatch(userSlice.actions.login(loginResult.data));
      const userData = loginResult.data?.user;
      if (
        (userData.roleId === 2 ||
          userData.roleId === 3 ||
          userData.roleId === 4) &&
        !userData.pharmacyBranchId
      ) {
        navigate(ROUTES.CHOOSE_PHARMACY_ACCOUNT_ROUTE);
      } else {
        if (userData.roleId === 7) {
          navigate(ROUTES.TRANSFER_IN_REQUEST_ROUTE);
        } else {
          navigate(ROUTES.DASHBOARD_ROUTE);
        }
      }
    } else {
      //Focus on field username
    }
  };

  useEffect(() => {
    document.title = "TransferMyRx::Login";
  }, []);
  return (
    <div
      id="login-form"
      className="auth-form p-4 p-sm-5 shadow-lg rounded-1 border-primary bg-white animate__animated"
    >
      <div className="text-center mb-3">
        <a href="https://www.TransferMyRx.com">
          <img src={images.Logo} alt="TransferMyRx" className="img-fluid" />
        </a>
      </div>
      <h5 className="text-center mb-4">Log in to your account</h5>
      <form onSubmit={(event) => handleSubmit(event)}>
        <div className="col-12 mb-3">
          <label htmlFor="username" className="form-label">
            User Name
          </label>
          <input
            id="username"
            type="text"
            className="form-control"
            placeholder="User Name"
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>

        <div className="col-12 mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="Password"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className="form-row d-flex justify-content-between mt-4 mb-2">
          <div className="mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="remember-me-cb"
              />
              <label className="form-check-label" htmlFor="remember-me-cb">
                Remember me
              </label>
            </div>
          </div>
          <div className="mb-3">
            <Link to={ROUTES.FORGOTPASSWORD_ROUTE} className="link-default">
              Forgot Password?
            </Link>
          </div>
        </div>
        <button type="submit" className="w-100 btn btn-default btn-lg">
          Log In
        </button>
        <div class="agreement-text">
          By clicking Log In, you have read and agree to the&nbsp;
          <a
            class="link-default"
            href="https://www.transfermyrx.com/#/terms-and-conditions"
            target="_blank"
          >
            Terms and Conditions
          </a>
          &nbsp;and&nbsp;
          <a
            class="link-default"
            href="https://www.transfermyrx.com/#/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
          .
        </div>
      </form>
      <div className="mt-3 text-start">
        Do you have a login code?&nbsp;
        <Link className="link-default" to={ROUTES.LOGIN_WITH_CODE_ROUTE}>
          Log In With Code
        </Link>
      </div>

      <div className="mt-3 text-start">
        Don't have an account?&nbsp;
        <Link className="link-default" to={ROUTES.SIGNUP_ROUTE}>
          Sign Up
        </Link>
      </div>
    </div>
  );
};
export default Login;
