import React, { useEffect, useState } from "react";
import PharmacyBranchReports from "../../components/PharmacyBranchReports";


const PharmacyBranchReportsPage = () => {
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  useEffect(() => {
    document.title = "TransferMyRx::Pharmacies";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Pharmacies";
    }
    const urlParams = new URLSearchParams(window.location.search);
    const pharmacyBranchId = urlParams.get('pharmacyBranchId');
    const pharmacyBranchName = urlParams.get('pharmacyBranchName');
    setId(pharmacyBranchId)
    setName(pharmacyBranchName)
  }, []);
  useEffect(() => {
  }, [id])
  return (

    <>
      {id && <PharmacyBranchReports pharmacyBranchId={id} pharmacyBranchName={name} />}
    </>
  );
};

export default PharmacyBranchReportsPage;
