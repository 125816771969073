import http from "../utility/http";

const GetAll = async () => {
  return await http.get("/v1/lookup");
};

const api = {
  GetAll,
};

export default api;
