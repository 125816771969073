import React, { useEffect } from "react";
import DataGrid from "../../components/PharmacyBranchDataGrid";


const PharmacyBranchPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Pharmacies";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Pharmacies";
    }
  }, []);
  return (
      <DataGrid />
  );
};

export default PharmacyBranchPage;
