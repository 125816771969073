import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import moment from "moment";
import { debounce } from "lodash";
import SearchService from "../../services/search.service";

import "./index.scss";
import axios from "axios";
const ProductLocatorModal = (props) => {
  const [formErrors, setFormErrors] = useState({});
  const [showMoreFields, setShowMoreFields] = useState(props.requesttype === 1);

  const [toPharmacy, setToPharmacy] = useState(null);

  const loadOptions = debounce((category, inputValue, callback) => {
    SearchService.Search(category, inputValue).then((data) =>
      callback(data?.data)
    );
  }, 300);

  const formatOptionLabel = ({ label, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    let highlighted;
    if (label.toLowerCase().includes(inputValue.toLowerCase())) {
      highlighted = label
        .toLowerCase()
        .replaceAll(inputValue.toLowerCase(), `<b>${inputValue}</b>`);

      return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
    }
    return label;
  };

  const [isChecked, setIsChecked] = useState(false);
  const [prescribedDrug, setPrescribedDrug] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [result, setResult] = useState(null);
  return (
    <>
      <Modal
        onHide={() => {
          props.onHide();
        }}
        show={props.show}
        aria-labelledby="message-another-pharmacy-modal-title"
        centered
        backdrop="static"
        keyboard={false}
        scrollable
        fullscreen="lg-down"
        dialogClassName={!showMoreFields ? "modal-lg" : "modal-xxl"}
      >
        <Modal.Header closeButton className="">
          <Modal.Title id="message-another-pharmacy-modal-title">
            Product locator query
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="App2"></div>
          <div className="container">
            <div>
              <label htmlFor="pharmacy-ddl" className="form-label">
                Pharmacy
              </label>
              <AsyncSelect
                test-id="pharmacy-ddl"
                id="pharmacy-ddl"
                className="me-3 w-100"
                classNamePrefix="react-select"
                menuPosition="fixed"
                getOptionValue={(option) => option.id}
                isClearable={true}
                value={toPharmacy}
                loadOptions={(inputValue, callback) =>
                  loadOptions("pharmacies", inputValue, callback)
                }
                onChange={(item) => {
                  setToPharmacy(item);
                }}
                formatOptionLabel={formatOptionLabel}
                placeholder="Select Pharmacy"
              />

            </div>
            <div>

              <label htmlFor="drug-ddl" className="form-label">
                Drug
              </label>
              <AsyncCreatableSelect
                onInputChange={(item) => { }}
                isDisabled={isChecked}
                id="drug-ddl"
                test-id="drug-ddl"
                className="me-3 w-100"
                classNamePrefix="react-select"
                menuPosition="fixed"
                getOptionValue={(option) => option.id}
                isClearable={true}
                value={prescribedDrug}
                placeholder="Select Drug"
                onChange={(item) => {
                  setPrescribedDrug(item);
                }}
                loadOptions={(inputValue, callback) =>
                  loadOptions("drugs", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
              />
            </div>

            <div>
              <label htmlFor="quantity-input" className="form-label">
                Quantity
              </label>
              <input type="number" id="quantity-input" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            </div>
            {result?.length > 0 && <div>
              <label htmlFor="result" className="form-label" style={{
                marginTop: "10px",
                color: result === "InStock" ? "green" :
                  result.includes("Insufficient Quantity:") ? "orange" : "red"
              }}>
                Result : {result}
              </label>
            </div>}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            test-id="message-another-pharmacy-submit-btn"
            onClick={async () => {
              console.log("submit");
              console.log(toPharmacy);
              const result = await axios
                .post(`http://localhost:9703/v1/product-locator-query`, {
                  pharmacyNCPDC: toPharmacy.ncpdp,
                  NDCNumber: prescribedDrug.ndcNumber,
                  quantity: quantity,
                }, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
              if (result?.data?.data?.success) {
                const resultData = result?.data?.data?.result;
                if (resultData.quantity) {
                  if (resultData.quantity === "InsufficientQuantity") {
                    setResult(`Insufficient Quantity: ${resultData?.aviableQuantity}`);
                  } else {
                    setResult(resultData.quantity);
                  }
                } else {
                  setResult("Cannot access pharmacy data");
                }
              } else {
                setResult("Cannot access pharmacy data");
              }

            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ProductLocatorModal;
