import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import Modal from "react-bootstrap/Modal";
import UserService from "../../services/user.service";
import { useDispatch } from "react-redux";
import { userSlice } from "../../redux/slices/user.slice";
import Emitter from "../../utility/emitter";
import "./index.scss";

const UpdateGuestInfoModal = (props) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      licenseNumber: "",
      emailAddress: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Field is required"),
      lastname: Yup.string().required("Field is required"),
      licenseNumber: Yup.string().required("Field is required"),
      emailAddress: Yup.string()
        .email("Invalid email address")
        .required("Field is required"),
      password: Yup.string()
        .required("Field is required")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[{\]};:<>|./?]).{8,}$/,
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one numeric character, and one special character (!@#$%^&*)"
        ),
      confirmPassword: Yup.string()
        .required("Retype your password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const result = await UserService.UpdateGuestInfo(values);
      if (result.error === null) {
        dispatch(userSlice.actions.login(result.data));
        props.onHide(result.data);
        Emitter.emit("SHOW_NOTIFICATION", {
          message: "Your account info has been updated successfully!",
        });
      }
    },
  });
  return (
    <Modal
      onHide={() => {
        props.onHide();
        formik.resetForm();
      }}
      show={props.show}
      aria-labelledby="update-guest-info-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="update-guest-info-modal-title">
          Update Your Info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className="row mb-3">
              <div className="col-sm-6 mb-sm-3">
                <label htmlFor="first-name-input" className="form-label">
                  First Name
                </label>
                <input
                  id="first-name-input"
                  name="firstname"
                  value={formik.values.firstname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <small className="text-danger">
                    {formik.errors.firstname}
                  </small>
                )}
              </div>
              <div className="col-sm-6 mb-sm-3">
                <label htmlFor="last-name-input" className="form-label">
                  Last Name
                </label>
                <input
                  id="last-name-input"
                  name="lastname"
                  value={formik.values.lastname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <small className="text-danger">
                    {formik.errors.lastname}
                  </small>
                )}
              </div>
              <div className="col-sm-6 mb-sm-3">
                <label htmlFor="license-number-input" className="form-label">
                  License Number
                </label>
                <input
                  id="license-number-input"
                  name="licenseNumber"
                  value={formik.values.licenseNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                />
                {formik.touched.licenseNumber &&
                  formik.errors.licenseNumber && (
                    <small className="text-danger">
                      {formik.errors.licenseNumber}
                    </small>
                  )}
              </div>
              <div className="col-sm-6 mb">
                <label htmlFor="email-address-input" className="form-label">
                  Email Address
                </label>
                <input
                  id="email-address-input"
                  name="emailAddress"
                  value={formik.values.emailAddress}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  className="form-control"
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <small className="text-danger">
                    {formik.errors.emailAddress}
                  </small>
                )}
              </div>
              <div className="col-sm-6 mb">
                <label htmlFor="password-input" className="form-label">
                  Password
                </label>
                <input
                  id="password-input"
                  name="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  className="form-control"
                />
                {formik.touched.password && formik.errors.password && (
                  <small className="text-danger">
                    {formik.errors.password}
                  </small>
                )}
              </div>
              <div className="col-sm-6 mb">
                <label
                  htmlFor="confirm-password-number-input"
                  className="form-label"
                >
                  Confirm Password
                </label>
                <input
                  id="confirm-password-address-input"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  className="form-control"
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <small className="text-danger">
                      {formik.errors.confirmPassword}
                    </small>
                  )}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button
          id="update-guest-info-btn"
          type="button"
          className="btn btn-default"
          onClick={formik.submitForm}
        >
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateGuestInfoModal;
