import React, { useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useSelector } from "react-redux";

import PharmacyBranchUserService from "../../services/pharmacy-branch-user.service";
import SearchService from "../../services/search.service";
import Swal from "sweetalert2";

const EditUserModal = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const userData = currentUser && currentUser.user;

  const lookups = useSelector((state) => state.lookups);
  const [showMoreFields, setShowMoreFields] = useState(
    props?.record?.Id !== undefined
  );

  const fieldsSchema = {
    firstname: { isRequired: true },
    lastname: { isRequired: true },
    gender: { isRequired: false },
    dateOfBirth: { isRequired: false },
    emailAddress: { isRequired: true },
    mobile: { isRequired: true },
    pharmacyBranch: {
      isRequired: true,
      cssClass: userData.roleId !== 1 ? " d-none" : "",
    },
    licenseNumber: { isRequired: false },
    address: { isRequired: false, cssClass: " w-100" },
    city: { isRequired: false },
    role: { isRequired: true },
  };

  const loadOptions = async (category, inputValue, callback) => {
    const data = await SearchService.Search(category, inputValue);
    callback(data?.data);
  };

  const formatOptionLabel = ({ label, id }, { inputValue }) => {
    if (!inputValue || id === inputValue) {
      return label;
    }
    const highlighted = label.replace(
      new RegExp(inputValue, "gi"),
      (highlighted) => `<b>${highlighted}</b>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  const toggleShowMoreFields = () => {
    setShowMoreFields(!showMoreFields);
  };

  const handleChangeUserStatus = async (id, statusId, pharmacyBranchId) => {
    const result = await Swal.fire({
      title: "",
      text: `Are you sure you want to ${
        statusId === 1 ? "Deactivate" : statusId === 2 ? "Activate" : "Invite"
      } user # ${id} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes",
    });
    if (result.isConfirmed) {
      const result = await (statusId === 2
        ? PharmacyBranchUserService.Activate
        : statusId === 1
        ? PharmacyBranchUserService.Deactivate
        : PharmacyBranchUserService.Invite)(id, pharmacyBranchId);
      if (!result.error) {
        props.handleRefresh(id);
      }
    }
  };

  const getStatusStyle = () => {
    switch (props.record?.StatusId) {
      case 1:
        return "success";
      case 2:
      default:
        return "secondary";
    }
  };

  const getStatusIcon = () => {
    switch (props.record?.StatusId) {
      case 1:
        return "fa-solid fa-circle-check";
      case 2:
      default:
        return "fa-solid fa-circle-exclamation";
    }
  };

  const getStatusMessage = () => {
    switch (props?.record?.StatusId) {
      case 1:
        return <span className="w-100">User is active</span>;
      case 2:
        return <span className="w-100">User is inactive</span>;
      case 3:
        return <span className="w-100">User is invited</span>;
      case 4:
        return <span className="w-100">User is not registered</span>;
      default:
        return null;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.record?.Id,
      firstname: props.record?.FirstName || "",
      lastname: props.record?.LastName || "",
      emailAddress: props.record?.EmailAddress || "",
      pharmacyBranch: {
        label: props.record?.PharmacyBranchName,
        id: props.record?.PharmacyBranchId,
      },
      mobile: props.record?.Mobile || "",
      phone: props.record?.Phone || "",
      gender: {
        label: props.record?.GenderName,
        id: props.record?.GenderId,
      },
      dateOfBirth: props.record?.DateOfBirth || "",
      avatarImageUrl: props.record?.AvatarImageUrl || "",
      city: { label: props.record?.CityName, id: props.record?.CityId },
      address: props.record?.Address || "",
      role: { label: props.record?.RoleName, id: props.record?.RoleId },
      licenseNumber: props.record?.LicenseNumber,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Field is required"),
      lastname: Yup.string().required("Field is required"),
      emailAddress: Yup.string()
        .required("Field is required")
        .email("Invalid email address"),
      mobile: Yup.string().required("Field is required"),
      //pharmacyBranch: { id: Yup.string().required("Field is required") },
    }),
    onSubmit: async (values) => {
      const newValues = {
        id: values.id,
        firstname: values.firstname,
        lastname: values.lastname,
        emailAddress: values.emailAddress,
        mobile: values.mobile,
        phone: values.phone,
        genderId: values.gender?.id,
        pharmacyBranchId: values.pharmacyBranch?.id,
        dateOfBirth: values.dateOfBirth,
        avatarImageUrl: values.avatarImageUrl,
        cityId: values.city?.id,
        address: values.address,
        roleId: values.role?.id,
        licenseNumber: values.licenseNumber,
      };
      const result = await (values.id
        ? PharmacyBranchUserService.Update
        : PharmacyBranchUserService.Create)(newValues);
      if (result.error === null) {
        props.onHide(result?.data);
      }
    },
  });

  const getCustomClasses = (fieldName) => {
    return !showMoreFields && !fieldsSchema[fieldName].isRequired
      ? "d-none"
      : `form-group ${!!formik.errors[fieldName] ? " invalid" : ""}${
          fieldsSchema[fieldName].cssClass
            ? fieldsSchema[fieldName].cssClass
            : ""
        }${!showMoreFields ? " w-100" : ""}`;
  };
  const handleOnShowModal = () => {
    setShowMoreFields(props?.record?.Id !== undefined);
  };
  return (
    <Modal
      onHide={() => {
        props.onHide();
        formik.resetForm();
      }}
      show={props.show}
      aria-labelledby="user-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName={!showMoreFields ? "modal-lg" : "modal-xxl"}
      onShow={handleOnShowModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="transfer-request-modal-title">
          {props.record?.Id !== undefined
            ? `Edit User #${props.record?.Id}`
            : `Add New User`}
        </Modal.Title>
      </Modal.Header>
      {props.record?.Id ? (
        <div
          className={`alert alert-${getStatusStyle()} d-flex align-items-center`}
          role="alert"
        >
          <i className={`${getStatusIcon()} me-3`}></i>
          {getStatusMessage()}
          <button
            className={`btn btn-${
              props.record?.StatusId === 1
                ? "secondary"
                : props.record?.StatusId === 2
                ? "default"
                : "primary"
            } me-2`}
            onClick={() =>
              handleChangeUserStatus(
                props.record?.Id,
                props.record?.StatusId,
                props.record?.PharmacyBranchId
              )
            }
          >
            {props.record?.StatusId === 1
              ? "Deactivate"
              : props.record?.StatusId === 2
              ? "Activate"
              : "Re-send Invite"}
          </button>
        </div>
      ) : null}

      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className={getCustomClasses("firstname")}>
              <label htmlFor="first-name-input" className="form-label">
                First Name
              </label>
              {fieldsSchema["firstname"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="first-name-input"
                name="firstname"
                value={formik.values.firstname}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <small className="text-danger">{formik.errors.firstname}</small>
              )}
            </div>
            <div className={getCustomClasses("lastname")}>
              <label htmlFor="last-name-input" className="form-label">
                Last Name
              </label>
              {fieldsSchema["lastname"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="last-name-input"
                name="lastname"
                value={formik.values.lastname}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <small className="text-danger">{formik.errors.lastname}</small>
              )}
            </div>

            <div className={getCustomClasses("gender")}>
              <label htmlFor="gender-ddl" className="form-label">
                Gender
              </label>
              {fieldsSchema["gender"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <Select
                id="gender-ddl"
                name="gender"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                options={lookups?.genders}
                value={formik.values.gender}
                onBlur={formik.handleBlur}
                onChange={(item) => formik.setFieldValue("gender", item)}
                formatOptionLabel={formatOptionLabel}
              />
              {formik.touched.gender && formik.errors.gender && (
                <small className="text-danger">{formik.errors.gender}</small>
              )}
            </div>
            <div className={getCustomClasses("dateOfBirth")}>
              <label htmlFor="date-of-birth-input" className="form-label">
                Date Of Birth
              </label>
              {fieldsSchema["dateOfBirth"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="date-of-birth-input"
                name="dateOfBirth"
                value={formik.values.dateOfBirth}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="date"
                max={moment().format("yyyy-MM-DD")}
                className="form-control"
              />
              {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                <small className="text-danger">
                  {formik.errors.dateOfBirth}
                </small>
              )}
            </div>

            <div className={getCustomClasses("emailAddress")}>
              <label htmlFor="email-address-input" className="form-label">
                Email Address
              </label>
              {fieldsSchema["emailAddress"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="email-address-input"
                name="emailAddress"
                value={formik.values.emailAddress}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                className="form-control"
              />
              {formik.touched.emailAddress && formik.errors.emailAddress && (
                <small className="text-danger">
                  {formik.errors.emailAddress}
                </small>
              )}
            </div>
            <div className={getCustomClasses("mobile")}>
              <label htmlFor="mobile-number-input" className="form-label">
                Mobile Number
              </label>
              {fieldsSchema["mobile"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="mobile-number-input"
                name="mobile"
                value={formik.values.mobile}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <small className="text-danger">{formik.errors.mobile}</small>
              )}
            </div>

            <div className={getCustomClasses("pharmacyBranch")}>
              <label htmlFor="pharmacy-branch-ddl" className="form-label">
                Pharmacy
              </label>
              {fieldsSchema["pharmacyBranch"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <AsyncSelect
                id="pharmacy-branch-ddl"
                name="pharmacyBranch"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                isClearable={true}
                value={formik.values.pharmacyBranch}
                onBlur={formik.handleBlur}
                loadOptions={(inputValue, callback) =>
                  loadOptions("pharmacies", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
                onChange={(item) =>
                  formik.setFieldValue("pharmacyBranch", item)
                }
              />
              {formik.touched.pharmacyBranch &&
                formik.errors.pharmacyBranch && (
                  <small className="text-danger">
                    {formik.errors.pharmacyBranch}
                  </small>
                )}
            </div>

            <div className={getCustomClasses("licenseNumber")}>
              <label htmlFor="license-number-input" className="form-label">
                License #
              </label>
              {fieldsSchema["licenseNumber"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <input
                id="license-number-input"
                name="licenseNumber"
                value={formik.values.licenseNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
              />
              {formik.touched.licenseNumber && formik.errors.licenseNumber && (
                <small className="text-danger">
                  {formik.errors.licenseNumber}
                </small>
              )}
            </div>

            <div className={getCustomClasses("role")}>
              <label htmlFor="role-ddl" className="form-label">
                Role
              </label>
              {fieldsSchema["role"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <Select
                id="role-ddl"
                name="role"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                options={lookups?.userRoles.filter(
                  (role) => role.id === 2 || role.id === 3 || role.id === 7
                )}
                value={formik.values.role}
                onBlur={formik.handleBlur}
                onChange={(item) => formik.setFieldValue("role", item)}
                formatOptionLabel={formatOptionLabel}
              />
              {formik.touched.role && formik.errors.role && (
                <small className="text-danger">{formik.errors.role}</small>
              )}
            </div>

            <div className={getCustomClasses("address")}>
              <label htmlFor="address-input" className="form-label">
                Address
              </label>
              {fieldsSchema["address"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <textarea
                id="address-input"
                name="address"
                value={formik.values.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="form-control"
              ></textarea>
              {formik.touched.address && formik.errors.address && (
                <small className="text-danger">{formik.errors.address}</small>
              )}
            </div>

            <div className={getCustomClasses("city")}>
              <label htmlFor="city-ddl" className="form-label">
                City
              </label>
              {fieldsSchema["city"].isRequired ? (
                <i className="fa fa-asterisk"></i>
              ) : null}
              <AsyncSelect
                id="city-ddl"
                name="cityId"
                classNamePrefix="react-select"
                getOptionValue={(option) => option.id}
                menuPlacement={"auto"}
                isClearable={true}
                value={formik.values.city}
                onBlur={formik.handleBlur}
                loadOptions={(inputValue, callback) =>
                  loadOptions("cities", inputValue, callback)
                }
                formatOptionLabel={formatOptionLabel}
                onChange={(item) => formik.setFieldValue("city", item)}
              />
              {formik.touched.city && formik.errors.city && (
                <small className="text-danger">{formik.errors.city}</small>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          onClick={formik.submitForm}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={props.onHide}
        >
          Cancel
        </button>

        <button
          className="btn btn-outline-primary rounded-circle btn-show-more"
          type="button"
          title={`Show ${showMoreFields ? "less" : "more"} fields`}
          onClick={() => toggleShowMoreFields()}
        >
          <i
            className={`fa-solid fa-chevron-${showMoreFields ? "up" : "down"}`}
          ></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;
