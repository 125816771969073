import React, { useEffect } from "react";
import DataGrid from "../../components/PrescriberDataGrid";


const PrescriberPage = () => {
  useEffect(() => {
    document.title = "TransferMyRx::Prescribers";
    const pageTitle = document.getElementById("page-title-text");
    if (pageTitle) {
      pageTitle.innerText = "Prescribers";
    }
  }, []);
  return (
      <DataGrid />
  );
};

export default PrescriberPage;
