import http from "../utility/http";

const Login = async (username, password, pharmacyBranchId) => {
  return await http.post("/v1/user/login", {
    username,
    password,
    pharmacyBranchId,
  });
};
const LoginWithCode = async (code, token) => {
  return await http.post("/v1/user/login-with-code", {
    code,
    token,
  });
};
const SignUp = async (user) => {
  return await http.post("/v1/user/signup", user);
};
const ResetPassword = async (email) => {
  return await http.post("/v1/user/reset-password", { email });
};
const RecoverUsername = async (email, mobile) => {
  return await http.post("/v1/user/recover-username", { email, mobile });
};

const Logout = async () => {
  return await http.delete("/v1/user/logout", {});
};

const UpdateGuestInfo = async (guestInfo) => {
  return await http.put("/v1/user/update-guest-info", guestInfo);
};

const UpdatePassword = async (passwords) => {
  return await http.put("/v1/user/update-password", passwords);
};

const ChangePassword = async (values) => {
  return await http.put("/v1/user/change-password", values);
};

const UpdateProfileDetails = async (profileDetails) => {
  return await http.put("/v1/user/update-profile-details", profileDetails);
};

const SetPharmacyBranch = async (pharmacyBranchId) => {
  return await http.put("/v1/user/set-pharmacy-branch", { pharmacyBranchId });
};

const GetPharmacyBranches = async () => {
  return await http.get("/v1/user/pharmacies");
};

const api = {
  Login,
  LoginWithCode,
  ResetPassword,
  SignUp,
  RecoverUsername,
  Logout,
  UpdateGuestInfo,
  GetPharmacyBranches,
  SetPharmacyBranch,
  UpdatePassword,
  UpdateProfileDetails,
  ChangePassword,
};

export default api;
